import { Row, Col, Card } from "antd";
import { useContext, useEffect } from "react";
import moment from "moment";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import Dashboardheader from "../components/Dashboard/Dashboardheader";
import LineChartCombined from "../components/Dashboard/LineChartCombined";

import DropdownMenu from "../components/DropdownMenu";
import Loader from "../components/Loader";

import { DashboardContext } from "../Context/Dashboard/DashboardContext";
import {
  DashboardContextI,
  ChartForecastI,
  TableForecastI,
} from "../utils/interfaces";
import HamburgerMenu from "../components/HamburgerMenu";
import TopBarNavigation from '../components/TopBarNavigation';

import { useNavigate } from "react-router-dom";

const getCircleColor = (totalAccuracy: number) => {
  let circleColor = "#449a59"; // Default color

  if (totalAccuracy >= 0 && totalAccuracy < 80) {
    circleColor = "#e6565b";
  } else if (totalAccuracy >= 80 && totalAccuracy < 90) {
    circleColor = "#f9c500";
  } else if (totalAccuracy >= 90 && totalAccuracy <= 100) {
    circleColor = "#47dc83";
  }

  return circleColor;
};

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/", { replace: true });

    return () => {};
  }, []);

  const {
    combinedByDate,
    combinedByDateAndType,
    totalForecastAccuracy,
    // getHoursPercentage,
    // getShiftsPercentage,
    loading,
  } = useContext<DashboardContextI>(DashboardContext);

  const randerTableRow = (data: TableForecastI, index: number) => {
    const {
      product,
      actualCreatedDate,
      forecastCreatedDate,
      actualValue,
      forecastValue,
      accuracy,
    } = data;

    const key = `${data.Date}-${product}`;
    return (
      <tr key={key}>
        <td>{data.Date}</td>
        <td>{product}</td>
        <td>{actualValue}</td>
        <td>{forecastValue}</td>
        <td>{accuracy ? `${accuracy} %` : ""}</td>
        <td>{actualCreatedDate}</td>
        <td>{forecastCreatedDate}</td>
      </tr>
    );
  };

  const renderForecastTable = () => {
    return (
      <table className="dashboard-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Product type</th>
            <th>Actuals</th>
            <th>Forecast</th>
            <th>Forecast Accuracy %(abs error)</th>
            <th>Actuals Created Date</th>
            <th>Forecast Created Date</th>
          </tr>
        </thead>
        <tbody>
          {combinedByDateAndType.map((item, index) =>
            randerTableRow(item, index)
          )}
        </tbody>
      </table>
    );
  };

  const formattedChartData = combinedByDate.map((item: ChartForecastI) => {
    return {
      ...item,
      date: moment(item.Date).format("MMM,DD,YYYY"),
    };
  });

  return (
    <div className="dashboard_container">
      <div>
        <div className="sticky-container">
          <div className="dashboard-header-container">
            <Dashboardheader/>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="dashboard-content-wrapper">
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
                xl: 44,
                xxl: 64,
              }}
            >
              <Col span={24}>
                <Card className="graphs_container ">
                  <Row
                    className="graph_details"
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 32,
                      xl: 44,
                      xxl: 64,
                    }}
                  >
                    <Col span={4}>
                      <div className="percentage_chart">
                        <div className="graph1">
                          <CircularProgressbar
                            value={+totalForecastAccuracy}
                            strokeWidth={13}
                            styles={{
                              path: {
                                // Path divor
                                stroke: getCircleColor(+totalForecastAccuracy),
                                strokeWidth: 13,
                              },
                              trail: {
                                // Trail color
                                stroke: "#d6d6d6",
                                strokeLinecap: "butt",
                              },
                            }}
                          />
                        </div>
                        <div className="graph_desc">
                          <h3>Order's Forecast</h3>
                          <p className="total_precentage">
                            {totalForecastAccuracy}%
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col className="graph2" span={20}>
                      <div className="combinedChart">
                        <LineChartCombined data={formattedChartData} />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="dashboard-table-wrapper">
                {renderForecastTable()}
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}
export default Home;
