import React from 'react'
import { Dna } from 'react-loader-spinner'

const Loader = () => {
    return (
        <>

            <div className='loader-container'>
                <Dna
                    visible={true}
                    height="120"
                    width="120"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                />
                <div>Loading, please wait...</div>
            </div>
        </>
    )
}

export default Loader