import  { useEffect, useContext, useState } from "react";
import {
  Button,
  Avatar,
  Checkbox,
  Tag,
  Row,
  Col,
  Input,
  Card,
} from "antd";

import {
  UserOutlined,
  EditOutlined,
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import DeleteModal from "../components/Modals/DeleteModal";
import { EmployeeContextI, EmployeeDataWithSecondShiftI } from "../utils/interfaces";
import { IShiftName } from '../components/schedule/types'
import { EmployeeContext } from "../Context/ShiftSettings/EmployeeContext";
import { getShift } from "../utils/functions"; 

import EditEmployeeModal from '../components/Modals/EmployeeDetails/EditEmployeeModal'
import CreateEmployeeModal from '../components/Modals/EmployeeDetails/CreateEmployeeModal'
interface ShiftExtended extends IShiftName {
  isMain?: boolean
}
const TeamComposition = ({ activeTab }: {activeTab: string}) => {
  const {
    employeeData,
    setEmpId,
    getFieldsValue,
    deleteModal,
    setEmployeeModal,
    setUpdateEmployeeModal,
    setDeleteModal,
    deleteEmployee,
    empId,
  } = useContext<EmployeeContextI>(EmployeeContext);


  const [searchTerm, setSearchTerm] = useState("");
  // const [shiftNames, setShiftNames] = useState<any[]>([]);
  const [shiftsById, setShiftsById]: any = useState<{[key in string]: IShiftName}>({});

  const setShifts = (data: IShiftName[]) => {
    const shiftsById: {
        [key in string]: IShiftName
      } = {};
    
      data.forEach((shift: IShiftName) => {
        shiftsById[`${shift.id}`] = shift
      })
      
    setShiftsById(shiftsById)
  }

  useEffect(()=>{
    if(activeTab === '3') {
      getShift(setShifts, () => {})
    }
  }, [activeTab])


  const showModal = () => {
    setEmployeeModal(true);
  };


  const handleOk = () => {
    setEmployeeModal(false);
  };

  const handleCancel = () => {
    setEmployeeModal(false);
  };

  const showUpdateModal = () => {
    setUpdateEmployeeModal(true);
  };

  const handleUpdateModalok = () => {
    setUpdateEmployeeModal(false);
  };

  const handleUpdateModalCancel = () => {
    setUpdateEmployeeModal(false);
  };

  const showDeleteModal = () => {
    setDeleteModal(true);
  };
  const handleDeleteModalCancel = () => {
    setDeleteModal(false);
  };
  const handleDeleteModalOk = () => {
    setDeleteModal(false);
  };

  const renderShifts = (employee: EmployeeDataWithSecondShiftI) => {
    const { 
      team: { shiftName }, 
      secondShifts 
    } = employee;

    const shiftsList: ShiftExtended[] = shiftName ? [{...shiftName, isMain: true }] : [];

    secondShifts.forEach((item) => {
      item.secondary_shift_id.split(',').forEach(secondaryId => {
        const secondShift = shiftsById[secondaryId];
        if(secondShift) {
          shiftsList.push(secondShift)
        }
      })
    })

    return (
      <div style={{display: 'flex'}}>
        {shiftsList.map(({id, shiftName, color}) => (
        <Tag 
          key={id}
          className="team"  
          style={{
            backgroundColor: color,
            marginRight: 10
          }}>
          {shiftName}
        </Tag>
        ))}
      </div>
    )
  }

  return (
    <>
      <Card
         style={{ 
          margin: "0 20px", 
          border: "none", 
          borderRadius: 0, 
          padding: "15px !important" 
        }}
        className="shift-filters-row"
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <div>
                  <SearchOutlined
                    style={{
                      position: "absolute",
                      top: "10px",
                      zIndex: "1",
                      marginLeft: "12px",
                      fontSize: "17px",
                    }}
                  />
                  <Input
                    className="emp-input"
                    placeholder="Search by name or id"
                    onChange={(e: any) => {
                      console.log(e.target.value);
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#20304F",
                      color: "white",
                      borderRadius: 5,
                      width: "200px",
                    }}
                    type="default"
                    size="large"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      showModal();
                    }}
                  >
                    Add Employee
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Row style={{ padding: "0 20px"}}>
        <Col span={24}>
          <Card className="employee_card">
            <table
              className="employeeTable"
              style={{ borderRadius: "12px !important" }}
            >
              <thead>
                <tr>
                  <th className="employee_name_heading">
                    <span className="avatar"></span>
                    <Checkbox style={{ marginInline: "10px" }} />
                    Employee Name
                  </th>
                  <th>Position</th>
                  <th>Team</th>
                  <th style={{textAlign: 'left'}}>Shifts</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {employeeData?.length > 0 &&
                  employeeData
                    ?.filter((emp: any, index: number) => {
                      return searchTerm === ""
                        ? emp
                        : emp?.worker.includes(searchTerm);
                    })
                    .map((employee: any) => {
                      return (
                        <tr key={employee?.id}>
                          <td className="employee_name">
                            <span className="avatar"></span>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "7px",
                                marginTop: "-1rem",
                              }}
                            >
                              <Checkbox style={{ marginInline: "10px" }} />

                              <Avatar
                                style={{
                                  backgroundColor: "#779cbf",
                                  color: "white",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                }}
                                size={48}
                                icon={<UserOutlined />}
                              />
                              <div>
                                <p>{employee.worker}</p>
                                <p style={{ color: "#777", fontSize: ".8rem" }}>
                                  ID:{employee?.external_id}
                                </p>
                              </div>
                            </div>
                            {/* <span>{employee?.worker}<br />Id:{employee?.external_id}</span> */}
                          </td>
                          <td>{employee?.position}</td>
                          <td>
                            <Tag className="team">
                              {employee?.team?.teamName}
                            </Tag>
                          </td>
                          {/* <td>{employee?.team.shiftName.shiftName}</td> */}
                          <td>{renderShifts(employee)}</td>
                         
                          <td></td>

                          <td>
                            <EditOutlined
                              style={{
                                marginRight: "20px",
                                cursor: "pointer",
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                setEmpId((prev: any) => employee?.id);
                                showUpdateModal();
                                getFieldsValue();
                              }}
                            />
                            <DeleteOutlined
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                showDeleteModal();
                                setEmpId(employee?.id);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>

      <CreateEmployeeModal
        shifts={shiftsById}
        handleModalOk={handleOk}
        handleModalCancel={handleCancel}
      /> 
      <EditEmployeeModal
        shifts={shiftsById}
        handleModalOk={handleUpdateModalok}
        handleModalCancel={handleUpdateModalCancel}
      /> 
     
      <DeleteModal
        title="Are you sure you want to delete this employee?"
        deleteOpen={deleteModal}
        deleteOk={handleDeleteModalOk}
        deleteCancel={handleDeleteModalCancel}
        handleDelete={deleteEmployee}
        id={empId}
      />
    </>
  );
};

export default TeamComposition;
