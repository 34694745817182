import React, { useContext } from 'react';

import { Button, Space } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import BarLegend, { LegendOption } from '../rechart/BarLegend';
import RechartBar from '../rechart/RechartBar'
import PlannedLeaveCells from './PlannedLeaveCells'
import RecurrenceLeaveCell from './RecurrenceLeaveCell';

import { ILeave } from '../../components/LeaveTracker/types'

import { LeaveTrackerContext } from '../../Context/LeaveTracker/LeaveTrackerContext'
import { LeaveTrackerContextI } from '../../utils/interfaces'


interface ILeaveTable {
  renderTableHeader?: any;
  showChart: boolean;
  setShowChart: any;
  toggleLeaveView: any
}

const BAR_COLORS = {
  ALL: '#c7d3fd',
  SELECTED: '#5468aa'
}

const legendOptions: LegendOption[] = [
  {
    color: BAR_COLORS.ALL,
    label: 'Time-off employees'
  },
  {
    color: BAR_COLORS.SELECTED, 
    label: 'One time leave'
  },
];

const LeaveTable = (props: ILeaveTable) => {
  const { 
    showChart,
    setShowChart,
    toggleLeaveView,
  } = props;

  const {
    daysInMonth,
    plannedLeaves,
    recurrenceLeaves,
    setLeaveId,
    setrecurrLeaveId,
    deleteLeave,
    deleteRecurringLeave,
    showEditModal,
    setRecurringModal,
    getRecurringFieldsValue,
    setUpdateRecurr,
    getFieldsValue,
    currentYear,
    currentMonth,
    itemCount,
    daysOfWeek,
  } = useContext<LeaveTrackerContextI>(LeaveTrackerContext);
  

  const content = (
    <Space>
      <div className="delete-icon-container" onClick={deleteLeave} >
        <DeleteOutlined className='delete-icon' />
      </div>
      <div className="edit-icon-container" onClick={() => {
        showEditModal();
        getFieldsValue();
      }}>
        <EditOutlined className='edit-icon' />
      </div>
    </Space>
  );

  const recurringContent = (
    <Space>
      <div className="delete-icon-container" onClick={deleteRecurringLeave} >
        <DeleteOutlined className='delete-icon' />
      </div>
      <div className="edit-icon-container" onClick={() => {
        setRecurringModal(true);
        setUpdateRecurr(true);
        getRecurringFieldsValue();
      }}>
        <EditOutlined className='edit-icon' />
      </div>

    </Space >
  )

  const test: any = [];
  const barData: string[] = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(currentYear, currentMonth, day);
    const dayOfWeek = date.getDay();
    
    let value: any = "";
    
    for (let elements in itemCount) {
      if (moment(date).format("YYYY-MM-DD") == elements) {
        value = itemCount[elements]
      }
    }
    
    barData.push(`${day} ${daysOfWeek[dayOfWeek]} ${value}`)
    test.push(moment(date).format("YYYY-MM-DD"));
  }

  const groupedByWorker:{[key in string]: ILeave[]} = {};

  plannedLeaves.forEach(item => {
      const workerId = item.worker.worker;
      if (!groupedByWorker[workerId]) {
        groupedByWorker[workerId] = [];
      }
      groupedByWorker[workerId].push(item);
  });

  return (
    <table className='scheduleTable'>
      <thead >
        <tr>
          <th className="left-th">
          <div className="action-buttons-wrapper">
            <div className="action-buttons">
                <Button 
                  style={showChart ? {
                    backgroundColor: "#20304F",
                    color: "white",
                  } : {}} 
                className="chat-toggler-button"
                  onClick={() => setShowChart(!showChart)}
                  type="default"
                  size="small"
                >
                {showChart ? 'Hide Graph' : 'Show Graph'}
                </Button>
            </div>
              <BarLegend 
                showChart={showChart} 
                legendOptions={legendOptions}
              />
            </div>
          </th>

          <th className="right-th" colSpan={daysInMonth} >
            <RechartBar
              totalEmployees={40}
              barData={barData}
              showChart={showChart}
              isDayView={true}
            />
          </th>
        </tr>
      </thead>

      <tbody className="schedule_body">
        {Object.values(groupedByWorker).map((leave: ILeave[], i: number) => (
          <tr 
            key={Math.random() * 10 + 30.5 * 2} 
            style={{ borderBottom: "1px solid #dee2e6", position: 'relative' }}
            className='leaves_row'>
            <PlannedLeaveCells
              test={test}
              content={content}
              leavesList={leave}
              setLeaveId={setLeaveId}
              getFieldsValue={getFieldsValue}
            />
          </tr>
        ))}

        {recurrenceLeaves?.map((emp: any, i: number) => {
          return (
            <tr key={Math.random() * 100 + 30} className='leaves_row'>
              <RecurrenceLeaveCell
                emp={emp}
                test={test}
                recurringContent={recurringContent}
                setrecurrLeaveId={setrecurrLeaveId}
              />
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default LeaveTable;