import React, { useState, useCallback, useMemo, useEffect } from "react";
import axios, { AxiosRequestConfig } from "axios";
import {
  actualForeCastURL,
  combinedURL,
  productTypesSettingsUrl,
  orderforecastURL,
  scheduled,
  forecasetModelRun,
} from "../../utils/network";

import moment from "moment";
import { DateTime } from "luxon";
import {
  DashboardContextI,
  ForecastI,
  ChartForecastI,
  TableForecastI,
  ShiftOptionI
} from "../../utils/interfaces";
import { notification } from "antd";
import { IProductType } from "../../utils/interfaces";
import { useGetShift } from "../../utils/hooks";

// import { fetchData} from '../../utils/mock'
// import { productTypesData } from '../../mock_data/productTypes';
// import { forecastData } from '../../mock_data/forecast_2';
// import { cleanedActualMocksData } from '../../mock_data/cleaned_actuals_2'
// import { forecastData } from '../../mock_data/forecast';
// import { cleanedActualMocksData } from '../../mock_data/cleaned_actuals'

function roundToTwoDecimalPlaces(number: number) {
  var rounded = Math.round(number * 100) / 100;
  return rounded % 1 === 0 ? Math.floor(rounded) : rounded.toFixed(2);
}

function calculateMAPE(actual: number, forecast: number) {
  if (actual === 0) {
    throw new Error("Actual value cannot be zero for MAPE calculation.");
  }
  return Math.abs((actual - forecast) / actual) * 100;
}

interface Option {
  value: number;
  label: string;
}

const sortForecast = (a: any, b: any) => {
  const dateA = new Date(a.Date).getTime();
  const dateB = new Date(b.Date).getTime();
  if (dateA < dateB) return -1;
  if (dateA > dateB) return 1;
  return 0;
};

function combineDataByDate(data: ForecastI[]) {
  const calendar: { [key in string]: ChartForecastI } = {};

  data.forEach((item: any) => {
    const { Date, product } = item;
    if (!calendar[Date]) {
      calendar[Date] = {
        Date,
        product: product?.product_name,
      };
    }

    if (item.type === "forecast") {
      const value = calendar[Date].forecast || 0;
      calendar[Date].forecast = value + item.value;
    } else if (item.type === "cleanedActuals") {
      const value = calendar[Date].cleanedActuals || 0;
      calendar[Date].cleanedActuals = value + item.value;
    }
  });

  const result = Object.values(calendar);

  return result;
}

const combineDataByDateAndType = (data: ForecastI[]) => {
  const dateFormat = "yyyy-MM-dd";
  const createdDateFormat = "yyyy-MM-dd HH:mm";

  const combinedData: {
    [key in string]: TableForecastI[];
  } = {};

  data.forEach((entry: ForecastI) => {
    const key = `${entry.Date}`;

    const createdDate = DateTime.fromISO(entry.created_at).toFormat(
      createdDateFormat
    );
    const data = {
      Date: DateTime.fromISO(entry.Date).toFormat(dateFormat),
      product: entry.product?.product_name,
      actualValue: entry.type === 'cleanedActuals' ? entry.value : null,
      forecastValue: entry.type === 'forecast' ?  entry.value  : null,
      MAE: null,
      accuracy: null,
      actualCreatedDate: entry.type === 'cleanedActuals' ? createdDate : '',
      forecastCreatedDate: entry.type === 'forecast' ? createdDate : '',
    } as TableForecastI;

    if (!data.product) {
      data.product = entry.product?.product_name;
      return;
    }

    if (!combinedData[key]) {
      combinedData[key] = [data];
    } else {
      combinedData[key].push(data)
    }

    return combinedData;
  });

  const result = Object.values(combinedData).map((array: TableForecastI[]) => {
    const productNames = new Set();
    const reduced = array.reduce((acc, currentValue, index) => {
      if (currentValue.product) {
        productNames.add(currentValue.product);
      }
  
      if (index === 0) {

        acc = { ...currentValue }
      } else {
        // @ts-ignore
        acc.actualValue += currentValue.actualValue;
         // @ts-ignore
        acc.forecastValue += currentValue.forecastValue;
        // @ts-ignore
        acc.actualCreatedDate = currentValue.actualCreatedDate;
         // @ts-ignore
        acc.forecastCreatedDate = currentValue.forecastCreatedDate;
      }
  
      return acc;
    }, {}) as TableForecastI;
    
    reduced.product = Array.from(productNames).join('');

    if (productNames.size > 1) {
      reduced.product = Array.from(productNames).join(', ');
    } 
    if(reduced.actualValue === 0 ) {
      reduced.actualValue = null
    }
    if(reduced.forecastValue === 0 ) {
      reduced.forecastValue = null
    }
    return reduced
  }, {});

  const accuracyResult = result.map(entry => {
    if (entry.forecastValue !== null && entry.actualValue !== null) {
      const accuracy = calculateMAPE(entry.actualValue, entry.forecastValue);
      entry.accuracy = +roundToTwoDecimalPlaces(accuracy);
    }
    return entry;
  });

  return accuracyResult;
};

export const DashboardContext: any = React.createContext<
  DashboardContextI | undefined
>(undefined);

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const todayDate = new Date();
  const dateSixMonthBefore = new Date(
    todayDate.setMonth(todayDate.getMonth() - 6)
  );

  // Values for table start
  const [combinedByDate, setCombinedByDate] = useState<ChartForecastI[]>([]);
  const [combinedByDateAndType, setCombinedByDateAndType] = useState<TableForecastI[]>([]);
  // Values for table end

  const [shifts, setShifts]: any = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [datesFilter, setDatesFilter] = useState<{
    fromDate?: any;
    toDate?: any;
  }>({
    fromDate: dateSixMonthBefore,
    toDate: new Date(),
  });
  const [shift, setshift] = useState<ShiftOptionI | null>(null);
  console.log(shift,'shift FILTER VALUE')
  const [fetching, setFetching] = useState(true);
  const [shiftdropdown, setshiftdropdown] = useState<any[]>([]);
  const [schedule, setSchedule]: any = useState([]);
  const [hours, setHours] = useState([]);
  const [productType, setProductType] = useState<Option[]>([]);
  const [productValue, setproductValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSetShiftsDropdown = (values: any) => {
    setshiftdropdown(values)

    if(values.length > 0) {
      setshift({
        value: values[0].id,
        label: values[0].shiftName
      })
    }
  }

  useGetShift(handleSetShiftsDropdown, setFetching);

  const changeShiftFilter = (newValue: ShiftOptionI) => {
    setshift(newValue)
    if(newValue.value !== shift?.value) {
      
      setproductValue('')
    }
  }
  const monthCount = useMemo(() => {
    const fromDate = new Date(datesFilter.fromDate);
    const toDate = new Date(datesFilter.toDate);

    const yearsDiff = toDate.getFullYear() - fromDate.getFullYear();
    const monthsDiff = toDate.getMonth() - fromDate.getMonth();

    return yearsDiff * 12 + monthsDiff;
  }, [datesFilter]);

  const handleDateChange = (date: string, name: string) => {
    let newDate: string | undefined;

    if (date) {
      const parsedDate = DateTime.fromISO(date);
      const formattedDate = parsedDate.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
      newDate = formattedDate;
    }

    setDatesFilter(prevDates => ({
      ...prevDates,
      [name]: newDate,
    }));

    setCurrentDate(new Date(date));
  };
  
  const getProductTypes = useCallback(async() => {
    const shiftFilter = shift?.value || '';

    const productTypesResponse: AxiosRequestConfig = await axios.get(productTypesSettingsUrl, {
      params: {
        shiftName_id: shiftFilter,
      }
    });

    setProductType(
      productTypesResponse.data.map((item: IProductType) => ({
        value: item.id,
        label: item.product_name,
      }))
    );
  }, [shift]);

  const getForecastData = useCallback(async () => {
    if(shift === null) return;
    setLoading(true);
    
    const fromDate = datesFilter.fromDate && moment(datesFilter.fromDate).format('YYYY-MM-DD');
    const toDate = datesFilter.toDate && moment(datesFilter.toDate).format('YYYY-MM-DD');

    const filterParams = {
      product_id: productValue || '',
      shiftName_id: shift ? shift.value : '',
      from_date: fromDate || '',
      to_date: toDate || '',
    };
    
    try {
      const orderResponse: AxiosRequestConfig = await axios.get(orderforecastURL, {
        params: filterParams
      });
      const actualResponse = await axios.get(actualForeCastURL, {
        params: filterParams
      });

      const orderData = orderResponse.data.map((item: ForecastI) => ({
        ...item,
        type: "forecast",
      }));

      const actualData = actualResponse.data.map(
        ({ value, ...item }: ForecastI) => ({
          ...item,
          value: value,
          type: "cleanedActuals",
        })
      );

      const mergedData = [...orderData, ...actualData].sort(sortForecast);

      setCombinedByDate(combineDataByDate(mergedData))
      setCombinedByDateAndType(combineDataByDateAndType(mergedData))

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [shift, productValue, datesFilter])

  //GET SHIFTS DATA
  const getShifts = async () => {
    const response = await axios.get(combinedURL);
    const data = response.data?.shifts;
    setShifts(data);
  };

  //GET SCHEDULE DATA FOR OBTAINING HOURS
  const getSchedule = useCallback(async () => {
    try {
      const response = await axios.get(`${scheduled}`, {
        params: {
          // months: `${monthNames[currentDate.getMonth()]
          //     } ${currentDate.getFullYear()}`,
          shiftName_id: shift?.value || ''
        },
      });

      if (response) {
        const data = response.data.map((item: any) => ({
          ...item,
        }));

        setSchedule(data);
        setFetching(false);
      }
      return response;
    } catch (e) {
      notification.error({
        message: "Error while fetching Schedule details",
      });
    }
  }, [shift]);

  const createForecast = async ({ shiftId, forecast_to }: any) => {
    setLoading(true);
    try {
      const formattedDate = forecast_to.format("YYYY-MM-DD HH:mm");
      const dateUtc = moment(formattedDate).utc().format("YYYY-MM-DDTHH:mm");

      const data = {
        status: "1",
        forecast_to: dateUtc,
        shiftName_id: shiftId,
      };

      const response = await axios.post(
        `${forecasetModelRun}?format=api`,
        data
      );
      // const response = await fetchData(data)
      setLoading(false);

      if (response) {
        notification.success({
          message: "Forecast generation Finished",
        });
      }
      setDatesFilter(prevDates => ({
        ...prevDates,
        toDate: new Date(formattedDate),
      }));
    } catch (err) {

      setLoading(false);
      notification.error({
        message: "Forecast generation Failed",
      });
    }
  };
  //method to obtain forecast percentage

  //method to obtain shifts percentage
  const getShiftsPercentage = useMemo(() => {
    let percentage = 0;
    
    if (shifts.length > 0) {
      percentage = (shifts?.length / 100) * 100;
    }
    return roundToTwoDecimalPlaces(percentage);
  }, [shifts]);

  //method to obtain hours
  const getHours = useCallback(() => {
    let s1: any = [];

    if (schedule?.length > 0) {
      s1 = schedule.map((item: any) => ({
        employee: item?.worker,
        start: new Date(item?.start_date).toISOString(),
        end: new Date(item.end_date).toISOString(),
        id: item?.id,
      }));
    }
    setHours(
      s1?.map(
        (s: any) => new Date(s?.end).getHours() - new Date(s.start).getHours()
      )
    );
  }, [schedule]);

  //method to obtain Hours Percentage
  // function add(accumulator: any, a: any) {
  //     return accumulator + a;
  // }
  // const getHoursPercentage = useMemo(() => {
  //     let sum = hours.reduce(add, 0);
  //     return sum;
  // }, [hours]);

  useEffect(() => {
    getProductTypes();
  }, [shift, getProductTypes]);

  useEffect(() => {
    getShifts();
  }, []);
  
  useEffect(() => {
    getSchedule();
  }, [getSchedule, shift]);

  useEffect(() => {
    getForecastData();
  }, [
    getForecastData, 
    datesFilter.fromDate, 
    datesFilter.toDate, 
    productValue,
    shift
  ]);

  const totalForecastError = useMemo(() => {
    if (!combinedByDateAndType.length) return 0;
    const filterdByAccuracy = combinedByDateAndType.filter(
      item => item.accuracy
    );

    const percentage =
      filterdByAccuracy.reduce(
        (total, entry) => total + (entry.accuracy || 0),
        0
      ) / filterdByAccuracy.length;

    return percentage;
  }, [combinedByDateAndType]);

  const totalForecastAccuracy =
    totalForecastError > 0
      ? roundToTwoDecimalPlaces(100 - totalForecastError)
      : 0;

  return (
    <DashboardContext.Provider
      value={{
        monthCount,
        currentDate,
        productValue,
        setshift: changeShiftFilter,
        shiftdropdown,
        productType,
        totalForecastAccuracy,
        combinedByDate,
        combinedByDateAndType,
        getShiftsPercentage,
        // getHoursPercentage,
        setproductValue,
        handleDateChange,
        datesFilter,
        createForecast,
        loading,
        selectedShift: shift,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
