import privateClient from "../client/private.client";

const userEndpoints = {
  info: "/users/current",
  roles: "/roles",
};

const userApi = {
  getInfo: async () => {
    try {
      const response = await privateClient.get(userEndpoints.info);
      localStorage.setItem("user", JSON.stringify(response.data));

      return { response };
    } catch (err) {
      return { err };
    }
  },
  getRoles: async () => {
    try {
      const response = await privateClient.get(userEndpoints.roles);
      localStorage.setItem("userRole", JSON.stringify(response.data));

      return { response };
    } catch (err) {
      return { err };
    }
  },
};

export default userApi;
