

export type LegendOption = {
  color: string;
  label: string;
};

interface IBarLegend {
  showChart: any;
  legendOptions: any
}

const BarLegend = ({
  showChart, 
  legendOptions
}: IBarLegend) => {
  if(!showChart) return null;

  const renderLegendOption = (item: LegendOption) => item &&  (
    <div key={item.color} className='item'> 
      <div className='square' style={{ backgroundColor: item.color }}/>
      <p>{item.label}</p>
    </div>
  )
  
  return (
    <div className='bar-legend' style={{ marginBottom: 30}}>
      {renderLegendOption(legendOptions[2])}
      {renderLegendOption(legendOptions[0])}
      {renderLegendOption(legendOptions[1])}
    </div>
  )
}

export default BarLegend 