import { Form, Input } from "antd";

interface Props {
  index: number;
  day: string;
  removeDay: (dayKey: string) => void;
  addDay: (dayKey: string) => void;
  form: any;
}

const ModalDayItem = ({ index, removeDay, addDay, day, form }: Props) => {
  const dayValue = Form.useWatch(day, form);
  const isDaySelected = dayValue?.shift === "yes";

  const handleChange = () => {
    const dayValues = form.getFieldsValue(day);
    form.setFieldsValue({
      [day]: {
        ...dayValues[day],
        shift: isDaySelected ? "no" : "yes",
      },
    });

    if (isDaySelected) {
      removeDay(day);
    } else {
      addDay(day);
    }
  };
  return (
    <>
      <Form.Item style={{ display: "none" }} name={[day, "shift"]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ padding: ".5rem" }} name={["day", index]} key={day}>
        <Input
          onClick={handleChange}
          readOnly
          value={day.slice(0, 3)}
          defaultValue={day.slice(0, 3)}
          className={`day_button weekday${index + 1} ${
            isDaySelected ? "clicked" : ""
          }`}
        />
      </Form.Item>
    </>
  );
};

export default ModalDayItem;
