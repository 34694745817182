import React, { useContext } from 'react';
import { Input, Button, Row, Col, Card, Select, Space } from 'antd';
import { LeftOutlined, RightOutlined, SearchOutlined, CloudOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { formatDate } from "../utils/dates";
import PlannedLeaveCells from './LeaveTracker/PlannedLeaveCells';
import RecurrenceLeaveCell from './LeaveTracker/RecurrenceLeaveCell';
import LeaveModals from './LeaveTracker/LeaveModals';
import LeaveActionButtons from './LeaveTracker/LeaveActionButtons'

import { ILeave } from '../components/LeaveTracker/types'

import { LeaveTrackerContext } from "../Context/LeaveTracker/LeaveTrackerContext";
import { LeaveTrackerContextI } from "../utils/interfaces";

const LeaveTableView: React.FC = () => {
  const {
    plannedLeaves,
    recurrenceLeaves,
    teams,
    selectedTeam,
    currentDate,
    shiftdropdown,
    deleteBtn,
    handleshiftchange,
    setSelectedTeam,
    setMonth,
    setLeaveId,
    setrecurrLeaveId,
    deleteLeave,
    deleteRecurringLeave,
    prevMonth,
    nextMonth,
    showEditModal,
    setRecurringModal,
    getRecurringFieldsValue,
    setUpdateRecurr,
    getFieldsValue,
    renderHeaders,
    test,
  } = useContext<LeaveTrackerContextI>(LeaveTrackerContext);


  const content = (
    <Space>
      <div className="delete-icon-container" onClick={deleteLeave} >
        <DeleteOutlined className='delete-icon' />
      </div>
      <div className="edit-icon-container" onClick={() => {
        showEditModal();
        getFieldsValue();
      }}>
        <EditOutlined className='edit-icon' />
      </div>

    </Space>
  );

  const recurringContent = (
    <Space>
      <div className="delete-icon-container" onClick={deleteRecurringLeave} >
        <DeleteOutlined className='delete-icon' />
      </div>
      <div className="edit-icon-container" onClick={() => {
        setRecurringModal(true);
        setUpdateRecurr(true);
        getRecurringFieldsValue();
      }}>
        <EditOutlined className='edit-icon' />
      </div>

    </Space >
  )

  const groupedByWorker:{[key in string]: ILeave[]} = {};

  plannedLeaves.forEach(item => {
      const workerId = item.worker.worker;
      if (!groupedByWorker[workerId]) {
        groupedByWorker[workerId] = [];
      }
      groupedByWorker[workerId].push(item);
  });
  return (
    <div>
      <Card
        className='leave_table'
      >
        <Row className="options_container">
          <Col span={11}>
            <div style={{ display: "flex" }}>
              <Select onChange={handleshiftchange}
                className="schedule_selects"
                mode="multiple"
                labelInValue
                style={{ width: "20%" }}
                placeholder="Select Shifts"
                options={
                  shiftdropdown?.map((item: any, index: number) => ({
                    value: item?.id,
                    label: item?.shiftName
                  }))
                }
              />
              <Select placeholder="Team" onChange={value => setSelectedTeam(value)}
                className="schedule_selects"
              >
                {teams.map((team: any, index: any) => {
                  return (<Select.Option value={team} key={index}>{team}</Select.Option>)
                })}

              </Select>
              {selectedTeam}
              <Select placeholder="Position" onChange={value => setMonth(value)}className="schedule_selects">
              </Select>
              <div><SearchOutlined
                style={{
                  position: "absolute",
                  top: "14px", zIndex: "1", marginLeft: "12px",
                  fontSize: "17px"
                }} />
                <Input
                  className="custom-input"
                  placeholder="Search by name or id"
                /></div>
            </div>
          </Col>
          <Col span={8}>
            <LeaveActionButtons/>
          </Col>
          <Col span={4}>
            <Button style={{ display: deleteBtn ? "block" : "none" }}>Delete</Button>
          </Col>
        </Row>
      </Card>
      <Card style={{ margin: "20px", }}>
        <table className='leaves_table'>
          <thead >
            <tr className='dates_header'>
              <th>
                <div className='leaves_month_buttons'>
                  <button onClick={prevMonth}><LeftOutlined /></button>
                  <p>{formatDate(currentDate)}</p>
                  <button onClick={nextMonth}><RightOutlined /></button>
                </div>
              </th>
              {renderHeaders()}
            </tr>
            <tr>
              <th className='employee_heading'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ marginTop: "10px", marginLeft: "15px" }}
                  id="IconChangeColor" height="20" width="20"> <g>
                    <path fill="none" d="M0 0h24v24H0z" id="mainIconPathAttribute"></path>
                    <path d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h14V5H5zm2 6h10v2H7v-2z" id="mainIconPathAttribute"></path> </g> </svg>
                <span style={{ marginTop: "7px", marginLeft: "15px" }}>Employee</span>
              </th>
            </tr>
          </thead>
          <tbody>
          {Object.values(groupedByWorker).map((leave: ILeave[], i: number) => (
            <tr 
              key={Math.random() * 10 + 30.5 * 2} 
              style={{ borderBottom: "1px solid #dee2e6", position: 'relative' }}
              className='leaves_row'>
              <PlannedLeaveCells
                test={test}
                content={content}
                leavesList={leave}
                setLeaveId={setLeaveId}
                getFieldsValue={getFieldsValue}
              />
            </tr>
          ))}
            {recurrenceLeaves?.map((emp: any, i: number) => {
              return (
                <tr key={Math.random() * 100 + 30} className='leaves_row'>
                  <RecurrenceLeaveCell
                    emp={emp}
                    test={test}
                    recurringContent={recurringContent}
                    setrecurrLeaveId={setrecurrLeaveId}
                  />
                </tr>
              )
            })}
          </tbody>
        </table>
      </Card>
      <LeaveModals/>
    </div >
  );
};
export default LeaveTableView;