import React from 'react'
import { Modal, Form, Switch, Select, Row, Col, InputNumber, Input, Button } from "antd";
import WeekDisplays from '../../LeaveTracker/WeekDisplays';

interface RecurrModel {
  recurringModal: any;
  handleOk: any;
  handleModalClose: any;
  addRecurringLeave: any;
  add_recurr: any;
  handleRecurrSwitchChange: any;
  workers: any;
  years: any;
  months: any;
  dates: any;
  handleSelectChangeFrom: any; selectedValuesFrom: any;
  handleSelectChangeTo: any;
  selectedValuesTo: any;
  isUpdate: boolean;
}

const AddRecurringLeaveModal: React.FC<RecurrModel> = ({
  recurringModal,
  handleOk,
  handleModalClose,
  addRecurringLeave,
  add_recurr,
  handleRecurrSwitchChange,
  workers,
  years,
  months,
  dates,
  handleSelectChangeFrom, selectedValuesFrom,
  handleSelectChangeTo,
  selectedValuesTo,
  isUpdate
}) => {

  const [interval, setinterval] = React.useState(1);
  const [recurrValue, setRecurrValue] = React.useState("");
  const handleSelectNumber = (value: any) => {
    setinterval(value);
  }

  const recurrLeaveOptions = ['daily', 'weekly', 'monthly'];
  const handleChange = (e: any) => {
    setRecurrValue(e);
    console.log(e);
  }
  
  
  const handleSwith = () => {
    handleRecurrSwitchChange(true)
}
  return (
    <Modal title={isUpdate ? "Update a recurring leave" : "Add a recurring leave"}
      className='leaves_modal'
      open={recurringModal}
      mask={false}
      onOk={handleOk}
      onCancel={handleModalClose}>
      <Form initialValues={{
        remember: true,

      }}
        onFinish={addRecurringLeave}
        form={add_recurr}
      > 
        <Form.Item style={{ padding: ".5rem" }}>
          <Switch onChange={handleSwith} checked={true} /><span className='one_time'>recurring</span>
        </Form.Item>
        <Form.Item label="Worker Name"
          labelCol={{ span: 24 }}
          style={{ padding: ".5rem" }}

          name="worker_id"
          rules={[

            {
              required: true,
              message: "Leave Option is required",
            },
          ]}
        >
          <Select onChange={(e: any) => console.log(e)} options={
            workers.map((item: any) => ({
              value: item.id,
              label: item.worker,
            }))
          } />


        </Form.Item>

        <Form.Item label="Recurrence Type"
          labelCol={{ span: 24 }}
          style={{ padding: ".5rem" }}

          name="recurrence_type"
          rules={[

            {
              required: true,
              message: "Leave Option is required",
            },
          ]}
        >
          <Select
            options={recurrLeaveOptions.map((o) => ({
              value: o,
              label: o
            }))}
            value={recurrValue}
            onChange={handleChange}
          />

        </Form.Item>
        <div style={{ display: "flex", width: "100%", marginLeft: "12px" }}>
          <div style={{ width: "143px" }}>
            <label >
              Unavailable <br />from</label>
          </div>

          <Form.Item style={{ width: "100%" }}
            name="leave_start_date">
            <Row>
              <Col span={8} style={{ marginRight: "4px" }}>
                <Select options={
                  years.map((item: any) => ({
                    value: item,
                    label: item,
                  }))
                }
                  onChange={(value: any) => handleSelectChangeFrom('select1', value)}
                  value={selectedValuesFrom.select1}
                /></Col>
              {selectedValuesFrom.select1}
              <Col span={6} style={{ marginRight: "4px" }}>
                <Select options={
                  months.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))
                }
                  onChange={(value: any) => handleSelectChangeFrom('select2', value)}
                  value={selectedValuesFrom.select2}
                />
              </Col>
              <Col span={6}>
                <Select options={
                  dates.map((item: any) => ({
                    value: item,
                    label: item,
                  }))
                }
                  onChange={(value: any) => handleSelectChangeFrom('select3', value)}

                  value={selectedValuesFrom.select3}
                /></Col>
            </Row>

          </Form.Item>
        </div>
        <div style={{ borderLeft: "2px dotted black", height: "50px", marginTop: "-10px", marginLeft: "10px" }}></div>

        <div style={{ display: "flex", width: "100%", marginLeft: "12px" }}>

          <div style={{ width: "80px" }}>
            <label>
              To</label>
          </div>
          <Form.Item
            name="leave_end_date"
            style={{ width: "100%" }}

          >
            <Row className='unavilability_container'>
              <Col span={7} style={{ marginRight: "4px" }}>
                <Select options={
                  years.map((item: any) => ({
                    value: item,
                    label: item,
                  }))
                }
                  onChange={(value: any) => handleSelectChangeTo('select1', value)}
                  value={selectedValuesTo.select1}
                /></Col>
              <Col span={6} style={{ marginRight: "4px" }}>
                <Select options={
                  months.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))
                }
                  onChange={(value: any) => handleSelectChangeTo('select2', value)}
                  value={selectedValuesTo.select2}
                />
              </Col>
              <Col span={5} style={{ marginRight: "4px" }}>
                <Select options={
                  dates.map((item: any) => ({
                    value: item,
                    label: item,
                  }))
                }
                  onChange={(value: any) => handleSelectChangeTo('select3', value)}

                  value={selectedValuesTo.select3}
                /></Col>
            </Row>
          </Form.Item>
        </div>

        <div style={{ display: "flex", width: "100%", marginLeft: "12px" }}>
          <div style={{ width: "145px" }}>
            <label>Repeat Every</label>
          </div>
          <Form.Item
            name="interval"
            style={{ marginRight: "20px" }}
            rules={[

              {
                required: true,
                message: "Leave Option is required",
              },
            ]}>
            <InputNumber min={0} max={10} value={interval} onChange={handleSelectNumber} />
          </Form.Item>
          <div style={{ marginTop: "5px" }}><p>Week</p></div>
        </div>
        {/* {Array.from({ length: interval }).map((_: any, index: number) => {
          return (
            <div style={{ display: "flex" }}><WeekDisplays /></div>
          )
        })} */}


        <Form.Item style={{ padding: ".5rem" }} className='leaves_modal_footer'>
          <Button type="default" onClick={handleModalClose} className='leaves_cancel'>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="add_team">
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddRecurringLeaveModal