import React, { useEffect, useState } from "react";
import { Dropdown, Avatar, MenuProps } from "antd";
import { CaretDownFilled, PoweroffOutlined } from "@ant-design/icons";
import userApi from "../api/modules/user.api";
import { getInitials } from "../utils/helpers";
import { ANGULAR_APP_URL } from "../config";

const items: MenuProps["items"] = [
  {
    key: "2",
    label: (
      <div
        key="2"
        onClick={e => {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = `${ANGULAR_APP_URL}/login`;
        }}
      >
        <PoweroffOutlined style={{ marginRight: "3px", fontSize: "18px" }} />
        Logout
      </div>
    ),
  },
];

const DropdownMenu = () => {
  const [user, setUser] = useState("");

  const getCurrentUser = async () => {
    const { response } = await userApi.getInfo();
    let user = `${response?.data.name} ${response?.data.surname}`;

    setUser(user);
  };

  useEffect(() => {
    getCurrentUser();
    return () => {};
  }, []);

  return (
    <Dropdown menu={{ items }} placement="bottom" arrow trigger={["click"]}>
      <div style={{ cursor: "pointer" }}>
        {user && (
          <Avatar className="user-avatar">{getInitials(`${user}`)}</Avatar>
        )}

        <CaretDownFilled />
      </div>
    </Dropdown>
  );
};

export default DropdownMenu;
