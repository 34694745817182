import React from 'react'
import { Modal, Form, Row, Col, Select, Button } from "antd"

interface LeaveModal {
  editModalOpen: boolean;
  handleEditModalOk: any;
  handleEditModalCancel: any;
  editLeave: any;
  form_edit: any;
  handleSwitchChange: any;
  workers: any;
  leavesOptions: any;
  years: any;
  months: any;
  dates: any;
  selectedValuesFrom: any;
  selectedValuesTo: any;
  handleSelectChangeFrom: any;
  handleSelectChangeTo: any;
}
const EditLeaveModal: React.FC<LeaveModal> = ({
  editModalOpen,
  handleEditModalOk,
  handleEditModalCancel,
  editLeave,
  form_edit,
  handleSwitchChange,
  workers,
  leavesOptions,
  years,
  months,
  dates,
  selectedValuesFrom,
  selectedValuesTo,
  handleSelectChangeFrom,
  handleSelectChangeTo
}) => {
  return (
    <>

      <Modal title="Edit One Time Leave"
        className='leaves_modal'
        open={editModalOpen}
        mask={false}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}>
        <Form initialValues={{
          remember: true,

        }}
          form={form_edit}
          onFinish={editLeave}
        >

          <Form.Item label="Worker Name"
            labelCol={{ span: 24 }}
            style={{ padding: ".5rem" }}

            name="worker_id"
            rules={[

              {
                required: true,
                message: "Leave Option is required",
              },
            ]}

          >
            <Select onChange={(e: any) => console.log(e)} options={
              workers?.map((item: any) => ({
                value: item.id,
                label: item.worker,
              }))


            }
              placeholder={"Year"}
            />


          </Form.Item>
          <Form.Item label="Leave Type"
            labelCol={{ span: 24 }}
            style={{ padding: ".5rem" }}

            name="reason"
            rules={[

              {
                required: true,
                message: "Leave Option is required",
              },
            ]}
          >
            <Select options={
              leavesOptions?.map((item: any) => ({
                value: item,
                label: item,
              }))
            }

            />


          </Form.Item>
          <div style={{ display: "flex", width: "100%", marginLeft: "12px" }}>
            <div style={{ width: "145px" }}>
              <label>
                Unavailable <br />from</label>
            </div>
            <Form.Item
              style={{ width: "100%" }}

              name="leave_start_date">

              <Row >
                <Col span={8} style={{ marginRight: "4px" }}>
                  <Select options={
                    years?.map((item: any) => ({
                      value: item,
                      label: item,
                    }))
                  }
                    onChange={(value: any) => handleSelectChangeFrom('select1', value)}
                    value={selectedValuesFrom.select1}
                    placeholder='year'
                    placement="topRight"

                  /></Col>
                <Col span={6} style={{ marginRight: "4px" }}>
                  <Select options={
                    months?.map((item: any) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  }
                    onChange={(value: any) => handleSelectChangeFrom('select2', value)}
                    value={selectedValuesFrom.select2}
                  />
                </Col>
                <Col span={6} style={{ marginRight: "4px" }}>
                  <Select options={
                    dates?.map((item: any) => ({
                      value: item,
                      label: item,
                    }))
                  }
                    onChange={(value: any) => handleSelectChangeFrom('select3', value)}

                    value={selectedValuesFrom.select3}
                  /></Col>
              </Row>


            </Form.Item>
          </div>
          <div style={{ borderLeft: "2px dotted black", height: "50px", marginTop: "-10px", marginLeft: "10px" }}></div>
          <div style={{ display: "flex", width: "100%", marginLeft: "12px" }}>

            <label style={{ width: "80px" }}>To</label>

            <Form.Item
              style={{ width: "100%" }}
              name="leave_end_date"

            >
              <Row className='unavilability_container'>
                <Col span={7} style={{ marginRight: "4px" }}>
                  <Select options={
                    years.map((item: any) => ({
                      value: item,
                      label: item,
                    }))
                  }
                    onChange={(value: any) => handleSelectChangeTo('select1', value)}
                    value={selectedValuesTo.select1}
                  /></Col>
                <Col span={6} style={{ marginRight: "4px" }}>
                  <Select options={
                    months.map((item: any) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  }
                    onChange={(value: any) => handleSelectChangeTo('select2', value)}
                    value={selectedValuesTo.select2}
                  />
                </Col>
                <Col span={5} style={{ marginRight: "4px" }}>
                  <Select options={
                    dates.map((item: any) => ({
                      value: item,
                      label: item,
                    }))
                  }
                    onChange={(value: any) => handleSelectChangeTo('select3', value)}

                    value={selectedValuesTo.select3}
                  /></Col>
              </Row>
            </Form.Item>

          </div>


          <Form.Item style={{ padding: ".5rem" }} className='leaves_modal_footer'>
            <Button type="default" onClick={handleEditModalCancel} className='leaves_cancel'>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className="add_team">
              Update Leave
            </Button>
          </Form.Item>
        </Form>
      </Modal ></>
  )
}

export default EditLeaveModal