import { useContext,useLayoutEffect, useEffect, useState } from "react"
import { getShift, getTeam, getdailySchedule } from "./functions"


export const useGetShift=(setShift:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{

    useEffect(()=>{
      getShift(setShift,setFetching)
    },[])

}

export const useGetTeam=(setTeam:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{

    useEffect(()=>{
      getTeam(setTeam,setFetching)
    },[])
}

export const useGetdailySchedule=(setdailySchedule:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{

  useEffect(()=>{
    getdailySchedule(setdailySchedule,setFetching)
  },[])
}

interface MousePosition {
  x: number;
  y: number;
}

export const useMousePosition = (isOpen: boolean)=> {
  const [position, setPosition] = useState<MousePosition>({
    x: 0,
    y: 0
  });
  useLayoutEffect(() => {
    function updatePosition(e: MouseEvent) {
      setPosition({ x: e.clientX, y: e.clientY });
    }
    if(!isOpen) {
      document.removeEventListener("mousemove", updatePosition);
    } else {
      document.addEventListener("mousemove", updatePosition);
    }
   
    return () => document.removeEventListener("mousemove", updatePosition);
  }, [isOpen]);

  return position;
}
