
import React, { useState, useContext } from 'react'
import { Popover, Space, Button } from 'antd'
import moment from 'moment';
import { EmployeeContextI } from '../../utils/interfaces'
import { EmployeeContext } from "../../Context/ShiftSettings/EmployeeContext";

const BREAK_DURATION = 60
const PopoverContent = ({ close, onCreate }: any) => {
    return (
        <Space>
            <div 
                onClick={() => {
                    onCreate('0');
                    close();
                }}
                className="delete-icon-container yellow">
               <span>P</span>
            </div>
            <div
                className="edit-icon-container"
                onClick={() => {
                    onCreate('1')
                    close();
                }}
            >
                <span>Shift</span>
            </div>
        </Space>
    )
}

interface Create {
    handleCreate: any;
    employee: string;
    month: number;
    year: number;
    day: number;
    shiftWorkingDays: any;
}

const CreateShiftModalButton: React.FC<Create> = ({ 
    handleCreate,
    employee,
    month,
    year,
    day,
    shiftWorkingDays,
}) => {
    const {
        employeeData
    } = useContext<EmployeeContextI>(EmployeeContext);

    const [isOpen, setIsOpen] = useState(false)

    const onCreate = (status: string) => {
        const dayOfWeek = moment({
            year: year,
            month: month,
            day: day + 1,
        }).format('dddd').toLowerCase()
        
        const workerShift = employeeData.find((item: any) => item.worker === employee);
        const shiftName_id = workerShift?.team.shiftName.id;
        const team_id = workerShift?.team.id;
        const external_id = workerShift?.external_id;
        const worker_id = workerShift?.id;
        const shiftworkingdays = shiftWorkingDays.find((workDay: any) => (
            workDay.day === dayOfWeek && workDay.shiftName.id === workerShift?.team.shiftName.id
        ));
        const { 
            start_time, 
            end_time
        } = shiftworkingdays;
        const shiftworkingdays_id = shiftworkingdays.id;

        const workingHours = moment.duration(
            moment(end_time, "HH:mm:ss").diff(moment(start_time, "HH:mm:ss"))
        ).asHours();

        const breakDurationInHours = BREAK_DURATION / 60; // Convert minutes to hours
        const totalWorkingHours = workingHours - breakDurationInHours;
        
        const startDate = moment.utc({
            year: year,
            month: month,
            day: day + 1,
            hour: start_time,
        }).utc().toISOString();

        const endDate = moment.utc({
            year: year,
            month: month,
            day: day + 1, 
            hours: end_time
        }).utc().toISOString();

        const data = {
            shiftName_id: shiftName_id,
            team_id: team_id,
            shiftworkingdays_id: shiftworkingdays_id,
            day: dayOfWeek, 
            external_id,
            start_date: startDate,
            end_date: endDate,
            status,
            worker_id,
            working_hours: totalWorkingHours
        }

        handleCreate(data)
    }

    return (
        <Popover
            open={isOpen}
            content={
                <PopoverContent 
                    close={() => setIsOpen(false)} 
                    onCreate={onCreate}
                />
            }
            trigger={["click"]}
            placement="bottom"
            className="leaves_popover-inner"
            onOpenChange={(visible: boolean) => setIsOpen(visible)}
        >
            <div className="shift_button_container">
                <Button
                    type="default"
                    size="small"
                    className='shift_button'
                    onClick={() => setIsOpen(true)}
                >
                +
                </Button>
            </div>
        </Popover>
    )
}

export default CreateShiftModalButton