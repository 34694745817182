import React, { useContext, useEffect, useMemo } from "react";

import { PlusOutlined, DeleteFilled } from "@ant-design/icons";
import {
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
} from "antd";

import { EmployeeContextI } from '../../../utils/interfaces'

import { EmployeeContext } from '../../../Context/ShiftSettings/EmployeeContext'
import { IShiftName } from '../../../components/schedule/types'

interface EmployeeModalI {
  title: string;
  submitButtonTitle: string;
  handleModalok: () => void;
  handleModalCancel: () => void;
  hadleFinish: (data:any) => void;
  shifts: {
    [key in string]: IShiftName
  };
  form: any;
  isOpen: boolean;
  isCreate?: boolean
}

const EmployeeModal: React.FC<EmployeeModalI> = props => {
  const {
    teamNames,
  } = useContext<EmployeeContextI>(EmployeeContext);

  const {
    title,
    submitButtonTitle,
    shifts,
    handleModalok,
    handleModalCancel,
    hadleFinish,
    form,
    isOpen
  } = props;


  const shiftName_id = Form.useWatch('shiftName_id', form);
  const team_id = Form.useWatch('team_id', form);

  
  useEffect(() => {
    if(!shiftName_id) return;

    const selectedSecondaryShifts = form.getFieldValue('secondaryShifts');
    const team_id = form.getFieldValue('team_id');
    
    const shiftIdIndexInSecondaryList = selectedSecondaryShifts ? selectedSecondaryShifts.indexOf(shiftName_id) : -1

    // Check if selected shift existed in secondary shifts and remove it 
    if(shiftIdIndexInSecondaryList !== -1) {
      form.setFieldValue('secondaryShifts', selectedSecondaryShifts.filter((id:string) => id !== shiftName_id))
    }
    if(team_id) {
      const selectedTeam = teamNames.find((teamItem: any) => teamItem.id === team_id);
      // Check if selected shiftId exists in selected team. Remove teamId if its not existed
      if(selectedTeam.shiftName.id !== shiftName_id) {
        form.setFieldValue('team_id', null)
      }
    }
  }, [shiftName_id, team_id, form])

  const shiftNames = useMemo(() => Object.keys(shifts).map(key => shifts[key]), [shifts])

  const teamNamesByShift = teamNames.filter((team: any) => team.shiftName.id === shiftName_id);
  const secondaryShifts = shiftNames.filter((shift: any) => shift.id !== shiftName_id);

  const renderSecondaryShifts = () => {
    return (
      <Form.List name="secondaryShifts">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => {
                return (
                  <div key={key}>
                    <Row style={{ marginBottom: 24 }}>
                      <Col span={20}>
                      <Form.Item
                        style={{ padding: ".5rem", marginBottom:0}}
                        label="Secondary Shift name"
                        name={name}
                        labelCol={{ span: 24 }}
                      >
                        <Select
                          placeholder="Select Shift"
                          options={secondaryShifts.map((item: any, index: number) => ({
                            value: item?.id,
                            label: item?.shiftName,
                          }))}
                        />
                      </Form.Item>
                      </Col>
                      <Col span={4}>
                        <div style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          marginTop: 20,
                        }}>
                          <Button
                            size={"large"}
                            icon={<DeleteFilled />}
                            onClick={() => remove(name)}
                            type="link"
                            danger
                          />
                          <Button
                            style={{ color: "black" }}
                            size={"large"}
                            icon={<PlusOutlined />}
                            onClick={() => add()}
                            type="link"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )
              })}
              {fields.length === 0 && <Row>
                <Col span={10}>
                  <div style={{
                    display: "flex",
                    alignItems: 'center',
                    marginLeft:10
                  }}>
                  
                    <Button
                      style={{ color: "black" }}
                      size={"large"}
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                      type="link"
                    > <span> Add secondary shift</span></Button>
                  </div>
                </Col>
              </Row>}
            </>
          )}
        </Form.List>
    )
  }

  return (
    <Modal
        title={title}
        className="teams_modal"
        open={isOpen}
        mask={false}
        onOk={handleModalok}
        onCancel={handleModalCancel}
      >
        <Form
          initialValues={{
            remember: true,
          }}
          onFinish={hadleFinish}
          form={form}
        >
          <Form.Item
            style={{ padding: ".5rem" }}
            label="Shift name"
            name="shiftName_id"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Shift Name is required",
              },
            ]}
          >
            <Select
              placeholder="Select Shift"
              options={shiftNames.map((item: any, index: number) => ({
                value: item?.id,
                label: item?.shiftName,
              }))}
            />
          </Form.Item>
          {renderSecondaryShifts()}
          <Form.Item
            label="Select Team"
            labelCol={{ span: 24 }}
            style={{ padding: ".5rem" }}
            name="team_id"
            rules={[
              {
                required: true,
                message: "Team ID is required",
              },
            ]}
          >
            <Select
              placeholder="Select Team"
              options={teamNamesByShift?.map((item: any, index: number) => ({
                value: item?.id,
                label: item?.teamName,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Employee Name"
            labelCol={{ span: 24 }}
            style={{ padding: ".5rem" }}
            name="worker"
            rules={[
              {
                required: true,
                message: "Team ID is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Position"
            labelCol={{ span: 24 }}
            style={{ padding: ".5rem" }}
            name="position"
            rules={[
              {
                required: true,
                message: "Position is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="External ID "
            labelCol={{ span: 24 }}
            style={{ padding: ".5rem" }}
            name="external_id"
            rules={[
              {
                required: true,
                message: "External ID is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item style={{ padding: ".5rem" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#20304f" }}
            >
              {submitButtonTitle}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
  );
};

export default EmployeeModal;
