import { Dropdown, MenuProps } from "antd";
import MenuIcon from "@mui/icons-material/Menu";
import { ANGULAR_APP_URL } from "../config";
import { useEffect, useState } from "react";
import userApi from "../api/modules/user.api";

const ROLE_ADMINISTRATOR = 1;
const ROLE_PLANNER = 2;
const ROLE_PERSON = 3;

const items = [
  {
    label: <a href={`${ANGULAR_APP_URL}/calendar`}>Calendar</a>,
    key: "0",
    roles: [ROLE_PLANNER],
  },
  {
    label: <a href={`${ANGULAR_APP_URL}/summary`}>Summary</a>,
    key: "1",
    roles: [ROLE_ADMINISTRATOR, ROLE_PLANNER],
  },
  {
    label: <a href={`${ANGULAR_APP_URL}/users`}>Users</a>,
    key: "2",
    roles: [ROLE_ADMINISTRATOR, ROLE_PLANNER],
  },
  {
    label: <a href={`${ANGULAR_APP_URL}/positions`}>Positions</a>,
    key: "3",
    roles: [ROLE_ADMINISTRATOR],
  },
  {
    label: <a href={`${ANGULAR_APP_URL}/teams/manage`}>Teams</a>,
    key: "4",
    roles: [ROLE_ADMINISTRATOR, ROLE_PLANNER],
  },
  {
    label: <a href={`${ANGULAR_APP_URL}/downloads`}>Downloads</a>,
    key: "5",
    roles: [ROLE_ADMINISTRATOR, ROLE_PLANNER, ROLE_PERSON],
  },
  {
    label: <a href={`${ANGULAR_APP_URL}/schedule-import`}>Import schedule</a>,
    key: "6",
    roles: [ROLE_ADMINISTRATOR],
  },
];

const HamburgerMenu = () => {
  const getRoles = async () => {
    await userApi.getRoles();
  };

  const getMenuItems = () => {
    const currentUserRaw = localStorage.getItem("user");
    const currentUserRoles = currentUserRaw
      ? JSON.parse(currentUserRaw ?? "").roles
      : "";

    const preparedItems: MenuProps["items"] = [];

    for (const item of items) {
      const { roles } = item;
      const isRoleExist = roles.some(role => currentUserRoles.includes(role));

      if (isRoleExist) {
        preparedItems.push(item);
      }
    }

    return preparedItems;
  };

  useEffect(() => {
    getRoles();
    getMenuItems();

    return () => {};
  }, []);

  return (
    <Dropdown
      menu={{ items: getMenuItems() }}
      placement="bottom"
      trigger={["click"]}
    >
      <MenuIcon style={{ cursor: "pointer" }} />
    </Dropdown>
  );
};

export default HamburgerMenu;
