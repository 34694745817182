import React, { useState, useContext } from 'react'
import { Modal, Form, Input, Button, DatePicker, Select} from 'antd'
import { DashboardContext } from '../../Context/Dashboard/DashboardContext'
import { DashboardContextI} from '../../utils/interfaces'

interface Props {}

const CreateForecastModal: React.FC<Props> = () => {
    const {
        createForecast,
        shiftdropdown
    } = useContext<DashboardContextI>(DashboardContext);
    const [form] = Form.useForm();

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true)
    };

    const handleCancel = () =>{
        setIsOpen(false);
        form.resetFields()
    };

    const handleFinish = (data: any) => {
        createForecast(data)
        handleCancel()
    }

    return (
        <>
            <Button onClick={handleOpen} type='primary' className='create-forecast-button'>
                Create Forecast
            </Button>
            <Modal
            forceRender
                title={'Create forecast'}
                className="forecast_modal"
                open={isOpen}
                width={400}
                mask={false}
                // onOk={() => {}}
                onCancel={handleCancel}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: true }}
            >
                <Form
                    onFinish={handleFinish}
                    form={form}
                    className='forecast_form'
                >
                    <Form.Item
                        style={{ padding: ".2rem", marginBottom: 0 }}
                        label="Shift ID"
                        name="shiftId"
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "Shift ID is required",
                            },
                        ]}
                    >
                        <Select 
                            placeholder="Select Shift"
                            options={
                                shiftdropdown?.map((item: any, index: number) => ({
                                    value: item?.id,
                                    label: item?.shiftName
                                }))
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        style={{ padding: ".2rem" }}
                        label="Last Date"
                        name="forecast_to"
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "Last Date is required",
                            },
                        ]}
                    >
                        <DatePicker 
                            showTime 
                            placeholder='Start Date' 
                            className='input-field'
                        />
                        {/* <Input className='input-field' /> */}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ backgroundColor: "#20304f" }}
                            >
                            Create Forecast
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CreateForecastModal;
