import { Col, Row, Tabs, TabsProps } from "antd";
import { useState } from "react";

import ShiftViewTable from "./ShiftViewTable";
import TeamWorkingPattern from "./TeamPattern";
import TeamComposition from "./TeamComposition";

function SchedulingReviewInput() {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "",
      children: <ShiftViewTable />,
    },
    {
      key: "2",
      label: "Tab 2",
      children: <TeamWorkingPattern />,
    },
    {
      key: "3",
      label: "Tab 3",
      children: <TeamComposition activeTab={activeTab}/>,
    },
  ];

  return (
    <div>
      <div
        style={{
          border: "none",
          width: "calc(100% - 40px)",
          margin: '0 20px',
          backgroundColor: "white",
          padding: "14px",
        }}
      >
        <Row>
          <Col span={6} style={{ marginLeft: 'auto'}}>
            <div className="custom-tabs-container">
              <div className="custom-tab-items">
                <div
                  className={`custom-tab-item ${
                    activeTab === "1" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("1")}
                >
                  Shifts
                </div>
                <div
                  className={`custom-tab-item ${
                    activeTab === "2" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("2")}
                >
                  Teams
                </div>
                <div
                  className={`custom-tab-item ${
                    activeTab === "3" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("3")}
                >
                  Employees
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Tabs
        activeKey={activeTab}
        items={items}
        onChange={handleTabChange}
        tabBarStyle={{ display: "none", marginTop: "200px" }}
      />
    </div>
  );
}
export default SchedulingReviewInput;
