import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import * as React from "react";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import TeamPattern from "./pages/TeamPattern";

import CustomSchedule from "./pages/CustomSchedulePage";
import SchedulingReviewInput from "./pages/SchedulingReviewInput";
import LeavePlans from "./pages/LeavePlans";
import Blank from "./pages/Blank";
import { routesList } from './utils/routesList'

const Schedule = React.lazy(() => import("./pages/CustomSchedulePage"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/blank" element={<Blank />} />

      <Route element={<Layout />}>
        <Route index path={routesList.home} element={<Home />} />
        <Route
          index
          path={routesList.shiftSettings}
          element={<SchedulingReviewInput />}
        />
        <Route index path={routesList.schedule} element={<CustomSchedule />} />

        <Route index path="/teampattern" element={<TeamPattern />} />
        <Route index path="/leaveplanner" element={<LeavePlans />} />
      </Route>
    </>
  )
);

export default router;
