import React from 'react'
import { 
    Modal, 
    Form, 
    Switch, 
    Select, 
    DatePicker, 
    Row, 
    Col, 
    Button
} from 'antd'

interface LeaveModal {
    isModalOpen: boolean;
    handleOk: any;
    handleCancel: any;
    addOneTimeLeave: any;
    handleSwitchChange: any;
    workers: any;
    leavesOptions: any;
    years: any;
    months: any;
    dates: any;
    selectedValuesFrom: any;
    selectedValuesTo: any;
    handleSelectChangeFrom: any;
    handleSelectChangeTo: any;
    form: any;
}

const AddOneTimeLeaveModal: React.FC<LeaveModal> = ({
    isModalOpen,
    handleOk,
    handleCancel,
    addOneTimeLeave,
    handleSwitchChange,
    workers,
    leavesOptions,
    years,
    months,
    dates,
    selectedValuesFrom,
    selectedValuesTo,
    handleSelectChangeFrom,
    handleSelectChangeTo,
    form
}) => {
    const handleSwith = (data: any) => {
        handleSwitchChange(data)
    }

    return (
        <>
            <Modal title="Add New leave"
                className='leaves_modal'
                open={isModalOpen}
                mask={false}
                onOk={handleOk}
                onCancel={handleCancel}>
                <Form initialValues={{
                    remember: true,
                }}
                    form={form}
                    onFinish={addOneTimeLeave}
                >
                    <Form.Item style={{ padding: ".5rem" }}>
                        <Switch onChange={handleSwith} checked={false}/>
                        <span className='one_time'>one time</span>
                    </Form.Item>
                    <Form.Item 
                        label="Worker Name"
                        labelCol={{ span: 24 }}
                        style={{ padding: ".5rem" }}
                        name="worker_id"
                        rules={[

                            {
                                required: true,
                                message: "Leave Option is required",
                            },
                        ]}

                    >
                        <Select onChange={(e: any) => console.log(e)} options={
                            workers.map((item: any) => ({
                                value: item.id,
                                label: item.worker,
                            }))
                            // [{
                            //     value: 'worker1',
                            //     label: 'Worker1',
                            // }]
                        } />
                    </Form.Item>

                    <Form.Item label="Leave Type"
                        labelCol={{ span: 24 }}
                        style={{ padding: ".5rem" }}

                        name="reason"
                        rules={[

                            {
                                required: true,
                                message: "Leave Option is required",
                            },
                        ]}
                    >
                        <Select options={
                            leavesOptions.map((item: any) => ({
                                value: item,
                                label: item,
                            }))
                        }
                        />
                    </Form.Item>

                    <Form.Item
                        label="Unavailable from"
                        style={{ width: "100%", padding: ".5rem", marginBottom: 0 }}
                        name="leave_start_date"
                        labelCol={{ span: 24 }}
                    >
                       <DatePicker style={{width: '100%'}} />
                    </Form.Item>

                    <Form.Item
                        label="Unavailable To"
                        style={{ width: "100%", padding: ".5rem" }}
                        name="leave_end_date"
                        labelCol={{ span: 24 }}
                    >
                        <DatePicker style={{width: '100%'}} />
                    </Form.Item>

                  
                    <Form.Item style={{ padding: ".5rem" }} className='leaves_modal_footer'>
                        <Button type="default" onClick={handleCancel} className='leaves_cancel'>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" className="add_team">
                            Apply
                        </Button>
                    </Form.Item>
                </Form>
            </Modal >
        </>
    )
}

export default AddOneTimeLeaveModal;