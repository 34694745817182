import React, { useState } from "react";
import {
  Checkbox,
  Row,
  Col,
  Select,
  Button,
  Input,
  notification,
  Form,
  Card,
  ColorPicker,
  Popover,
} from "antd";
import isEqual from "lodash/isEqual";
import {
  EditOutlined,
  CloudUploadOutlined,
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  ShiftdetailsUrl,
  shiftworkingdaysUrl,
  workerUrl,
  productTypesSettingsUrl,
  shifNameUrl,
} from "../utils/network";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useGetShift } from "../utils/hooks";
import moment from "moment";
import dayjs from "dayjs";

import DeleteModal from "../components/Modals/DeleteModal";
import WorkingDaysModal from "../components/Modals/ShiftDetails/WorkingDaysModal";
import { useNavigate } from "react-router-dom";
import UpdateShiftDaysModal from "../components/Modals/ShiftDetails/UpdateShiftDaysModal";
import AddNewShiftModal from "../components/Modals/ShiftDetails/AddNewShiftModal";

// import { shiftNameData } from '../mock_data/shitName';
// import { ShiftdetailsViewSetMock } from '../mock_data/shiftdetailsViewSet '
// import { productTypesData } from '../mock_data/productTypes';
// import { fetchData } from '../utils/mock';

const dayOrder = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

interface ConI {
  close: any;
}

const ShiftViewTable = () => {
  const [fetching, setFetching] = useState(true);
  const [shifts, setShifts] = useState([]);
  const [shiftsData, setShiftsData] = useState([]);
  const [workingdaysModal, setWorkingDaysModal] = useState(false);
  const [shiftModal, setShiftModal] = useState(false);
  const [daysUpdate, setDaysUpdate] = useState(false);
  const [searchTerm, setSerachterm] = useState("");
  const [shiftNames, setShiftNames] = useState<any[]>([]);
  const [productTypes, setProductTypes] = useState<any[]>([]);
  const [selectedWeekdays, setSelectedWeekdays]: any = useState([]);
  const [shiftid, setShiftId]: any = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  // const [shiftNameUpdate, setShiftNameUpdate] = useState(false);
  const [shiftForm] = Form.useForm();
  const [form] = Form.useForm();

  const [shiftDaysEditModal, setShiftDaysEditModal] = useState(false);
  const [shiftWorkingDayId, setShiftWorkingDaysId]: any = useState();
  const [shiftdays_form] = Form.useForm();
  const [recentShift, setRecentShift]: any = useState({});
  const [filterShiftid, setFilterShiftId] = useState();

  useGetShift(setShiftNames, setFetching);
  //handle shift Change
  const handleShiftChange = (value: any) => {
    console.log("value", value);

    setFilterShiftId(value);
  };

  const showModal_workingDays = () => {
    setWorkingDaysModal(true);
  };

  const handleOK_workingDays = () => {
    form.resetFields();
    if (daysUpdate) {
      setDaysUpdate(false);
    }
    setShiftId(null);
    setWorkingDaysModal(false);
  };

  const handleCancel_workingDays = () => {
    form.resetFields();
    if (daysUpdate) {
      setDaysUpdate(false);
    }
    setShiftId(null);
    setWorkingDaysModal(false);
  };

  const showModal_shiftName = () => {
    shiftForm.resetFields();
    setShiftModal(true);
  };
  const handleOk_shiftName = () => {
    setShiftModal(false);
  };
  const handleCancel_shiftName = () => {
    setShiftModal(false);
    shiftForm.resetFields();
  };
  const showDeleteModal = () => {
    setDeleteModal(true);
  };
  const handleDeleteModalCancel = () => {
    setDeleteModal(false);
    setShiftId(null);
  };
  const handleDeleteModalOk = () => {
    setDeleteModal(false);
    setShiftId(null);
  };

  const getShiftDetailsView = async () => {
    try {
      const response = await axios.get(ShiftdetailsUrl, {
        params: {
          shiftId: filterShiftid,
        },
      });

      // const response = {
      //     data: ShiftdetailsViewSetMock
      // };
      const shifts = response.data;

      setShiftsData(shifts);
      return shifts;
    } catch (e) {
      notification.error({
        message: "error while fetching shifts details",
      });
    }
  };

  // Function to handle field click
  const handleFieldClick = (weekday: string) => {
    // Check if the weekday is already selected, and toggle its selection
    if (selectedWeekdays.includes(weekday)) {
      setSelectedWeekdays(
        selectedWeekdays.filter((day: any) => day !== weekday)
      );
    } else {
      setSelectedWeekdays([...selectedWeekdays, weekday]);
    }
  };

  // const getShiftNames = async (newShiftData: any) => {
  const getShiftNames = async () => {
    const response = await axios.get(shifNameUrl);

    // const response = {
    //     data: shiftNameData
    // }
    const data = response.data.results;
    setShifts(data);

    // const shiftData = [...data]
    // if(newShiftData) {
    //     shiftData.push(newShiftData)
    // }
    // setShifts(shiftData);
  };

  const getProductTypeData = (productItem: any, shiftId: string) => {
    return {
      product_name: productItem.product_name || "",
      seconds_to_finish: +productItem.seconds_to_finish,
      organization_id: 1,
      shiftName_id: +shiftId,
    };
  };

  //submit method to add working days
  const SubmitWorkingDays = async () => {
    // UPDATE WORKING DAYS

    const formValues = form.getFieldsValue();

    const shiftIdValue = shiftid || recentShift?.id;

    const filteredTypes = productTypes.filter(
      item => item.shiftName == shiftIdValue
    );

    const filteresdShifts = shiftsData.filter(
      // @ts-ignore
      item => item.shiftName.id == shiftIdValue
    );
    const isDaysExisted = filteresdShifts.length > 0;

    const start_time: string[] = [];
    const end_time: string[] = [];
    const break_duration_min: number[] = [];
    const shift: string[] = [];

    const updateDaysPromises: any = []; // for update only
    let productTypesPromises: any = []; // for update only

    dayOrder.forEach((day: string) => {
      const dayValues = formValues[day];
      const isDaySelected = dayValues.shift === "yes";

      const start_time_value = isDaySelected
        ? dayValues.start_time.format("HH:mm")
        : "00:00";
      const end_time_value = isDaySelected
        ? dayValues.end_time.format("HH:mm")
        : "12:00";
      const break_duration_min_value = isDaySelected
        ? dayValues.break_duration_min
        : 0;
      const shift_value = isDaySelected ? "yes" : "no";

      start_time.push(start_time_value);
      end_time.push(end_time_value);
      break_duration_min.push(break_duration_min_value);
      shift.push(shift_value);

      if (daysUpdate) {
        const oldShift: any = filteresdShifts.find(
          (item: any) => item.day === day
        );

        const updateShiftRequest = axios.put(
          `${ShiftdetailsUrl}/${oldShift?.id}`,
          {
            shiftName_id: +shiftIdValue,
            end_time: end_time_value,
            shift: shift_value,
            start_time: start_time_value,
            break_duration_min: break_duration_min_value,
            day,
          }
        );
        updateDaysPromises.push(updateShiftRequest);
      }
    });

    const { products } = formValues;

    if (typeof products !== "undefined") {
      if (daysUpdate) {
        filteredTypes.forEach((productItem: any) => {
          const existedItem = products?.find(
            (prod: any) => prod.id === productItem.id
          );
          if (existedItem) {
            const dataToUpdate = getProductTypeData(
              existedItem,
              existedItem.shiftName
            );

            if (!isEqual(productItem, existedItem)) {
              productTypesPromises.push(
                axios.put(
                  `${productTypesSettingsUrl}/${productItem.id}`,
                  dataToUpdate
                )
              );
            }
          } else {
            productTypesPromises.push(
              axios.delete(`${productTypesSettingsUrl}/${productItem.id}`)
            );
          }
        });

        const newProductTypes = products.filter(
          (prodItem: any) => !prodItem.id
        );

        newProductTypes.forEach((productItem: any) => {
          const newProductTypeData = getProductTypeData(
            productItem,
            shiftIdValue
          );
          productTypesPromises.push(
            axios.post(productTypesSettingsUrl, newProductTypeData)
          );
        });
      } else {
        products.forEach((productItem: any) => {
          const newProductTypeData = getProductTypeData(
            productItem,
            shiftIdValue
          );
          productTypesPromises.push(
            axios.post(productTypesSettingsUrl, newProductTypeData)
          );
        });
      }
    }

    try {
      let response;

      await updateShiftName(
        {
          shiftName: formValues.shiftName,
          color: typeof formValues.color === 'string' 
           ? formValues.color
           : formValues.color.toHexString()
        },
        +shiftIdValue
      );

      if (daysUpdate && isDaysExisted) {
        response = await Promise.all(updateDaysPromises);
      } 
      if(!isDaysExisted) {
        const formData = {
          shiftName_id: +shiftIdValue,
          start_time,
          end_time,
          shift,
          break_duration_min,
          day: dayOrder,
        } as any;

        response = await axios.post(ShiftdetailsUrl, formData);
      }

      if (typeof products !== "undefined") {
        const productTypesResponse = await Promise.all(productTypesPromises);
        notification.success({
          message: daysUpdate
            ? "Product Types has been updated"
            : "Product Types created successfully",
        });
      }

      // @ts-ignore
      const data = response?.data;

      if (response) {
        notification.success({
          message: daysUpdate
            ? "Shift Working Days has been updated"
            : "Shift Working Days created successfully",
        });
        handleOK_workingDays();
        getShiftDetailsView();
        getShiftNames();
        setSelectedWeekdays([]);
        // setDaysArray([]);
        setRecentShift(null);
      }
      return data;
    } catch (e) {
      console.log(e, "Error in update request");
      notification.error({
        message: daysUpdate
          ? "Failed to update Working Days"
          : "error while creating Working Days",
      });
    }
  };

  const getProductTypes = async () => {
    try {
      const productTypesResponse: AxiosRequestConfig = await axios.get(
        productTypesSettingsUrl
      );
      // const productTypesResponse = {
      //     data: productTypesData
      // };

      setProductTypes(productTypesResponse.data);
    } catch (e) {
      notification.error({
        message: "Error fetching Product types",
      });
    }
  };

  //GET SHIFT WORKING DAYS
  const getShiftWorkingDays = async () => {
    try {
      const response = await axios.get(ShiftdetailsUrl);

      setShiftWorkingDays(response.data);
      return response;
    } catch (e) {
      notification.error({
        message: "Error fetching shift working days",
      });
    }
  };

  const setShiftDetailsToForm = (updatedShifts: any, shiftId: number) => {
    let shift: any = shifts.find((item: any) => item.id === shiftId);

    if (!shift) {
      shift = shiftsData.find((item: any) => item.shiftName.id === shiftId);
      shift = shift?.shiftName;
    }

    const filteredTypes = productTypes.filter(
      item => item.shiftName === shift.id
    );

    const shiftName = shift?.shiftName;
    const shiftColor = shift?.color;

    let initialFormData: {
      [key in string]: {
        start_time: any;
        end_time: any;
        break_duration_min: number;
        products: any;
      };
    } = {};

    updatedShifts.forEach((item: any) => {
      initialFormData = {
        ...initialFormData,
        [item.day]: {
          id: item.id,
          start_time: dayjs(item.start_time, "HH:mm"),
          end_time: dayjs(item.end_time, "HH:mm"),
          break_duration_min: +item.break_duration_min,
          shift: item.shift,
        },
      };
    });

    // const initialProductTypeForm = {
    //     shiftName,
    //     shiftId,
    //     products: filteredTypes
    // };

    // @ts-ignore
    initialFormData.shiftName = shiftName;
    // @ts-ignore
    initialFormData.shiftId = shiftId;
     // @ts-ignore
    initialFormData.color = shiftColor;

    // @ts-ignore
    initialFormData.products = filteredTypes;

    form.setFieldsValue(initialFormData);
  };

  //ADD SHIFT NAME
  const AddShiftName = async (values: any) => {
    const data = { ...values };
    
    const hexColor = data.color.toHexString()

    try {
      const response = await axios.post(shifNameUrl, {
        shiftName: data.shiftName,
        color: hexColor
      });
     
      const shiftData = response.data;
      if (response) {
        notification.success({
          message: "New Shift has been added",
        });
        // TODO: REMOVE ARGUMENT BEFORE PUSH
        // getShiftNames(shiftData)
        getShiftNames();

        getShiftDetailsView();
        handleOk_shiftName();

        shiftForm.resetFields();

        form.setFieldsValue({
          color: response.data.color
        });
      
        setRecentShift(response.data);
        showModal_workingDays();
      }
      return shiftData;
    } catch (e) {
      notification.error({
        message: "New Shift has been added",
      });
    }
  };

  const updateShiftName = async (values: any, shiftIdValue: number) => {
    const data = { ...values };
    try {
      const response = await axios.put(`${shifNameUrl}/${shiftIdValue}`, data);
      const shiftData = response.data;

      if (response) {
        notification.success({
          message: "Shift Name has been updated",
        });
        await getShiftNames();
      }
      return shiftData;
    } catch (e) {
      notification.error({
        message: "Can't Update Shift",
      });
    }
  };
  //DELETE SHIFT
  const deleteShift = async (id: number) => {
    try {
      const response = await axios.delete(`${shifNameUrl}/${id}`);
      if (response) {
        notification.success({
          message: "Shift has been deleted",
        });
        getShiftDetailsView();
        getShiftNames();
      }
    } catch (e) {
      notification.error({
        message: "Shift can't be deleted",
      });
    }
  };

  const groupedData: any = {};
  const shiftColors: any = {}
  shiftsData?.forEach((currentValue: any) => {
    const shiftColor: any = currentValue?.shiftName?.color;
    const shiftName: any = currentValue?.shiftName?.shiftName
    
    const shiftId: any = currentValue?.shiftName?.id;

    if (!groupedData[shiftName]) {
      groupedData[shiftName] = {};
    }
    if (!groupedData[shiftName][shiftId]) {
      groupedData[shiftName][shiftId] = [];
    }
    const itemWithoutShift = { ...currentValue };
    delete itemWithoutShift.shiftName;
    delete itemWithoutShift.shiftId;
    groupedData[shiftName][shiftId].push(itemWithoutShift);
    shiftColors[shiftName] = shiftColor
  }, {});

  shifts.forEach(({ id, shiftName, created_at, color }: any) => {
    const notCompleted = !groupedData[shiftName];
    if (notCompleted) {
      shiftColors[shiftName] = color
      groupedData[shiftName] = {
        [id]: [],
      };
    }
  });

  let test: any;
  let workingDaysData: any;
  for (let i in groupedData) {
    for (let j in groupedData[i]) {
      if (j === shiftid) {
        test = i;
        workingDaysData = groupedData[i];
      }
    }
  }

  const [shiftWorkingDays, setShiftWorkingDays] = useState([]);

  let shiftDays: any = [];

  for (let i in workingDaysData) {
    if (shiftid != null) {
      shiftDays = workingDaysData[i];
    }
  }

  // const handleDayClick = (dayValue: string) => {
  //     if (daysArray?.includes(dayValue)) {
  //         setDaysArray(daysArray.filter((d: any) => d !== dayValue));
  //     } else {
  //         setDaysArray((prevArray: any) => [...prevArray, dayValue]);
  //     }
  // };
  const handleUpdatedDayClick = (dayIndex: number, dayValue: string) => {
    shiftDays?.map((shift: any) => {
      if (
        shift?.day.charAt(0).toUpperCase() + shift?.day.slice(1) ==
        dayValue
      ) {
        shiftDays = shiftDays?.filter(
          (shift: any) =>
            shift?.day.charAt(0).toUpperCase() + shift?.day.slice(1) !==
            dayValue
        );
      }
      return shiftDays;
    });
  };

  const handleShowUpdateShiftFull = (shiftId: number) => {
    // const updatedShifts = await getShiftDetailsView()

    const shiftDetails =
      // @ts-ignore
      shiftsData?.filter(item => item?.shiftName?.id === shiftId) || [];

    setSelectedWeekdays(shiftDetails.map((item: any) => item.day));

    setShiftDetailsToForm(shiftDetails, shiftId);
    setDaysUpdate(true);
    showModal_workingDays();
  };


  const handleShifDaysEditModalOk = () => {
    setShiftDaysEditModal(false);
  };
  const handleShiftDaysEditModalCancel = () => {
    setShiftDaysEditModal(false);
  };

  const updateShiftId = test;
  // const getShiftDaysValue = () => {

  //     const shiftDayUpdate: any = shiftsData.find(
  //         (day: any) => day?.id == shiftWorkingDayId
  //     );

  //     shiftdays_form.setFieldsValue({
  //         shiftName_id: shiftDayUpdate?.shiftName?.shiftName,
  //         shift: shiftDayUpdate?.shift,
  //         day: shiftDayUpdate?.day,
  //         start_time: shiftDayUpdate?.start_time ? dayjs(shiftDayUpdate.start_time, 'HH:mm'):  '00:00',
  //         end_time: shiftDayUpdate?.end_time ? dayjs(shiftDayUpdate.end_time, 'HH:mm') : '12:00',
  //         break_duration_min: + (shiftDayUpdate?.break_duration_min || 0),
  //     });
  // };

  //method to Update Shift Working Day
  const updateShiftWorkingDay = async (values: any) => {
    const updateddata = { ...values };
    try {
      const response = await axios.put(
        `${ShiftdetailsUrl}/${shiftWorkingDayId}`,
        updateddata
      );

      const data = response.data;
      if (response) {
        notification.success({
          message: "Working Day has been updated",
        });
      }
      window.location.reload();
      handleShifDaysEditModalOk();
      return data;
    } catch (e) {
      notification.error({
        message: "Failed to update working day",
      });
    }
  };
  //method to delete a shift working day
  // const deleteWorkingDay = async () => {
  //     // add shift working day
  //     try {
  //         const response = axios.delete(
  //             `${ShiftdetailsUrl}/${shiftWorkingDayId}`
  //         );
  //         notification.success({
  //             message: "Working Day has been deleted",
  //         });
  //         window.location.reload();
  //         handleShifDaysEditModalOk();

  //         return response;
  //     } catch (e) {
  //         notification.error({
  //             message: "Cant't delete working day",
  //         });
  //     }
  // };

  // const Content: React.FC<ConI> = ({ close }) => {
  //     return (<Space>
  //         <div className="delete-icon-container">
  //             <DeleteOutlined
  //                 className="delete-icon"
  //                 onClick={() => {
  //                     showWDaydeleteModal();
  //                     close();
  //                 }}
  //             />
  //         </div>
  //         <div
  //             className="edit-icon-container"
  //             onClick={() => {
  //                 // showModal_workingDays();
  //                 showEditShiftDaysModal()
  //                 setDaysUpdate(true);
  //                 getShiftDaysValue();
  //                 close();
  //             }}
  //         >
  //             <EditOutlined className="edit-icon" />
  //         </div>
  //     </Space>)
  // }

  React.useEffect(() => {
    getShiftDetailsView();
    getShiftWorkingDays();
    getShiftNames();
    getProductTypes();
    // getShiftUpdateName();

    //getShiftWorkingDaysData();
  }, [filterShiftid, test]);

  return (
    <>
      <Card
        style={{ 
          margin: "0 20px", 
          border: "none", 
          borderRadius: 0, 
          padding: "15px !important" 
        }}
        className="shift-filters-row"
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <div>
                  <SearchOutlined
                    style={{
                      position: "absolute",
                      top: "10px",
                      zIndex: "1",
                      marginLeft: "12px",
                      fontSize: "17px",
                    }}
                  />
                  <Input
                    className="shift-input"
                    placeholder="Search by name or id"
                    onChange={(e: any) => setSerachterm(e.target.value)}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#20304F",
                      color: "white",
                      borderRadius: 5,
                      width: "200px",
                    }}
                    type="default"
                    size="large"
                    icon={<PlusOutlined />}
                    onClick={showModal_shiftName}
                  >
                    Add New Shift
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Row style={{ padding: "0 20px"}}>
        <Col span={24}>
          <Card className="shift_view_card">
            <table className="shifts_table" style={{ borderRadius: 12 }}>
              <thead>
                <tr>
                  <th className="shift_name_heading ">
                    <Checkbox />
                    <span style={{ marginLeft: "24px" }}>Shift name</span>
                  </th>
                  <th>Color</th>
                  <th>Working days</th>
                  <th>Teams</th>
                  <th>Employees</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupedData).map(
                  ([shiftName, shiftData]: any) =>
                    Object.entries(shiftData)
                      .filter((name: any) => {
                        return searchTerm == ""
                          ? name
                          : shiftName.includes(searchTerm);
                      })
                      .map(([id, items]: any) => {
                        const sortedItems = items.sort((a: any, b: any) => {
                          const dayA = a.day.toLowerCase();
                          const dayB = b.day.toLowerCase();
                          return (
                            dayOrder.indexOf(dayB) - dayOrder.indexOf(dayA)
                          );
                        });
                        const shiftColor = shiftColors[shiftName];

                        return (
                          <tr
                            key={id}
                            className="data_row"
                            id={
                              Math.random() * 1000 + 21 + (23 - 20).toString()
                            }
                          >
                            <td>
                              <Checkbox style={{ marginLeft: "-2px" }} />

                              <span style={{ marginLeft: "24px" }}>
                                {shiftName}
                              </span>
                            </td>
                            <td>
                              <span >
                                {shiftColor && <ColorPicker open={false} value={shiftColor} showText/>}
                              </span>
                            </td>
                            <td className="shiftWorkingDays">
                              {sortedItems?.map((shift: any, i: any) => (
                                <div className="daysViewSet" key={i}>
                                  <div
                                    className={
                                      shift?.shift == "yes"
                                        ? "days"
                                        : "inactive_days"
                                    }
                                  >
                                    {shift?.day?.slice(0, 3)?.toUpperCase()}
                                  </div>
                                </div>
                              ))}
                            </td>
                            <td>
                              <div className="team_name">
                                {sortedItems[0]?.teams?.teams?.map(
                                  (team: any) => (
                                    <ul key={team?.id}>
                                      <li>
                                        <div> {team?.teamName}</div>
                                      </li>
                                    </ul>
                                  )
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="shift_count_table">
                                <div className="shift_workers_count">
                                  {sortedItems[0]?.number_of_workers || 0}
                                </div>
                              </div>
                            </td>
                            <td>
                              <EditOutlined
                                style={{
                                  marginRight: "20px",
                                  fontSize: "20px",
                                }}
                                onClick={() => {
                                  setShiftId(id);

                                  // getShiftUpdateName();
                                  //getShiftWorkingDaysData();
                                  // showModal_shiftName();
                                  // setShiftNameUpdate(true);

                                  handleShowUpdateShiftFull(+id);
                                }}
                              />
                              <DeleteOutlined
                                style={{ fontSize: "20px", color: "red" }}
                                onClick={() => {
                                  showDeleteModal();
                                  setShiftId(id);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                )}
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>
 
      <AddNewShiftModal
        shiftModal={shiftModal}
        handleOk_shiftName={handleOk_shiftName}
        handleCancel_shiftName={handleCancel_shiftName}
        AddShiftName={AddShiftName}
        shiftForm={shiftForm}
      />

      {workingdaysModal && (
        <WorkingDaysModal
          workingdaysModal={workingdaysModal}
          handleOK_workingDays={handleOK_workingDays}
          handleCancel_workingDays={handleCancel_workingDays}
          form={form}
          SubmitWorkingDays={SubmitWorkingDays}
          // handleDayClick={handleDayClick}
          shifts={shifts}
          handleFieldClick={handleFieldClick}
          shiftId={shiftid}
          handleUpdatedDayClick={handleUpdatedDayClick}
          shiftWorkingDays={shiftDays}
          recentShift={recentShift}
          isUpdate={daysUpdate}
          updatedShiftid={updateShiftId}
        />
      )}

      <DeleteModal
        title="Are you sure you want to delete this Shift?"
        deleteOpen={deleteModal}
        deleteOk={handleDeleteModalOk}
        deleteCancel={handleDeleteModalCancel}
        handleDelete={deleteShift}
        id={shiftid}
      />
      <UpdateShiftDaysModal
        shiftDaysEditModal={shiftDaysEditModal}
        handleShifDaysEditModalOk={handleShifDaysEditModalOk}
        handleShiftDaysEditModalCancel={handleShiftDaysEditModalCancel}
        shiftdays_form={shiftdays_form}
        updateShiftWorkingDay={updateShiftWorkingDay}
        shifts={shifts}
      />
    </>
  );
};

export default ShiftViewTable;
