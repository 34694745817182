import React, { useContext } from "react";
import { EmployeeContextI } from '../../../utils/interfaces'
import { EmployeeContext } from '../../../Context/ShiftSettings/EmployeeContext'
import { IShiftName } from '../../../components/schedule/types'
import EmployeeModal from './EmployeeModal'

interface EditEmployeeModalI {
  handleModalOk: () => void;
  handleModalCancel: () => void;
  shifts: {
    [key in string]: IShiftName
  },
}

const EditEmployeeModal: React.FC<EditEmployeeModalI> = props => {
  const {
    updateEmployeeModal,
    updateEmployeeData,
    updateForm,
  } = useContext<EmployeeContextI>(EmployeeContext);

  const {
    shifts,
    handleModalOk,
    handleModalCancel
  } = props;

  if(!updateEmployeeModal) return null;
  return (
    <EmployeeModal 
      title='Update Employee'
      submitButtonTitle='Update Employee'
      handleModalok={handleModalOk}
      handleModalCancel={handleModalCancel}
      hadleFinish={updateEmployeeData}
      form={updateForm}
      isOpen={updateEmployeeModal}
      shifts={shifts}
    />
  )
};

export default EditEmployeeModal;
