
import { Checkbox, Avatar } from "antd";
import {  UserOutlined } from "@ant-design/icons";
import { numberToHoursMinutes } from '../../utils/dates'
interface ITeamMemberColumnProps {
  name: string;
  id: string;
  total?: number
  color?:string;
};

const TeamMemberColumn = ({
  name, id, total = 0, color 
}: ITeamMemberColumnProps) => {
  const totalLabel = numberToHoursMinutes(total)
  return (
    <div className="teams-member-col-desc">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox className="checkbox" ></Checkbox>
        <Avatar
          size={30}
          style={{
            minWidth: 30,
            background: "white",
            border: "1px solid #ebebeb"
          }}
          icon={<UserOutlined style={{ color: color || "#779cbf", fontSize: "1.5rem" }} />}
        />
        <div className="text-wrapper">
          <p className='name'>{name}</p>
          <p className='id'> ID: <span> {id}</span></p>
        </div>
      </div>
      {/* <div className="warning-rounded">!</div> */}
      {total || total === 0  ? 
      <div className="rounded-wrapper">
        <div className='rounded'>{totalLabel}</div>
      </div> : null}
    </div>
  )
}

export default TeamMemberColumn;