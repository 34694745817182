import { Fragment, useContext } from 'react';

import AddOneTimeLeaveModal from '../Modals/OneTimeLeave/AddLeaveModal';
import EditLeaveModal from '../Modals/OneTimeLeave/EditLeaveModal';
import AddRecurringLeaveModal from '../Modals/RecurringLeave/AddRecurringLeaveModal';

import { LeaveTrackerContext } from '../../Context/LeaveTracker/LeaveTrackerContext'
import { LeaveTrackerContextI } from '../../utils/interfaces'
import { months } from "../../utils/dates";


const LeaveModals = (props: any) => {
  const {
    leavesOptions,
    years,
    workers,
    isModalOpen,
    handleOk, 
    handleCancel,
    addOneTimeLeave,
    handleSwitchChange, 
    add_leave,
    dates, 
    selectedValuesFrom,
    selectedValuesTo,
    handleSelectChangeFrom,
    handleSelectChangeTo,
    editModaOpen,
    handleEditModalOk,
    handleEditModalCancel,
    editLeave,
    form_edit,
    updateRecurr,
    recurringModal,
    handleModalClose,
    addRecurringLeave,
    add_recurr,
    handleRecurrSwitchChange,
    handleSelectChangeFromRecurr,
    selectedValuesFromRecurr,
    handleSelectChangeToRecurr,
    selectedValuesToRecurr,
  } = useContext<LeaveTrackerContextI>(LeaveTrackerContext);
 
  return (
    <Fragment>
      <AddOneTimeLeaveModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        addOneTimeLeave={addOneTimeLeave}
        handleSwitchChange={handleSwitchChange}
        workers={workers}
        leavesOptions={leavesOptions}
        years={years}
        months={months}
        form={add_leave}
        dates={dates}
        selectedValuesFrom={selectedValuesFrom}
        selectedValuesTo={selectedValuesTo}
        handleSelectChangeFrom={handleSelectChangeFrom}
        handleSelectChangeTo={handleSelectChangeTo}
      />

      <EditLeaveModal
        editModalOpen={editModaOpen}
        handleEditModalOk={handleEditModalOk}
        handleEditModalCancel={handleEditModalCancel}
        editLeave={editLeave}
        form_edit={form_edit}
        handleSwitchChange={handleSwitchChange}
        workers={workers}
        leavesOptions={leavesOptions}
        years={years}
        months={months}
        dates={dates}
        selectedValuesFrom={selectedValuesFrom}
        selectedValuesTo={selectedValuesTo}
        handleSelectChangeFrom={handleSelectChangeFrom}
        handleSelectChangeTo={handleSelectChangeTo}
      />

      <AddRecurringLeaveModal
        isUpdate={updateRecurr}
        recurringModal={recurringModal}
        handleOk={handleOk}
        handleModalClose={handleModalClose}
        addRecurringLeave={addRecurringLeave}
        add_recurr={add_recurr}
        handleRecurrSwitchChange={handleRecurrSwitchChange}
        workers={workers}
        years={years}
        months={months}
        dates={dates}
        handleSelectChangeFrom={handleSelectChangeFromRecurr}
        selectedValuesFrom={selectedValuesFromRecurr}
        handleSelectChangeTo={handleSelectChangeToRecurr}
        selectedValuesTo={selectedValuesToRecurr}
      />
    </Fragment>
  )
}

export default LeaveModals;