import React, { ReactNode, useState } from "react";
import { TeamsContextI } from "../../utils/interfaces";
import { Form, notification } from "antd";
import axios, { AxiosResponse } from "axios";
import {
  teamcompositionUrl,
  teamworkingpatternUrl,
  teamUrl,
  teamConstraintsViewUrl,
} from "../../utils/network";
import { useGetShift, useGetTeam } from "../../utils/hooks";

// import { teamConstraintsViewMock } from '../../mock_data/teamConstraintsView'
// import { teamWorkingPatternMock } from '../../mock_data/teamWorkingPattern';
// import { fetchData } from '../../utils/mock'

export const TeamsContext: any = React.createContext<TeamsContextI | undefined>(
  undefined
);

export const TeamsProvider = ({ children }: { children: ReactNode }) => {
  const [teamsData, setTeamData] = useState([]);
  const [constraintsData, setConstraintsData] = useState([]);
  const [employees, setEmployees]: any = useState();

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [keys, setKeys]: any = useState([]);
  const [teamId, setTeamId]: any = useState();
  const [recentTeam, setRecentTeam]: any = useState();
  const [updateWorkingDays, setUpdateWorkingDays] = useState(false);
  const [workingDaysId, setEditWorkingDaysId] = useState("");
  // const [teamUpdate, setTeamUpdate] = useState(false);
  const [form] = Form.useForm();

  const [workingDaysForm] = Form.useForm();

  // const handleOk = () => {
  //     setIsModalOpen(false);
  // };
  const handleTeamModalOk = () => {
    setTeamModalOpen(false);
  };

  const getTeamWorkingPattern = async () => {
    try {
      const response: AxiosResponse = (await axios.get(
        teamworkingpatternUrl
      )) as AxiosResponse;
      // const response = await fetchData(teamWorkingPatternMock)

      const teams = response.data;

      setTeamData(teams?.results);
      setKeys(teams?.results[0]);

      return teams;
    } catch (e) {
      notification.error({
        message: "error while fetching teams details",
      });
    }
  };

  const getTeamConstraints = async () => {
    try {
      const response: AxiosResponse = (await axios.get(
        teamConstraintsViewUrl
      )) as AxiosResponse;
      // const response = await fetchData(teamConstraintsViewMock)

      setConstraintsData(response.data);
    } catch (e) {
      notification.error({
        message: "error while fetching Team Constraints",
      });
    }
  };

  const getTeamComposition = async () => {
    try {
      const response = await axios.get(teamcompositionUrl);
      const employees = response.data.results;
      setEmployees(employees);
    } catch (e: any) {
      console.log(e);
    }
  };

  const createShiftName = async (formData: any) => {
    try {
      const response: AxiosResponse = (await axios.post(teamUrl, {
        shiftName_id: formData.shiftName_id,
        teamName: formData.teamName,
      })) as AxiosResponse;

      const data = response.data;

      if (data) {
        notification.success({
          message: "team created successfully",
        });
        setRecentTeam(data);
        form.resetFields();
      }

      // handleOk();
      getTeamWorkingPattern();

      return data;
    } catch (e) {
      notification.error({
        message: "error while creating new team",
      });
    }
  };

  const updateShiftName = async (formData: any) => {
    try {
      const response: AxiosResponse = (await axios.put(`${teamUrl}/${teamId}`, {
        shiftName_id: formData.shiftName_id,
        team_id: formData.team_id,
        teamName: formData.teamName
      })) as AxiosResponse;
      const data = response.data;

      if (data) {
        notification.success({
          message: "team updated successfully",
        });
        setRecentTeam(data);
        form.resetFields();
      }

      getTeamWorkingPattern();

      return data;
    } catch (e) {
      notification.error({
        message: "error while updating team",
      });
    }
  };

  const getInitialFormData = (data: any) => {
    const {
      consecutive_check_days,
      max_work_days_per_days,
      max_work_hours_per_days,
      min_rest_hours_per_days,
      min_rest_hours_between_shifts,
      max_work_hours_per_day,
      constraint_id,
      ...form_data
    } = data;

    form_data.restdays = +form_data.restdays;
    form_data.workdays = +form_data.workdays;

    const constraintsData: any = {
      consecutive_check_days: +consecutive_check_days,
      max_work_days_per_days: +max_work_days_per_days,
      max_work_hours_per_days: +max_work_hours_per_days,
      min_rest_hours_per_days: +min_rest_hours_per_days,
      min_rest_hours_between_shifts: +min_rest_hours_between_shifts,
      max_work_hours_per_day: +max_work_hours_per_day,
      shiftName_id: form_data.shiftName_id,
      team_id: form_data.team_id,
      organization_id: 1,
    };

    return {
      constraintsData,
      form_data,
      constraint_id,
    };
  };

  const submitCreateWorkingDays = async (
    data: any,
    team_id: number,
    shiftName_id: number
  ) => {
    const { constraintsData, form_data } = getInitialFormData(data);
    
    constraintsData.team_id = team_id;
    constraintsData.shiftId = shiftName_id;
    // constraintsData.team_id = recentTeam?.id;
    // constraintsData.shiftName_id = recentTeam?.shiftName.id;

    // form_data.shiftName_id = recentTeam?.shiftName.id;
    // form_data.team_id = recentTeam?.id;
    form_data.shiftName_id = shiftName_id;
    form_data.team_id = team_id;
    form_data.rotating_team = !!form_data.rotating_team;
    
    try {
      const response: AxiosResponse = (await axios.post(
        teamworkingpatternUrl,
        form_data
      )) as AxiosResponse;
      const data = response.data;

      const constraintsResponse: AxiosResponse = (await axios.post(
        `${teamConstraintsViewUrl}`,
        constraintsData
      )) as AxiosResponse;

      // console.log('constraintsData', constraintsData);

      if (data) {
        notification.success({
          message: "team working days added successfully",
        });
        workingDaysForm.resetFields();
        handleTeamModalOk();
      }

      if (!constraintsResponse.data) {
        notification.error({
          message: "Failed to update constraints",
        });
      }

      getTeamWorkingPattern();
      getTeamConstraints();
      return data;
    } catch (e) {
      notification.error({
        message: "error while adding team working days ",
      });
    }
  };

  const submitEditWorkingDays = async (data: any) => {
    // productName -> product_name
    // productId -> product_id
    // externalId -> external_id
    // teamId -> team_id
    // startdate -> start_date
    // enddate	-> end_date
    // shiftId -> shiftName_id
    // product -> product_id OR product_name
    // organizationName -> organization_name
    // organizationId -> organization_id
    // secondsToFinish -> seconds_to_finish
    // consecutiveCheckDays -> consecutive_check_days
    // maxWorkDaysPerDays -> max_work_days_per_days
    // maxWorkHoursPerDays -> max_work_hours_per_days
    // minRestHoursPerDays -> min_rest_hours_per_days
    // minRestHoursBetweenShifts -> min_rest_hours_between_shifts
    // maxWorkHoursPerDay -> max_work_hours_per_day
    // constraintId -> constraint_id

    const { constraintsData, form_data, constraint_id } =
      getInitialFormData(data);

    try {
      const response: AxiosResponse = (await axios.put(
        `${teamworkingpatternUrl}/${workingDaysId}`,
        form_data
      )) as AxiosResponse;
      const data = response.data;

      let constraintsResponse;

      if (constraint_id) {
        constraintsResponse = (await axios.put(
          `${teamConstraintsViewUrl}/${constraint_id}`,
          constraintsData
        )) as AxiosResponse;
      } else {
        constraintsResponse = (await axios.post(
          `${teamConstraintsViewUrl}`,
          constraintsData
        )) as AxiosResponse;
      }

      if (data) {
        notification.success({
          message: "team working days updated successfully",
        });
        workingDaysForm.resetFields();
        handleTeamModalOk();
      }
      if (!constraintsResponse.data) {
        notification.error({
          message: "Failed to update constraints",
        });
      }

      getTeamWorkingPattern();
      getTeamConstraints();
      return data;
    } catch (e) {
      notification.error({
        message: "error while updating team working days ",
      });
    }
  };

  //method for adding team working days
  const SubmitTeamWorkingDays = async (teamFormData: any) => {
    const data = { ...teamFormData };

    if (!updateWorkingDays) {
        const shiftData = await createShiftName(data)
        await submitCreateWorkingDays(data, shiftData.id, shiftData.shiftName.id)
    } else {
        const shiftData = await updateShiftName(data);
        await submitEditWorkingDays(data)
    }
  };

  const mergedData: any = [];

  teamsData.forEach((item: any) => {
    const existingItem = mergedData.find(
      (mergedItem: any) =>
        mergedItem?.team?.id === item?.team?.id &&
        mergedItem?.shiftName?.id == item?.shiftName?.id
    );

    if (existingItem) {
      if (
        existingItem.day !== item.day &&
        (existingItem.pattern != item.pattern ||
          existingItem.pattern == item.pattern)
      ) {
        if (!existingItem.days) {
          existingItem.days = [existingItem.day];
          existingItem.patterns = existingItem.pattern;
        }
        existingItem["days"] = item.day;
        existingItem["patterns"] = item.pattern;
      }
    } else {
      // If no matching item exists, add it to the mergedData array
      mergedData.push(item);
    }
  });

  const employee_counts = mergedData.map((a: any, b: any) => {
    return employees
      ?.map((e: any) => e)
      .find(
        (e: any) =>  e.team.id == a.team.id && e.team.shiftName.id == a.team.shiftName.id
      );
  });

  //method for deleting team
  const deleteTeam = async () => {
    try {
      const response = await axios.delete(`${teamworkingpatternUrl}/${teamId}`);
      if (response) {
        notification.success({
          message: "Team has been deleted",
        });
        getTeamWorkingPattern();
      }
    } catch (e) {
      notification.error({
        message: "Team can't be deleted",
      });
    }
  };
  //values for prefilling update working days Modal

  const updateTeamPattern = mergedData.filter(
    (data: any) => data?.id == workingDaysId
  );
  const updatedShiftId = updateTeamPattern[0]?.team.shiftName?.id;
  const updatedTeamNameId = updateTeamPattern[0]?.team?.id;
  const updatedTeamName = updateTeamPattern[0]?.team?.teamName;
  const updatedRotatingTeam = updateTeamPattern[0]?.rotating_team
  const updatedWorkingDays = updateTeamPattern[0]?.workdays;
  const updatedRestDays = updateTeamPattern[0]?.restdays;
  // const updatedPatternValue = updateTeamPattern[0]?.pattern;

  //values for prefilling Team Name Modal for update

  const getUpdatedWOrkingDaysValue = () => {
    const selectedConstraint: any = constraintsData.find(
      (item: any) =>
        item?.team.id === updatedTeamNameId && item?.team.shiftName.id
    );

    workingDaysForm.setFieldsValue({
      rotating_team: updatedRotatingTeam,
      shiftName_id: updatedShiftId,
      team_id: updatedTeamNameId,
      teamName: updatedTeamName,
      workdays: updatedWorkingDays,
      restdays: updatedRestDays,
      constraint_id: selectedConstraint?.id,
      consecutive_check_days:
        selectedConstraint?.consecutive_check_days || null,
      max_work_days_per_days:
        selectedConstraint?.max_work_days_per_days || null,
      max_work_hours_per_days:
        selectedConstraint?.max_work_hours_per_days || null,
      min_rest_hours_per_days:
        selectedConstraint?.min_rest_hours_per_days || null,
      min_rest_hours_between_shifts:
        selectedConstraint?.min_rest_hours_between_shifts || null,
      max_work_hours_per_day:
        selectedConstraint?.max_work_hours_per_day || null,
    });
  };

  const getTeamValues = () => {
    form.setFieldsValue({
      shiftName_id: updatedShiftId,
      teamName: updateTeamPattern[0]?.team?.teamName,
    });
  };

  React.useEffect(() => {
    
    getTeamWorkingPattern();
    getTeamConstraints();
    getTeamComposition();
  }, []);

  React.useEffect(() => {
    getUpdatedWOrkingDaysValue();

    getTeamValues();
  }, [workingDaysId, teamId]);

  return (
    <TeamsContext.Provider
      value={{
        mergedData,
        employee_counts,
        setUpdateWorkingDays,
        setEditWorkingDaysId,
        setTeamId,
        // teamUpdate,
        // handleOk,
        // SubmitEvents,
        form,
        deleteTeam,
        teamId,
        handleTeamModalOk,
        SubmitTeamWorkingDays,
        updateWorkingDays,
        recentTeam,
        workingDaysId,
        workingDaysForm,
        // setTeamUpdate,
        // isModalOpen,
        teamModalOpen,
        // setIsModalOpen,
        setTeamModalOpen,
        constraintsData,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};
