import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export interface LineChartForecast {
  Date: string;
  cleanedActuals?: number;
  forecast?: number;
  date: string;
  product: string;
}

const LineChartCombined = ({ data }: { data: LineChartForecast[] }) => {
  const renderTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const date = payload[0].payload.date;

      return (
        <div className="custom-tooltip">
          <p>
            Date: <strong>{date}</strong>
          </p>
          {payload.map((item: any) => {
            const { name, color, value } = item;

            return (
              <div key={item} className="item-row">
                <span className="title" style={{ color }}>
                  {name}:{" "}
                </span>
                <span className="value">{value}</span>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderCustomAxisTick = (tickProps: any) => {
    const { x, y, payload } = tickProps;

    const [month, day, year] = payload.value.split(",");

    const getText = (text: string, index: number) => (
      <text
        x={0}
        y={index * 14}
        dy={16}
        textAnchor="left"
        fontFamily="SF UI Text"
        fill="#878789"
        fontSize={10.6}
      >
        {text}
      </text>
    );

    return (
      <g transform={`translate(${x},${y})`}>
        {getText(day, 0)}
        {getText(month, 1)}
        {getText(year, 2)}
      </g>
    );
  };

  const interval = data.length < 30 ? 0 : Math.ceil(data.length / 10);

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={1000}
          // height={300}
          data={data}
        >
          <XAxis
            dataKey="date"
            tick={renderCustomAxisTick}
            interval={interval}
          />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={renderTooltip} />
          <Legend />
          <Line
            type="monotone"
            dataKey="forecast"
            stroke="#8884d8"
            name="Forecast"
          />
          <Line
            type="monotone"
            dataKey="cleanedActuals"
            stroke="#82ca9d"
            name="Cleaned Actuals"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartCombined;
