import { Button } from 'antd';
import Modal from 'antd/es/modal/Modal'
import React from 'react'

interface DeleteModalI {
    deleteOpen: any;
    deleteOk: any;
    deleteCancel: any;
    handleDelete: any;
    id: number;
    title: string;
}
const DeleteModal: React.FC<DeleteModalI> = ({ deleteOpen, title, deleteOk, deleteCancel, handleDelete, id }) => {
    return (
        <Modal title={title}
            mask={true}
            className='deleteModal'
            open={deleteOpen}
            onOk={deleteOk}
            onCancel={deleteCancel}

        >
            <div className='delete_confirms'>
                <Button className='yes' onClick={() => {
                    handleDelete(id);
                    deleteOk();
                }}>Yes</Button>
                <Button className='no' onClick={deleteCancel}>No</Button>
            </div>
        </Modal>
    )
}

export default DeleteModal