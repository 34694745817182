import LeaveTableView from "../components/AddLeaves"
import {  Row, Col, Menu } from "antd";
import { SettingOutlined, BellOutlined } from "@ant-design/icons";
import { LeaveTrackerProvider } from '../Context/LeaveTracker/LeaveTrackerContext'

import DropdownMenu from "../components/DropdownMenu";

const menu = (
    <Menu>
        <Menu.Item key="1">  <SettingOutlined
            style={{ marginRight: "20px", fontSize: "18px" }}
        />Settings
        </Menu.Item>
        <Menu.Item key="2">
            <BellOutlined style={{ marginRight: "20px", fontSize: "18px" }} />Notification</Menu.Item>
    </Menu>
);
 
function LeavePlans() {
    return (
        <LeaveTrackerProvider>
            <div>
                <div style={{
                    border: 'none',
                    marginBottom: "42px",
                    width: "100%",
                    backgroundColor: "white",
                    padding: "14px"
                }}>
                    <Row>
                        <Col span={20}>
                            <h1>Leave Tracker</h1>
                        </Col>

                        <Col
                            span={4}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <DropdownMenu />

                        </Col>
                    </Row>
                </div>
                <LeaveTableView />
            </div >
        </LeaveTrackerProvider> 
    )
}
export default LeavePlans