import { Navigate, Outlet, useLocation } from "react-router-dom";
import { FC, useEffect } from "react";
import {  getPageProvider } from "../Context/ApplicationContext";
import { ACCESS_TOKEN } from "../config";
import useQuery from "../hooks/useQuery";

import TopBarNavigation from '../components/TopBarNavigation';

const Layout = () => {
  const location = useLocation();
  let query = useQuery();

  const existingToken = localStorage.getItem(ACCESS_TOKEN);
  const token = query.get("token");

  if (token || existingToken) {
    if (!existingToken) {
      localStorage.setItem(ACCESS_TOKEN, JSON.stringify(token));
    }

    const ContextProvider = getPageProvider(location.pathname)
    return (
      <div className="layout">
        <div className="bodyHolder">
          <ContextProvider>
            <div className="mainContent">
              <TopBarNavigation/>
              <Outlet />
            </div>
          </ContextProvider>
        </div>
      </div>
    );
  }

  if (!token) {
    return (
      <Navigate
        to="/blank"
        state={{
          message: "You must log in first.",
          from: location.pathname,
        }}
        replace
      />
    );
  }
};

export default Layout as FC;
