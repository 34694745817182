import EmployeeShifts from "../components/Dashboard/customschedule";
import { Fragment, useEffect, useRef, memo, useState, useMemo } from "react";
import {
  scheduled,
  schedulerunUrl,
  scheduleWorkersRequest,
  plannedleavesURL,
  ShiftdetailsUrl,
} from "../utils/network";
import { notification, Button, Select, Form , Space} from "antd";
import axios, { AxiosResponse } from "axios";
import debounce from "lodash/debounce";

import { useGetShift } from "../utils/hooks";
import { Row, Col, Input, SelectProps } from "antd";
import moment from "moment";
import dayjs from "dayjs";

import { SearchOutlined, CloudOutlined } from "@ant-design/icons";
import Loader from "../components/Loader";

import { useForm } from "antd/es/form/Form";
import { ILeave } from "../components/LeaveTracker/types";
import {
  EmployeeShift,
  ISchedule,
  IScheduleWorkerRequest,
  Employee
} from "../components/schedule/types";
import ScheduleGenerationModal, { ScheduleData } from '../components/schedule/ScheduleGenerationModal'
import { shiftsToCSV } from "../utils/csvImporter";

const BREAK_DURATION = 60

// import { leaveData } from '../mock_data/leave';
// import { leaveDataSeptember } from '../mock_data/leave2'
// import { getSchaduleDate as getSchaduleDateOctober, getSchaduleDateSmall} from '../mock_data/schedule';
// import { getSchaduleDate } from '../mock_data/schedule2';
// import { scheduleWorkersDataMock } from '../mock_data/scheduleWorkersRequest'
// import { ShiftdetailsViewSetMock } from '../mock_data/shiftdetailsViewSet '
// import { fetchData } from '../utils/mock'

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MyScheduler: React.FC = () => {
  const inputRef = useRef(null);
  const debouncedFilterRef = useRef(null);

  const [fetching, setFetching] = useState(true);
  const [initialSchedule, setInitialSchedule] = useState<ISchedule[]>([]);
  const [schedule, setSchedule] = useState<ISchedule[]>([]);
  const [leaves, setLeaves] = useState<ILeave[]>([]);
  const [tempdata, settempdata] = useState<EmployeeShift[]>([]);
  const [scheduleWorkers, setScheduleWorkers] = useState<{
    [key in string]: number;
  }>({});
  const [shiftWorkingDays, setShiftWorkingDays] = useState([]);
  const [tempworkers, settempworkers] = useState<Employee>({});

  const [week, setWeek] = useState(1);
  const [teams, setTeams] = useState([]);
  const [shift, setshift]: any = useState([]);
  const [shiftdropdown, setshiftdropdown] = useState<any[]>([]);
  const [view, setView] = useState<"month" | "week" | "day">("month");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [employeeTeam, setEmployeeTeam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState([]);
  const [shiftLabel, setShiftLable] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  const [isLeaveView, toggleLeaveView] = useState(false);

  // Initial render 3times

  const shiftOptions = useMemo(() => {
    return shiftdropdown?.map((item: any) => ({
      value: item?.id,
      label: item?.shiftName,
      color: item.color
    }));
  }, [shiftdropdown]);

  const [form] = useForm();

  useGetShift(setshiftdropdown, setFetching);

  const getShiftWorkingDays = async () => {
    try {
      const response = await axios.get(ShiftdetailsUrl);
      // const response = {
      //     data: ShiftdetailsViewSetMock
      // };
      const shiftsData = response.data;
      setShiftWorkingDays(shiftsData);
    } catch (e) {
      notification.error({
        message: "error while fetching shifts details",
      });
    }
  };

  const getSchedule = async (disableLoading?: boolean) => {
    if (!disableLoading) {
      setLoading(true);
    }

    try {
      const scheduleResponse: AxiosResponse = await axios.get(`${scheduled}`, {
        params: {
          months: `${monthNames[month]} ${year}`,
          shiftName_id: `${shiftLabel.join(",")}`,
          status: "0",
          limit: 1000,
        },
      });

      // const mockDataSchedule = {
      //   8: [],
      //   9: getSchaduleDateSmall,
      //   10: getSchaduleDate
      // }

      // @ts-ignore
      // const response = await fetchData( mockDataSchedule[month] || [])

      const startDate = moment(
        `${year}-${monthNames[month]}-01`,
        "YYYY-MMMM-DD"
      );
      const endDate = startDate.clone().endOf("month");

      const leavesResponse = await axios.get(plannedleavesURL, {
        params: {
          leave_start_date: startDate.format("YYYY-MM-DD"),
          leave_end_date: endDate.format("YYYY-MM-DD")
        },
      });

      // const mockDataLeave = {
      //   8: leaveDataSeptember,
      //   9: leaveData,
      // }
      // @ts-ignore
      // const leavesResponse: any = await fetchData( mockDataLeave[month] || {results: []} )
      const leavesData = leavesResponse.data?.results as ILeave[];

      const scheduleWorkersResponse = await axios.get(scheduleWorkersRequest, {
        params: {
          months: `${monthNames[month]} ${year}`,
          shiftName_id: `${shiftLabel.join(",")}`,
        }
      });
      // const scheduleWorkersResponse: any = await fetchData(scheduleWorkersDataMock)

      const scheduleWorkersData =
        scheduleWorkersResponse.data as IScheduleWorkerRequest[];
      const scheduleWorkersByDate: { [key in string]: number } = {};

      // Loop through the data and group by date
      scheduleWorkersData.forEach(item => {
        const date = item.date.split("T")[0]; // Extract the date part
        if (scheduleWorkersByDate[date]) {
          scheduleWorkersByDate[date] += item.workers_request; // Add workers_request to the existing sum
        } else {
          scheduleWorkersByDate[date] = item.workers_request; // Initialize the sum for the date
        }
      });


      setSchedule(scheduleResponse.data);
      setInitialSchedule(scheduleResponse.data);
      setScheduleWorkers(scheduleWorkersByDate);

      setLeaves(leavesData);

      
    } catch (e) {
      notification.error({
        message: "Schedule generation failed",
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  function filterData(value: string) {
    // Perform the filtering logic here
    const filteredData = initialSchedule.filter(
      item => item.worker.worker.includes(value) || `${item.id}`.includes(value)
    );

    setSchedule(filteredData);
  }
  const handleNameFilterChange = (event: any) => {
    const value = event.target.value;
    // @ts-ignore
    inputRef.current.value = value;

    if (debouncedFilterRef.current) {
      // @ts-ignore
      debouncedFilterRef.current.cancel();
    }
    // @ts-ignore
    debouncedFilterRef.current = debounce(filterData, 500); // 500ms debounce
    // @ts-ignore
    debouncedFilterRef.current(value);
  };

  useEffect(() => {
    getShiftWorkingDays();
    // Clean up the debounced function when the component unmounts
    return () => {
      if (debouncedFilterRef.current) {
        // @ts-ignore
        debouncedFilterRef.current.cancel();
      }
    };
  }, []);

  const deleteSchedule = async () => {
    try {
      const response = await axios.delete(scheduled, {
        data: {
          months: `${monthNames[month]} ${year}`,
          shiftName: `${shiftLabel.join(",")}`,
        },
      });

      if (response) {
        notification.success({
          message: "Old records has been deleted..",
        });
      }
      return response.data;
    } catch (err) {
      notification.error({
        message: "Error while deleting old records",
        description: "",
      });
    }
  };

  //delete shift Instance
  const deleteShift = async (shiftId: number) => {
    try {
      const response = await axios.delete(`${scheduled}/${shiftId}`);
      if (response) {
        notification.success({
          message: "Shift Instance has been deleted",
        });
        console.log(schedule);
        setSchedule(schedule.filter((item: any) => item?.id !== shiftId));
        setInitialSchedule(
          initialSchedule.filter((item: any) => item?.id !== shiftId)
        );
      }
    } catch (e) {
      notification.error({
        message: "Shift Instance can't be deleted",
      });
    }
  };
  //edit SHift instance
  const submitEditData = async ({ 
    start_date, 
    end_date,
    id,
    shiftName_id,
    team_id
  }: any, onReady: () => void) => {
    try {
      const startDate = start_date.toISOString();
      const endDate = end_date.toISOString();
      const breakDurationInHours = BREAK_DURATION / 60; // Convert minutes to hours
      
      const workingHours = moment.duration(
        moment(endDate).diff(moment(startDate))
      ).asHours();
      
      const totalWorkingHours = workingHours - breakDurationInHours;

      const response = await axios.put(`${scheduled}/${id}`, {
        shiftName_id,
        team_id,
        start_date: startDate,
        end_date: endDate,
        working_hours: totalWorkingHours
      });

      if (response) {
        notification.success({
          message: "shift instance has been updated",
        });
        form.resetFields();
        setSchedule(schedule.map(item => {
          if(item.id === id) {
            return response.data
          }
          return item;
        }))
        onReady()
      }
     
    } catch (e) {
      notification.error({
        message: "can't update the shift instance",
      });
    }
  };

  useEffect(() => {
    let s1: any = [];

    const workers:any = {}
    if (schedule.length > 0) {

      schedule.forEach((item: any) => {
        const workerLeave = leaves.find(
          liveItem => liveItem.worker.worker === item.worker.worker
        );
          
        const shiftItem: EmployeeShift = {
          employee: item?.worker.worker,
          start: new Date(item?.start_date).toISOString(),
          end: new Date(item?.end_date).toISOString(),
          id: item?.id,
          external_id: item.worker.external_id,
          working_hours: item.working_hours,
          status: item.status,
          leave_start_date: workerLeave?.leave_start_date || '',
          leave_end_date: workerLeave?.leave_end_date || '',
          break_duration_min: item?.shiftworkingdays?.break_duration_min,
          shiftName_id: item?.worker.team.shiftName.id,
          shiftworkingdays_id: item.shiftworkingdays.id,
          team_id: item.worker.team.id,
          color: item.shiftworkingdays?.shiftName.color
        };

        s1.push(shiftItem);

        if(!workers[item?.worker?.worker]) {
          workers[item?.worker?.worker] = {
            employee: item?.worker,
            type: "Full Time",
          }
        }

      });
    }

    settempdata(s1);
    settempworkers(workers);
    setTime(
      s1?.map((s: any) => {
        const starttime: any = new Date(s?.start);
        const endtime: any = new Date(s?.end);
        return (endtime - starttime) / (1000 * 60 * 60);
      })
    );
  }, [schedule, leaves]);

  const shiftsByEmployee = useMemo(() => {
    const shiftsByEmployee: any = [];

    for (const shift of tempdata) {

      const employeeField = `${shift.employee},${shift.external_id || ""}`;
      if (!shiftsByEmployee[employeeField]) {
        shiftsByEmployee[employeeField] = [];
      }
      shiftsByEmployee[employeeField].push(shift);
    }

    let keys = Object.keys(shiftsByEmployee);

    keys.sort((a: any, b: any) => {
      // Extract numeric values from keys using regular expressions

      const match1 = a.match(/\d+$/);
      const match2 = b.match(/\d+$/);
      let aNumber = parseInt(match1 ? match1[0] : 0) || 0;
      let bNumber = parseInt(match2 ? match2[0] : 0) || 0;

      // Compare alphabetically if numeric values are the same
      if (aNumber === bNumber) {
        return a.localeCompare(b);
      }

      // Compare numerically
      return aNumber - bNumber;
    });

    let sortedArray = keys.reduce((acc, key) => {
      // @ts-ignore
      acc[key] = shiftsByEmployee[key];
      return acc;
    }, {});

    return sortedArray;
  }, [tempdata]);

  useEffect(() => {
    getSchedule();
  }, [monthNames[currentDate.getMonth()], shiftLabel]);

  const handleGenerate = async (data: ScheduleData) => {
    const dateFormat = "YYYY-MM-DD"

    if (!data.shiftName_id) {
      notification.error({
        message: "Validation Failed",
        description: "Shift selection is required for schedule generation",
      });
      return;
    }
    setLoading(true);

    if (shift.length !== 0) {
      await deleteSchedule();
    }


    const datatosend = {
      shiftName_id: data.shiftName_id,
      status: "1",
      start_date: data.start_date.format(dateFormat),
      end_date: data.end_date.format(dateFormat),
    };

    const response: AxiosResponse = (await axios
      .post(schedulerunUrl, datatosend)
      .catch(e => {
        notification.error({
          message: e.error || "Operation Failed",
          description: "Optimizing Schedule",
        });
      })) as AxiosResponse;

    if (response) {
      notification.success({
        message: "Operation Success",
        description: "Optimizing Schedule",
      });
      await getSchedule(false);
    }

    setLoading(false);
  };

  const handleCreateShift = async (data: any) => {
    try {
      const response: AxiosResponse = await axios.post(scheduled, data);

      if (response) {
        notification.success({
          message: "Create Success",
          description: "Shift created",
        });
        
        setSchedule([...schedule,response.data])

      }
    } catch (err) {
      notification.error({
        message: "Create Failed",
        description: "Shift not created",
      });
    }
  };

  const filteredEmployees = employeeTeam
    .filter(
      (item: any) => selectedTeam === "" || item.team.teamName === selectedTeam
    )
    .map((item: any) => item.worker);

  //handle shift Change
  const handleShiftChange = (option: any) => {
    setshift(option);
    // setShiftLable(value.map((shift: any) => shift?.label))
  };

  const handleShiftLabelsChange = () => {
    setShiftLable(shift.map((shift: any) => shift));
  };

  // const percentageBarWidth = (calculateHours / totalHoursAvailable) * 100

  const handleDownloadCSV = () => {
    console.log(shiftsByEmployee, "shiftsByEmployee csv");
    shiftsToCSV(shiftsByEmployee);
  };


  const renderFilters = () => {
    return (
      <div className="filters_container">
        <div className="schedule_selects">
          <SearchOutlined
            style={{
              position: "absolute",
              top: "14px",
              zIndex: "1",
              marginLeft: "12px",
              fontSize: "17px",
            }}
          />
          <Input
            className="custom-input"
            ref={inputRef}
            onChange={handleNameFilterChange}
            placeholder="Search by name or id"
          />
        </div>
        <Select
          onChange={handleShiftChange}
          onBlur={handleShiftLabelsChange}
          className="schedule_selects"
          mode="multiple"
          allowClear
          style={{ width: 200 }}
          placeholder="Select Shifts"
        >
        {shiftOptions.map((option) => {
          return (
            <Select.Option key={option.value} value={option.value}>
              <div className="custom-select-option">
                <span 
                  className="color_shift_circle" 
                  style={{ backgroundColor: option.color }}
                />
               <span>{option.label}</span>
              </div>
            </Select.Option>
          )
        })}
       </Select>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className="custom-tabs-container tabs_container">
        <div className="custom-tab-items">
          <div
            onClick={() => setView("month")}
            className={`custom-tab-item ${view === "month" ? "active" : ""}`}
          >
            Month
          </div>
          <div
            onClick={() => setView("week")}
            className={`custom-tab-item ${view === "week" ? "active" : ""}`}
          >
            Week
          </div>
          <div
            className={`custom-tab-item ${view === "day" ? "active" : ""}`}
            onClick={() => setView("day")}
          >
            Day
          </div>
        </div>
      </div>
    );
  };

  const renderSecondRow = () => {
    return (
      <Row className="schedule_row" align="middle">
        <Col span={14}>{renderFilters()}</Col>
        <Col span={4} offset={6}>
          {renderTabs()}
        </Col>
      </Row>
    );
  };

  const newScheduleDefaultValue = useMemo(() => {
    
    const startDateoftheMonth = new Date(year, month, 1);
    const lastDateoftheMonth = new Date(year, month + 1, 0);

    return {
      shiftName_id: shift[0],
      start_date: dayjs(startDateoftheMonth),
      end_date: dayjs(lastDateoftheMonth),
    }
  },[shift, year, month])

  const renderActionButtons = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{
            fontWeight: "bold",
            marginRight: "15px",
            borderRadius: 0,
            color: "#20304F",
          }}
          type="default"
          icon={<CloudOutlined />}
          size="large"
          onClick={handleDownloadCSV}
        >
          Download CSV
        </Button>
        <ScheduleGenerationModal 
         shiftdropdown={shiftdropdown}
          generateSchedule={handleGenerate}
          defaultValue={newScheduleDefaultValue}
        />
      </div>
    );
  };

  return (
    <Fragment>
      <div className="schedule_container">
        <div
          style={{
            border: "none",
            marginBottom: "20px",
            // width: "100%",
          }}
        >
          {renderSecondRow()}
        </div>
        {loading ? (
          <Loader />
        ) : (
          <EmployeeShifts
            shiftdropdown={shiftdropdown}
            defaultScheduleValue={newScheduleDefaultValue}
            handleGenerate={handleGenerate}
            handleCreateShift={handleCreateShift}
            isLeaveView={isLeaveView}
            toggleLeaveView={toggleLeaveView}
            shiftsByEmployee={shiftsByEmployee}
            shifts={tempdata}
            employees={tempworkers}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            loading={loading}
            filteredEmployee={filteredEmployees}
            schedule={schedule}
            setWeek={setWeek}
            deleteShift={deleteShift}
            week={week}
            year={year}
            month={month}
            editShiftinstance={submitEditData}
            renderActionButtons={renderActionButtons}
            time={time}
            scheduleWorkers={scheduleWorkers}
            shiftWorkingDays={shiftWorkingDays}
            view={view}
          />
        )}
      </div>
    </Fragment>
  );
};

export default memo(MyScheduler);
