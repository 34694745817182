import { memo, useState, useMemo, FC, useCallback, Fragment } from "react";
import moment from "moment";
import { Row, Col, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import clsx from 'clsx';

import ShiftBox from "../schedule/shiftBox";
import { DateTime } from "luxon";
import {
  getWorkingDaysInMonth,
  getWorkingDaysInWeek,
  isWorkingDay,
  calculateTotalDurationInRange,
} from "../../utils/dates";
import { useGetShift, useGetTeam } from "../../utils/hooks";

import RechartBar, { legendOptions } from "../rechart/RechartBar";
import BarLegend from "../rechart/BarLegend";
import LeaveTable from "../LeaveTracker/LeaveTable";
import LeaveModals from "../LeaveTracker/LeaveModals";
import LeaveActionButtons from "../LeaveTracker/LeaveActionButtons";
import { LeaveTrackerProvider } from "../../Context/LeaveTracker/LeaveTrackerContext";
import RadioGroup, { SCHEDULE_VIEW, LEAVE_VIEW } from '../../components/RadioGroup'
import { EmployeeShift, Employee, ShiftsByEmployee } from "../schedule/types";
import TeamMemberColumn from "../schedule/TeamMemberColumn";
import { numberToHoursMinutes } from '../../utils/dates'
import { ScheduleData } from '../schedule/ScheduleGenerationModal'

const monthDays = [
  31, // January
  28, // February
  31, // March
  30, // April
  31, // May
  30, // June
  31, // July
  31, // August
  30, // September
  31, // October
  30, // November
  31, // December
];
type ViewType = "month" | "week" | "day";
type BarDataType = {
  [key in ViewType]: string[];
};
interface EmployeeShiftsProps {
  isLeaveView: boolean;

  shiftsByEmployee: ShiftsByEmployee;
  shifts: EmployeeShift[];
  employees: Employee;
  scheduleWorkers: { [key in string]: number };
  year: number;
  month: number;
  view: ViewType;
  setWeek: any;
  week: number;
  filteredEmployee: any;
  loading: boolean;
  schedule: any;
  deleteShift: any;
  editShiftinstance: any;
  currentDate: any;
  setCurrentDate: any;
  time: any;
  renderActionButtons: () => React.ReactNode;
  // shiftArr: any;
  toggleLeaveView: (data: boolean) => void;

  handleCreateShift: any;
  handleGenerate: (data: ScheduleData) => void;
  shiftWorkingDays: any;
  defaultScheduleValue: ScheduleData;
  shiftdropdown: number[]
}

const WORK_DAY_HOURS = 8;


const EmployeeShifts: FC<EmployeeShiftsProps> = memo(props => {
  const {
    scheduleWorkers,
    isLeaveView,
    shifts,
    employees,
    shiftsByEmployee,
    year,
    month,
    view,
    setWeek,
    loading,
    filteredEmployee,
    schedule,
    week,
    deleteShift,
    editShiftinstance,
    currentDate,
    setCurrentDate,
    time,
    renderActionButtons,
    toggleLeaveView,
    handleCreateShift,
    shiftWorkingDays,
    handleGenerate,
    defaultScheduleValue,
    shiftdropdown
  } = props;

  const [weekOffset, setWeekOffset] = useState(0);

  const [dayOffset, setDayOffset] = useState(0);
  const [showChart, setShowChart] = useState(true);

  const [shiftNames, setShiftNames] = useState<any[]>([]);
  const [teamNames, setTeamNames] = useState<any[]>([]);

  useGetShift(setShiftNames, () => {});
  useGetTeam(setTeamNames, () => {});

  // Initial render 4times

  const workingDaysInCurrentRange = useMemo(() => {
    if (view === "month") {
      return getWorkingDaysInMonth(currentDate);
    }

    if (view === "week") {
      return getWorkingDaysInWeek(currentDate);
    }

    return isWorkingDay(currentDate) ? 1 : 0; // For day view
  }, [currentDate, view]);

  const activeShifts = shifts.filter(item => item.status === "1");
  const calculateHours: any = useMemo(() => {
    return calculateTotalDurationInRange(
      activeShifts,
      view,
      month,
      year,
      weekOffset + 1,
      dayOffset + 1
    );
  }, [activeShifts, view, weekOffset, month, year, dayOffset]);

  const shiftData: any = [];
  const dayShiftStart: any = [];
  const dayShiftEnd: any = [];

  activeShifts.forEach((item: any) => {
    const startDate = DateTime.fromISO(item.start, { zone: "utc" });
    const endDate = DateTime.fromISO(item?.end, { zone: "utc" });
    // Add only current date
    shiftData.push(startDate.toFormat("yyyy-MM-dd"));

    const startDateMoment = moment(item.start);
    const isCurrentDateShift =
      startDateMoment.date() === dayOffset + 1 &&
      startDateMoment.year() === year &&
      startDateMoment.month() === month;

    if (isCurrentDateShift) {
      dayShiftStart.push(startDate.toFormat("HH"));
      dayShiftEnd.push(endDate.toFormat("HH"));
    }
  });

  const itemCounts = useMemo(() => {
    const data: any = {};
    for (const item of shiftData) {
      if (data[item]) {
        data[item]++;
      } else {
        data[item] = 1;
      }
    }
    return data;
  }, [shiftData]);

  function getDayName(dayIndex: any) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[dayIndex];
  }

  let numColumns = monthDays[month];

  const startOfDay: any = new Date();
  startOfDay.setHours(0, 0, 0, 0);
  const endOfDay: any = new Date();
  endOfDay.setHours(23, 59, 59, 999);

  const daysColumns = Math.round((endOfDay - startOfDay) / (60 * 60 * 1000));

  const monthlyBar = useMemo(() => {
    const daysInMonth: number = monthDays[month];

    return Array.from({ length: daysInMonth }, (_, i) => {
      const date = new Date(year, month, i + 1);
      const dayIndex = date.getDay();
      const dayName = getDayName(dayIndex);
      let value: any = "";

      const dateString = moment(date).format("YYYY-MM-DD");

      const workersLimit = scheduleWorkers[dateString] || "";

      for (let element in itemCounts) {
        if (dateString == element) {
          value = itemCounts[element];
        }
      }

      return `${date.getDate()} ${dayName.slice(
        0,
        3
      )} ${value} ${workersLimit}`;
    });
  }, [year, month, scheduleWorkers, itemCounts]);

  let dayBars: string[] = [];

  const getWeeklyBar = useCallback(
    (daysInMonth: number, year: number, month: number, itemCounts: any) => {
      return Array.from({ length: daysInMonth }, (_, i) => {
        const date = new Date(year, month, i + 1);
        const dayIndex = date.getDay();
        const dayName = getDayName(dayIndex);
        let value: any = "";

        const dateString = moment(date).format("YYYY-MM-DD");

        const workersLimit = scheduleWorkers[dateString] || "";
        for (let element in itemCounts) {
          if (dateString == element) {
            value = itemCounts[element];
          }
        }

        return `${date.getDate()} ${dayName.slice(
          0,
          3
        )} ${value} ${workersLimit}`;
      });
    },
    []
  );

  const weeklyBar = useMemo(() => {
    if (view !== "week") return [];

    const daysInMonth: number = monthDays[month];
    const currentMonthData = getWeeklyBar(daysInMonth, year, month, itemCounts);
    return currentMonthData;
  }, [view, month, year, itemCounts, getWeeklyBar]);

  const prevMonthWeeksCount = useMemo(() => {
    if (view !== "week") return 0;

    const prevMonth = month - 1;
    const firstDay = new Date(year, prevMonth, 1);
    const lastDay = new Date(year, prevMonth + 1, 0);

    const weeks = [];

    let currentWeek = [];
    let currentDate = firstDay;

    while (currentDate <= lastDay) {
      currentWeek.push(new Date(currentDate));
      if (currentDate.getDay() === 0) {
        // Sunday
        weeks.push(currentWeek);
        currentWeek = [];
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // If there are remaining days, add them to a final week
    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }

    return weeks.length;
  }, [view, month, year]);

  if (view === "day") {
    const hoursRange: { [key in number]: number } = {};
    for (let hour = 0; hour <= 24; hour++) {
      hoursRange[hour] = 0;
    }

    for (let hour = 0; hour <= 24; hour++) {
      const formattedHour = moment({ hour }).format("HH");

      const startIndex = dayShiftStart.indexOf(formattedHour);

      if (startIndex !== -1) {
        const endTime = +dayShiftEnd[startIndex];
        for (let startHour = hour; startHour <= endTime; startHour++) {
          hoursRange[startHour] = hoursRange[startHour] + 1;
        }
      }
    }

    const date = new Date(year, month, dayOffset + 1);
    const dateString = moment(date).format("YYYY-MM-DD");
    const workersLimit = scheduleWorkers[dateString] || "";

    for (let key in hoursRange) {
      const formattedkey = moment({ hour: +key }).format("HH:mm");
      const x = formattedkey + "  " + hoursRange[key];
      // dayBars.push(x);
      // dayBars.push(`${formattedkey}  ${hoursRange[key]} ${workersLimit}`);
      const y = `${formattedkey}  ${hoursRange[key]} ${workersLimit}`;

      dayBars.push(y);
      // return `${date.getDate()} ${dayName.slice(0, 3)} ${(value)} ${workersLimit}`;
    }
  }

  const options = [];
  for (let i = 0; i < 12; i++) {
    options.push(i);
  }

  const nextMonth = () => {
    setCurrentDate((prevDate: any) => {
      const nextDate = new Date(prevDate);
      nextDate.setMonth(nextDate.getMonth() + 1);
      return nextDate;
    });
  };

  const prevMonth = () => {
    setCurrentDate((prevDate: any) => {
      const prevDateCopy = new Date(prevDate);
      prevDateCopy.setMonth(prevDateCopy.getMonth() - 1);
      return prevDateCopy;
    });
  };

  const displayedValues = useMemo(() => {
    const weeks = [];
    let currentWeek = [];

    for (const day of weeklyBar) {
      currentWeek.push(day);

      // If Sunday is reached, start a new week
      if (day.indexOf("Sun") !== -1) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    }

    // If there are remaining days, add them to a final week
    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }

    return weeks;
  }, [weeklyBar, weekOffset]);

  const format = (date: any) => {
    return date.toLocaleDateString("en-US", { month: "long", year: "numeric" });
  };

  let totalEmployees = Object.keys(shiftsByEmployee).length;
  totalEmployees = totalEmployees === 0 ? 40 : totalEmployees;

  const renderTableHeader = () => {
    return (
      <div className="teams-header">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            // style={{ marginBottom: "22px" }}
            id="IconChangeColor"
            height="20"
            width="20"
          >
            {" "}
            <g>
              <path
                fill="none"
                d="M0 0h24v24H0z"
                id="mainIconPathAttribute"
              ></path>
              <path
                d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h14V5H5zm2 6h10v2H7v-2z"
                id="mainIconPathAttribute"
              ></path>{" "}
            </g>{" "}
          </svg>
        </div>
        <div
        // style={{ marginRight: "80px", marginBottom: "22px" }}
        >
          <p>Employees</p>
        </div>
      </div>
    );
  };

  const renderMonthNavigation = () => {
    if (view !== "month") return null;

    return (
      <div className="month-navigation">
        <button className="prev" onClick={prevMonth}>
          <LeftOutlined />
        </button>

        <div>
          <p className="month">{format(currentDate)}</p>
        </div>

        <button className="next" onClick={nextMonth}>
          <RightOutlined />
        </button>
      </div>
    );
  };

  const handleDayChange = (moveNext: boolean) => {
    if (moveNext) {
      setDayOffset(dayOffset + 1);
    } else {
      setDayOffset(dayOffset - 1);
    }
  };

  const handleWeekChange = (moveNext: boolean) => {
    if (moveNext) {
      const hasNextWeek = displayedValues[weekOffset + 1];

      if (hasNextWeek) {
        setWeekOffset(weekOffset + 1);
      } else {
        nextMonth();
        setWeekOffset(0);
      }
    } else {
      const hasPrevWeek = displayedValues[weekOffset - 1];
      if (hasPrevWeek) {
        setWeekOffset(weekOffset - 1);
      } else {
        setWeekOffset(prevMonthWeeksCount - 1);
        prevMonth();
      }
    }
  };

  const renderWeekDayNavigation = () => {
    if (view === "week" || view === "day") {
      return (
        <div style={{ marginLeft: 0 }} className="day_week_navigation">
          <button
            className="prev_week"
            onClick={() =>
              view === "week" ? handleWeekChange(false) : handleDayChange(false)
            }
          >
            <LeftOutlined />
          </button>
          {view === "week" ? (
            <div className="day_navigation">
              <span className="month">{format(currentDate)}</span>
              {/* <span className="year">{year}</span> */}
              <span
                style={{ fontSize: "20px", color: "red", marginRight: "14px" }}
              >
                {" "}
                Week {weekOffset + 1}
              </span>
            </div>
          ) : (
            <div className="day_navigation">
              <span className="month">{format(currentDate)}</span>
              {/* <span className="year" style={{ marginRight: "5px" }}>{year}</span> */}
              <span
                style={{
                  fontSize: "20px",
                  color: "red",
                  marginRight: "14px",
                  marginLeft: 4,
                }}
              >
                Day {dayOffset + 1}
              </span>
            </div>
          )}
          <button
            onClick={() =>
              view === "week" ? handleWeekChange(true) : handleDayChange(true)
            }
            className="next_week"
          >
            <RightOutlined />
          </button>
        </div>
      );
    }
  };

  const cellCounts = {
    month: monthlyBar.length,
    week: displayedValues[weekOffset]?.length || 0,
    day: dayBars.length,
  };

  const renderToggler = () => {
    const handleClick = (value: string) => {
      toggleLeaveView(value !== SCHEDULE_VIEW)
    }

    return (
      <div className="toggler_container">
        <RadioGroup 
          defaultValue={isLeaveView ? LEAVE_VIEW : SCHEDULE_VIEW}
          onChange={handleClick}
        />
      </div>
    )
  }

  const renderPercentageCalculator = () => {
    const totalMonthHoursAvailable =
      totalEmployees * WORK_DAY_HOURS * workingDaysInCurrentRange;

    // const totalHoursAvailable = totalEmployees * 126;
    const totalHoursAvailable = totalMonthHoursAvailable;

    const percentageBarWidth =
      totalHoursAvailable && (calculateHours / totalHoursAvailable) * 100;

    const calculateHoursArray = `${numberToHoursMinutes(calculateHours)}`.split(':');
    const [hours, minutes] = calculateHoursArray;
    const calculateHoursLabel = `${hours}h ${minutes ? `${minutes}m` : ''}`;

    const totalHoursArray = `${numberToHoursMinutes(totalHoursAvailable)}`.split(':');
    const [hours_total, minutes_total] = totalHoursArray;
    const totalHoursLabel = `${hours_total}h ${minutes_total ? `${minutes_total}m` : ''}`;

    return (
      <div className="percentage_calculator">
        <div className="percentage">
          <div id="percentage_calculated">
            {Math.round(percentageBarWidth)}%
          </div>
          <div id="hours_utlized">
            <p>Hours utlization</p>
          </div>
        </div>
        <div className="hours_bar">
          <p
            className="calcualted_hours_bar"
            style={{
              width: `${percentageBarWidth < 0 ? 0 : percentageBarWidth}%`,
              backgroundColor: "#1c366e",
              height: "3px",
              padding: "3px",
              borderRadius: "12px",
              marginTop: "-4px",
            }}
          ></p>
        </div>
        <div className="hours_desc">
          <div id="hours_planned">
            <span style={{ color: "#7d8598", fontWeight: "600" }}>
              {calculateHoursLabel}
            </span>
            <span style={{ color: "#bdc1c3", marginLeft: "5px" }}>planned</span>
          </div>
          <div id="hours_available">
            <span style={{ color: "#f8e7e0", fontWeight: "bold" }}>
              {totalHoursLabel}{" "}
            </span>
            <span style={{ color: "#bdc1c3" }}>available</span>
          </div>
        </div>
      </div>
    );
  };

  // @ts-ignore
  const barCellColSpan = cellCounts[view];

  const barData: BarDataType = {
    month: monthlyBar,
    week: displayedValues[weekOffset] || [],
    day: dayBars,
  };

  const weekSize = displayedValues[weekOffset]?.length || 0;
  const filteredEmployees = useMemo(
    () => Object.entries(shiftsByEmployee),
    [shiftsByEmployee]
  );
  

  const weekOffsetForShiftCount =
    displayedValues.length > 1
      ? displayedValues.slice(0, weekOffset).flat().length
      : displayedValues.length;

  const renderScheduleTable = () => {
    // ALL used props in this function

    // filteredEmployees
    // showChart
    // teamNames
    // shiftNames
    // numColumns
    // view
    // weekOffset
    // displayedValues
    // dayOffset
    // year
    // month
    // daysColumns
    // schedule
    // deleteShift
    // editShiftinstance
    // TODO OPTIMIZE RENDER
    return (
      <table className="scheduleTable">
        <thead>
          <tr>
            <th className="left-th">
              <div className="action-buttons-wrapper">
                <div className="action-buttons">
                  <Button
                    style={
                      showChart
                        ? {
                            backgroundColor: "#20304F",
                            color: "white",
                          }
                        : {}
                    }
                    className={clsx('chat-toggler-button', {
                      'is-closed': !showChart
                    })}
                    onClick={() => setShowChart(!showChart)}
                    type="default"
                    size="small"
                  >
                    {showChart ? "Hide Graph" : "Show Graph"}
                  </Button>
                </div>
              </div>
              <BarLegend showChart={showChart} legendOptions={legendOptions} />
              {renderTableHeader()}
            </th>

            <th className="right-th" colSpan={barCellColSpan}>
              <RechartBar
                view={view}
                totalEmployees={totalEmployees}
                isDayView={view === "day"}
                barData={barData[view]}
                showChart={showChart}
              />
            </th>
          </tr>
        </thead>
        <tbody className="schedule_body">
          {filteredEmployees.map(([employee, employeeShifts]) => {
            const totalShifts: number[] = [];
            employeeShifts.forEach((shift: any) => {
              if (shift.status === "1") {
                totalShifts.push(+shift.working_hours);
              }
            });

            const total = totalShifts.reduce(
              (acc, currentValue) => acc + currentValue,
              0
            );
            
            const [employeeName, employeeId] = employee.split(",");
            const currentWorker = employees[employeeName as string];
            const employeeColor = currentWorker?.employee.team.shiftName.color;

            return (
              <tr
                key={employee}
                style={{
                  borderBottom: "1px solid #dee2e6",
                  position: "relative",
                }}
              >
                <td
                  style={{
                    borderBottom: "1px solid #dee2e6",
                    padding: "3px 10px",
                    textAlign: "left",
                    backgroundColor: "white !important",
                    width: `${view == "week" ? "100px" : "30px"}`,
                  }}
                  className="calendar-columns teams-member-col"
                >
                  <TeamMemberColumn
                    name={employeeName}
                    id={employeeId}
                    total={total}
                    color={employeeColor}
                  />
                </td>
          
                <ShiftBox
                  shiftNames={shiftNames}
                  teamNames={teamNames}
                  numColumns={numColumns}
                  view={view}
                  weekOffset={weekOffsetForShiftCount}
                  weekSize={weekSize}
                  dayOffset={dayOffset}
                  year={year}
                  month={month}
                  dayColumns={daysColumns}
                  shifts={schedule}
                  employeeShifts={employeeShifts}
                  employee={employeeName}
                  // shiftID={shiftId}
                  deleteShift={deleteShift}
                  // setShiftid={setShiftID}
                  editShiftInstance={editShiftinstance}
                  createShift={handleCreateShift}
                  shiftWorkingDays={shiftWorkingDays}
                />
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderLeaveTable = () => {
    return (
      <LeaveTable
        renderTableHeader={renderTableHeader}
        showChart={showChart}
        toggleLeaveView={toggleLeaveView}
        setShowChart={setShowChart}
      />
    );
  };

  const content = (
    <div className="scheduleCard">
      <Row
        className="schedule_row"
        align="middle"
        justify="end"
      >
         <Col span={4}>
          {renderToggler()}
        </Col>
        <Col span={6} >
          {renderPercentageCalculator()}
        </Col>
        <Col span={6} offset={1}>
          {renderMonthNavigation()}
          {renderWeekDayNavigation()}
        </Col>
        <Col span={5} offset={2}>
          {isLeaveView ? (
            <LeaveActionButtons 
            shiftdropdown={shiftdropdown}
            handleGenerate={handleGenerate} 
            defaultScheduleValue={defaultScheduleValue}
            />
          ) : (
            renderActionButtons()
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24} className="scheduleTable-wrapper">
          {/* TODO: Remove blink on view change */}
          {isLeaveView ? renderLeaveTable() : renderScheduleTable()}
        </Col>
      </Row>
      <Row></Row>
    </div>
  );

  if (isLeaveView) {
    return (
      <LeaveTrackerProvider initialDate={currentDate}>
        {content}
        <LeaveModals />
      </LeaveTrackerProvider>
    );
  } else {
    return <Fragment>{content}</Fragment>;
  }
});

export default EmployeeShifts;
