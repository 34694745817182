import { memo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { LegendOption } from "./BarLegend";

const weekendDays = ["Sa", "Su"];
interface Props {
  view?: string;
  totalEmployees: number;
  showChart: boolean;
  barData: string[];
  isDayView: boolean;
}

const BAR_COLORS = {
  ALL: "#c8d9fe",
  SELECTED: "#657eb9",
  REQUIRED: "red",
};

export const legendOptions: LegendOption[] = [
  {
    color: BAR_COLORS.ALL,
    label: "Available",
  },
  {
    color: BAR_COLORS.SELECTED,
    label: "Planned",
  },
  {
    color: BAR_COLORS.REQUIRED,
    label: "Required",
  },
];

type Tick = {
  x: number;
  y: number;
  payload: {
    value: string;
  };
};

const CustomTooltip = ({ active, payload, label, total }: any) => {
  if (active && payload && payload.length) {
    const plannedEmployees = payload[0];
    const availableEmployees = payload[1];

    const labels = label.split(" ");
    const required = labels[3];

    return (
      <div className="custom-tooltip bar-chart">
        <p>{labels.slice(0, 2).join(" ")}</p>
        {required && (
          <p style={{ color: BAR_COLORS.REQUIRED }}>Required: {required}</p>
        )}
        <p style={{ color: plannedEmployees?.color }}>
          Planned Employees: {plannedEmployees.value}
        </p>
        <p style={{ color: availableEmployees?.color }}>
          Available Employees: {total}
        </p>
      </div>
    );
  }

  return null;
};

const renderCustomAxisTick = (tickProps: Tick) => {
  const { x, y, payload } = tickProps;

  const labelParts = payload.value.split(" ");
  const dayLabel = labelParts[1].slice(0, 2);
  const isWeekend = weekendDays.includes(dayLabel);

  const getText = (text: string, index: number) => (
    <text
      x={0}
      y={index * 15}
      dy={16}
      textAnchor="middle"
      // fill={"#878789"}
      fill={isWeekend ? "#000" : "#878789"}
      className="xAxis-label"
    >
      {text}
    </text>
  );

  return (
    <g transform={`translate(${x},${y - 5})`}>
      {getText(labelParts[0], 0)}
      {getText(dayLabel, 1.3)}
    </g>
  );
};

// @ts-ignore
const RenderCustomBarWithTarget = props => {
  const { fill, x, y, width, height, totalEmployees, payload } = props;

  const requiredValue = payload.Required;
  const totalHeight = y + height;

  const plannedBar = fill === BAR_COLORS.SELECTED;

  let allowRender = true;

  let targetY = 100;

  const TOP_AREA_OFFSET = 33;

  if (plannedBar) {
    const tickSize = (totalHeight - TOP_AREA_OFFSET) / totalEmployees;
    const barValue = props[legendOptions[1].label];

    allowRender = requiredValue < barValue;

    targetY = totalHeight - tickSize * requiredValue ;
   
  } else {
    const barValue = props[legendOptions[0].label];
    const tickSize =barValue === 0 
    ? (totalHeight - TOP_AREA_OFFSET) / totalEmployees
    : height / barValue;

    const selectedBarValue = props[legendOptions[1].label];

    allowRender = requiredValue > selectedBarValue || requiredValue === selectedBarValue;
    
    targetY = totalHeight - tickSize * (requiredValue - selectedBarValue);
  }

  return (
    <svg>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke="none"
        fill={fill}
      />
      {allowRender && requiredValue !== 0 && (
        <line
        x1={x}
        x2={x + width}
        y1={targetY + 1.5}
        strokeWidth={3}
        y2={targetY + 1.5}
        stroke={"red"}
      />
      )}
    </svg>
  );
};

const RechartBar = ({
  view,
  barData,
  totalEmployees,
  showChart,
  isDayView,
}: Props) => {
  const data = barData.map((item: string) => {
    const values = item.split(" ");

    const currentValue = parseInt(values[2]) || 0;
    const limitValue = parseInt(values[3]) || 0;

    return {
      name: item,
      [legendOptions[0].label]: totalEmployees - currentValue,
      [legendOptions[1].label]: currentValue,
      [legendOptions[2].label]: limitValue,
    };
  });


  const ticks = [];
  for (let key = 0; key <= totalEmployees; key++) {
    if (key % 4 === 0) {
      ticks.push(key);
    }
  }
  const maxYaxisValue = totalEmployees + 4;

  return (
    <div className={`chart-container ${!showChart ? "hidden" : ""}`}>
      <ResponsiveContainer width="104%" height={showChart ? 250 : 40}>
        <BarChart
          barSize={view === "month" ? 30 : ""}
          barGap={0}
  
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={renderCustomAxisTick} interval={0} />
          <YAxis
            width={30}
            domain={[0, maxYaxisValue]}
            ticks={ticks}
            interval={0}
          />
          <Tooltip content={<CustomTooltip total={totalEmployees} />} />

          {/* planned  */}
          <Bar
            shape={
              <RenderCustomBarWithTarget
                showChart={showChart}
                totalEmployees={totalEmployees}
              />
            }
            
            dataKey={legendOptions[1].label}
            stackId="a"
            fill={legendOptions[1].color}
          />
          {/* Available */}
          <Bar
            shape={
              <RenderCustomBarWithTarget totalEmployees={totalEmployees} />
            }
            barSize={3}
            dataKey={legendOptions[0].label}
            stackId="a"
            fill={legendOptions[0].color}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default memo(RechartBar);
