import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Form, Popover, Space, Button } from "antd";
import clsx from 'clsx';
import dayjs from 'dayjs';

import React, { useEffect, useState, useRef } from "react";

import EditShiftModal from "./EditShiftModal";

import moment from "moment";
import { DateTime } from "luxon";
import { numberToHoursMinutes } from '../../utils/dates'
import { EmployeeShift, ISchedule } from '../schedule/types';
import CreateShiftModalButton from './CreateShiftModalButton';

const getShiftLabel = (
    isVocation: boolean, 
    isShiftWithinLeave: boolean, 
    defaultLabel: string | number
) => {
    let shiftLabel: string | number;

    switch (true) {
        case isShiftWithinLeave:
            shiftLabel = 'A';
            break;
        case isVocation:
            shiftLabel = 'P';
            break;
        default:
            shiftLabel = defaultLabel
    }
    return shiftLabel;
}

interface IShiftProps {
    numColumns: number;
    view: string;
    weekOffset: number;
    weekSize: number;
    year: number;
    month: number;
    dayOffset: any;
    employeeShifts: EmployeeShift[];
    dayColumns: any;
    shifts: any;
    employee: any;
    deleteShift: any;
    editShiftInstance: any;

    shiftNames: any;
    teamNames: any;
    createShift: any
    shiftWorkingDays: any
}

const ShiftBox: React.FC<IShiftProps> = (props) => {
    const { 
        numColumns,
        view,
        weekOffset,
        weekSize,
        year,
        month,
        dayOffset,
        employeeShifts,
        dayColumns,
        shifts,
        deleteShift,
        editShiftInstance,
        createShift,
        teamNames,
        shiftNames,
        shiftWorkingDays
    } = props;

    const dayCellRef = useRef<HTMLTableCellElement | null>(null);
    const uniqueKey = new Date("2022-03-25");

    const [editShiftModalOpen, setEditShiftModalOpen] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);

    const close = () => {
        setPopupVisible(false);
    };


    const renderCount = useRef(0);
    useEffect(() => {
        renderCount.current = renderCount.current + 1;
    });

    // console.log(renderCount.current,'_____renderCount______')
    const [form] = Form.useForm();


    const showEditShiftModal = () => {
        setEditShiftModalOpen(true);
    };
    const handleClose = () => {
        setEditShiftModalOpen(false);
    };
    
    const getFieldsValue = (shift_id: number) => {
        const {
            id,
            end_date,
            start_date,
            worker: {
                team
            }
        } = shifts?.find((shift: ISchedule) => shift.id === shift_id);

        form.setFieldsValue({
            id,
            shiftName_id: team.shiftName.id,
            team_id: team.id,
            end_date: dayjs(end_date),
            start_date: dayjs(start_date),
        });
    };

    const Content = ({ close, shiftId }: any) => {
        return (
            <Space>
                <div className="delete-icon-container">
                    <DeleteOutlined className="delete-icon" onClick={() => {
                        deleteShift(shiftId).then(() => {
                            close();
                        });
                        
                    }} />
                </div>
                <div
                    className="edit-icon-container"
                    onClick={() => {
                        getFieldsValue(shiftId);
                        showEditShiftModal();
                       
                        close();
                    }}
                >
                    <EditOutlined className="edit-icon" />
                </div>
            </Space>
        )
    }

    const [dayShiftWidth, setDayShiftWidth] = useState(32);

    const handleResize = () => {
        if (dayCellRef.current) {
            const newSpanWidth = dayCellRef.current.clientWidth;
            setDayShiftWidth(newSpanWidth);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if(view === 'day') {
            handleResize();
        }
    }, [view]);

    const renderShiftButton = (shift: any) => {
        const startTime: any = new Date(shift?.start);
        const endTime: any = new Date(shift?.end);
        const shiftDuration: any =
            (endTime - startTime) / (1000 * 60 * 60)

        // const startTime = shift.start.getHours();
        // const leftPosition = `${((startTime / 24) - 330 / 1440) * 100}%`;
        // const shiftDurationLabel = `${shiftDuration}`;
                
        const margins: {
            marginRight?: string;
            marginLeft?: string
        } = {

        }
        if(startTime && view === "week" ) {
            const spaceSize = 8;
            const startTimeUTC = + moment(shift?.start).utc().format('H');

            const hoursDiff = Math.abs(12 - startTimeUTC);

            if(startTimeUTC > 12) {
                margins.marginRight = `${- spaceSize * hoursDiff}px`;
            } else if(startTimeUTC < 12) {
                margins.marginLeft = `${- spaceSize * hoursDiff}px`;
            }
        }
        
        const isVocation = shift.status === '0';
        const isShiftWithinLeave = isVocation && moment(shift.start).isBetween(shift.leave_start_date, shift.leave_end_date, null, '[]')
        
        const shiftLabel = getShiftLabel(isVocation, isShiftWithinLeave, numberToHoursMinutes(shift.working_hours))
        // const shiftLabel = getShiftLabel(isVocation, isShiftWithinLeave, numberToHoursMinutes(shiftDuration))

        const shiftStyles = {
            width: view === "week" ? shiftDuration > 0 ? `${shiftDuration * 3}%` : '3px' : '',
            ...margins,
        }
        if(shift.color) {
            // @ts-ignore
            shiftStyles.borderBottomColor = shift.color;
        }
        return (
            <Popover
                content={<Content close={close} shiftId={shift?.id}/>}
                // @ts-ignore
                popupVisible={popupVisible}
                trigger={["click"]}
                placement="bottom"
                getPopupContainer={(triggerNode: any) => triggerNode}
                className="leaves_popover-inner"
                onOpenChange={(visible: any) => {
                    setPopupVisible(visible);
                }}
            >
                <div
                    className="shift_wrapper"
                    id={`${new Date(shift?.start).getMonth() *
                        (Math.random() * 600) +
                        100
                        }`}
                    key={`${new Date(shift?.start).getMonth() *
                        (Math.random() * 600) +
                        100
                        }`}
                    style={{
                        // marginBottom: "10px",
                        cursor: "pointer",
                    }}
                    // onClick={() => setShiftid(shift?.id)}
                >
                    <span
                        style={shiftStyles}
                        className={clsx('shift_content',{
                            vocation: isVocation,
                            leave: isShiftWithinLeave,
                            month: view === 'month',
                           
                        })}
                    >
                        {shiftLabel}
                    </span>
                </div>
            </Popover>
        );
    }

    const renderMonthWeekShift = () => (
        (view == "week" || view == "month") &&
            Array.from({ length: view === "week" ? weekSize : numColumns }).map((_, i) => {
                let dayIndex =view === "week" ? weekOffset : i;
                if (view === "week") {
                    dayIndex +=  i;
                }

                const dayShifts = employeeShifts.filter(
                    (shift: any) =>
                        DateTime.fromISO(shift.start, { zone: "utc" }).year === year &&
                        DateTime.fromISO(shift.start, { zone: "utc" }).month ===
                        month + 1 &&
                        DateTime.fromISO(shift.start, { zone: "utc" }).day ===
                        dayIndex + 1
                );
    
                return (
                    <td
                        // key={uniqueKey.getMonth() * (Math.random() * 600) + 10}

                        // id={(uniqueKey.getMonth() * (Math.random() * 600) + 10).toString()}
                        className={`${view === "week" ? "weekly_shift" : "monthly_shift"
                           } -${i}`}
                    >
                     {dayShifts.length > 0 
                        ? dayShifts.map(renderShiftButton) 
                        : renderAddButton(dayIndex)}
                        {/* {dayShifts.map(renderShiftButton)} */}
                    </td>
                );
            })
    )

    const renderDayShiftButton = (shift: any) => {
        // const startTime = shift.start.getHours();
        // const leftPosition = `${((startTime / 24) - 330 / 1440) * 100}%`;
        let shiftDuration: any =
            (new Date(shift.end).getTime() -
                new Date(shift.start).getTime()) /
            (1000 * 60 * 60);

        const key = `${new Date(shift?.start).getMonth() * (Math.random() * 600) + 100}`;

        const startTime = moment(shift?.start).utc().format('HH:mm');
        const endTime = moment(shift?.end).utc().format('HH:mm');

        const isVocation = shift.status === '0';
        const isShiftWithinLeave = isVocation && moment(shift.start).isBetween(shift.leave_start_date, shift.leave_end_date, null, '[]')

        const shiftLabel = getShiftLabel(isVocation, isShiftWithinLeave, startTime + "-" + endTime)

        return (
            <Popover
                content={<Content close={close} />}
                trigger={["click"]}
                placement="bottom"
                getPopupContainer={(triggerNode: any) => triggerNode}
                className="leaves_popover-inner"
            >
                <div
                    className="dayShifts"
                    id={key}
                    key={key}
                    style={{
                        marginBottom: "10px",
                        cursor: "pointer",
                    }}
                >
                    <span
                        className={clsx('shift_content_day',{
                            vocation: isVocation,
                            leave: isShiftWithinLeave,
                        })}
                        style={{
                            width: `${dayShiftWidth * shiftDuration}px`,
                        }}
                    >
                        {shiftLabel}
                    </span>
                </div>
            </Popover>
        );
    }

    const renderAddButton = (
        dayIndex: number, 
    ) => {
        const {  
            employee,
            month,
            year,
        } = props

        return (
            <CreateShiftModalButton
                handleCreate={createShift}
                employee={employee}
                month={month}
                year={year}
                day={dayIndex}
                shiftWorkingDays={shiftWorkingDays}
            />
          
        )
    }
    
    const renderDayShift = () => view == "day" &&
        Array.from({ length: dayColumns }).map((_, i) => {
            let dayIndex: any;
            const formattedHour = moment()
                .startOf("day")
                .add(i, "hours")
                .format("HH:mm");
            if (view == "day") {
                dayIndex += dayOffset;
            }

            const dayShifts = employeeShifts.filter((shift: any) => {
                const dateUTC = DateTime.fromISO(shift.start, { zone: "utc" });
                const momentDate =  moment(new Date(shift.start).getTime()).utc().format("HH:mm");
                   
                const isValidDate = dateUTC.year === year && 
                    dateUTC.month === month + 1 &&
                    dateUTC.day === dayOffset + 1 &&
                    momentDate.slice(0, 2) == formattedHour.slice(0, 2);
                return isValidDate
            });

            return (
                <td
                    ref={dayCellRef}
                    className="dayCell"
                    key={uniqueKey.getMonth() * (Math.random() * 600) + 10}
                    id={(
                        uniqueKey.getMonth() * (Math.random() * 600) +
                        10
                    ).toString()}
                >
                    {dayShifts.length > 0 
                    ? dayShifts.map(renderDayShiftButton)
                    : renderAddButton(dayIndex)} 
                </td>
            );
        })

    return (
        <>
            {renderMonthWeekShift()}
            {renderDayShift()}
            <EditShiftModal
                isOpen={editShiftModalOpen}
                handleClose={handleClose}
                submitEditData={editShiftInstance}
                shiftNames={shiftNames}
                teamNames={teamNames}
                form={form}
            />
        </>
    );
};

export default ShiftBox;
