import React, { ReactNode, useState, useEffect } from "react";
import { EmployeeContextI, SecondShiftI, EmployeeDataI } from "../../utils/interfaces";
import { Form, notification } from "antd";
import axios, { AxiosResponse } from "axios";
import { workerUrl, teamUrl, secondShifNameUrl } from "../../utils/network";

// import { teamcompositionMock } from '../../mock_data/teamcomposition'

export const EmployeeContext: any = React.createContext<EmployeeContextI | undefined>(undefined);


export const EmployeeProvider = ({ children }: { children: ReactNode }) => {
    const [employeeData, setEmployeeData]: any = useState([]);
    const [teamNames, setTeamNames] = useState<any[]>([]);
    const [update, setUpdate] = useState(false);
    const [empId, setEmpId]: any = useState();
    const [add_form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [addEmployeeModal, setEmployeeModal] = useState(false);
    const [updateEmployeeModal, setUpdateEmployeeModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const updateEmployee: any = employeeData?.filter(
        (emp: any, id: number) => emp?.id == empId
    );

    const worker = updateEmployee[0]?.worker;
    const shiftName = updateEmployee[0]?.team.shiftName?.id;
    const teamName = updateEmployee[0]?.team?.id;
    const position = updateEmployee[0]?.position;
    const externalID = updateEmployee[0]?.external_id;
    const secondaryShifts = updateEmployee[0]?.secondShifts || [];
    const secondaryShiftsIds = secondaryShifts.map((item: SecondShiftI) => item.secondary_shift_id).join(',')



    const showModal = () => {
        setEmployeeModal(true);
    };

    const handleOk = () => {
        setEmployeeModal(false);
    };

    const handleUpdateModalok = () => {
        setUpdateEmployeeModal(false);
    };

    const getEmployeeDetails = async () => {
        try {
            const response: AxiosResponse = (await axios.get(
                workerUrl
            )) as AxiosResponse;

            const employeeData = response.data?.results;
            const secondShiftsData = await getSecondShifts();

            const employeeWithSecondShift = employeeData.map((employeeItem: EmployeeDataI) => {
                const secondShifts = secondShiftsData.filter((shiftItem: SecondShiftI) => {
                    return shiftItem.worker.worker === employeeItem.worker
                })
                
                return {
                    ...employeeItem,
                    secondShifts: secondShifts || []
                }
            })
            
            setEmployeeData(employeeWithSecondShift || []);
            
            return employeeData;
        } catch (err) {
            notification.error({
                message: "Error Fetching Employee Details",
            });
        }
    };
    const getTeams = async () => {
        try {
            const response = await axios.get(teamUrl);
            const teamData = response.data.results;
            setTeamNames(teamData);
        } catch (e) {
            throw new Error();
        }
    };    
    const getSecondShifts = async () => {
        try {
            const response = await axios.get(secondShifNameUrl);
            const secondShiftData = response.data.results;
            return secondShiftData
        } catch (e) {
            throw new Error();
        }
    };

    const handleAddSecondaryShift = async (secondary_shift_id: string, external_id: string) => {
        const response = await axios.post(secondShifNameUrl, {
            external_id,
            secondary_shift_id
        });
        return response
    }

    const handleDeleteSecondaryShift = async(secondary_shift_pk_id:number, external_id: string) => {
        const response = await axios.delete(`${secondShifNameUrl}/${secondary_shift_pk_id}`, {
            data: {
                external_id
            },
        });
        return response
    }
    
    const handleSecondaryShiftUpdate = async (data: any) => {
        const itemsToDelete: number[] = []
        secondaryShifts.forEach((secondaryItem: SecondShiftI) => {
            const isExistedInitially = data.secondaryShifts.find((secondItem: string) => secondItem === secondaryItem.secondary_shift_id)
            if(!isExistedInitially) {
                itemsToDelete.push(secondaryItem.id)
            } 
        })
        
        await Promise.all(itemsToDelete.map(
            item => handleDeleteSecondaryShift(item, data.external_id)
        ))

        await handleAddSecondaryShift(
            data.secondaryShifts.join(','), 
            data.external_id
        )
    }

    const submitEmployeeData = async (value: any) => {
        const data = { ...value };

        try {
            const response: AxiosResponse = (await axios.post(
                workerUrl,
                data
            )) as AxiosResponse;
            await handleSecondaryShiftUpdate(data)

            if (response) {
                
                notification.success({
                    message: "New Employee has been added successfully",
                });
                add_form.resetFields();
                getEmployeeDetails();
                handleOk();
            }
            return response;
        } catch (e) {
            notification.error({
                message: "Some error occured while adding a new employee",
            });
        }
    };

    const updateEmployeeData = async (value: any) => {
        const data = { ...value };
        try {
            const response: AxiosResponse = (await axios.put(
                `${workerUrl}/${empId}`,
                data
            )) as AxiosResponse;
            
            await handleSecondaryShiftUpdate(data)
            // teamcompositionMock
            if (response) {
                notification.success({
                    message: " Employee has been updated successfully",
                });
                add_form.resetFields();
                getEmployeeDetails();
                handleUpdateModalok();
            }
            return response;
        } catch (e) {
            notification.error({
                message: "Some error occured while updating employee",
            });
        }
    };

    const deleteEmployee = async (id: number) => {
        try {
            const response = await axios.delete(`${workerUrl}/${id}`);
            if (response) {
                notification.success({
                    message: "Employee has been deleted",
                });
                getEmployeeDetails();
            }
        } catch (e) {
            notification.error({
                message: "Some error occured while deleting...",
            });
        }
    };

    const getFieldsValue = () => {
        const shiftsArray = secondaryShiftsIds ? secondaryShiftsIds.split(',') : []
        
        updateForm.setFieldsValue({
            shiftName_id: shiftName,
            worker: worker,
            team_id: teamName,
            secondaryShifts: shiftsArray.map((item: string) => +item)
        });
    };


    useEffect(() => {
        getEmployeeDetails();
        getTeams();
        
        const shiftsArray = secondaryShiftsIds ? secondaryShiftsIds.split(',') : []
        
        updateForm.setFieldsValue({
            shiftName_id: shiftName,
            worker: worker,
            team_id: teamName,
            position: position,
            external_id: externalID,
            secondaryShifts: shiftsArray.map((item: string) => +item)
        });
    }, [updateForm, shiftName, teamName, worker, externalID, position, secondaryShiftsIds]);

    return (
        <EmployeeContext.Provider
            value={{
                showModal,
                employeeData,
                setEmpId,
                getFieldsValue,
                addEmployeeModal,

                submitEmployeeData,
                add_form,
                updateEmployeeModal,
                update,

                updateEmployeeData,
                updateForm,
                teamNames,
                deleteModal,

                deleteEmployee,
                empId,

                setEmployeeModal,
                setUpdateEmployeeModal,
                setDeleteModal,
            }}
        >
            {children}
        </EmployeeContext.Provider>
    )

}
