import { EmployeeShift } from '../components/schedule/types'

export const months = [
    {
        id: 1,
        name: "January"
    },

    {
        id: 2,
        name: "February"
    },
    {
        id: 3,
        name: "March"
    },
    {
        id: 4,
        name: "April"
    }, {
        id: 5,
        name: "May"
    },
    {
        id: 6,
        name: "June"
    },
    {
        id: 7,
        name: "July"
    },
    {
        id: 8,
        name: "August"
    },
    {
        id: 9,
        name: "September"
    },
    {
        id: 10,
        name: "October"
    },
    {
        id: 11,
        name: "November"
    },
    {
        id: 12,
        name: "December"
    }
]


export const generateDates = () => {
    const daysInMonth = 31; // You can adjust this based on the month
    const dates = [];

    for (let day = 1; day <= daysInMonth; day++) {
        dates.push(day);
    }

    return dates;
};

export const days = [
    {
        id: 0,
        day: "monday"

    }
]

export const daysOfWeek = [
    "Sunday",

    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "Jun",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const formatDate = (date: any) => {
    return date.toLocaleDateString("en-US", { month: "long", year: "numeric" });
};
export const isWorkingDay = (date: Date): boolean => {
    const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
    return dayOfWeek >= 1 && dayOfWeek <= 5; // Monday to Friday
}

export const getWorkingDaysInMonth = (date: Date | string): number => {
    const currentDate = new Date(date);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0); // Setting day to 0 gets the last day of the previous month
  
    let workingDayCount = 0;
    let currentDatePointer = firstDayOfMonth;
  
    while (currentDatePointer <= lastDayOfMonth) {
      if(isWorkingDay(currentDatePointer)) {
        workingDayCount++;
      }
      
      currentDatePointer.setDate(currentDatePointer.getDate() + 1); // Move to the next day
    }
  
    return workingDayCount;
  }

export const getWorkingDaysInWeek = (date: Date): number =>  {
    const startDate = new Date(date);
    startDate.setDate(date.getDate() - date.getDay() + 1); // Start of the week (Monday)
    const endDate = new Date(date);
    endDate.setDate(startDate.getDate() + 4); // End of the week (Friday)
  
    let workingDayCount = 0;
    let currentDate = new Date(startDate);
  
    while (currentDate <= endDate) {
      if(isWorkingDay(currentDate)) {
        workingDayCount++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return workingDayCount;
}
  
function getWeekNumber(date: Date, monthIndex: number, year: number): number {
    const firstDayOfMonth = new Date(year, monthIndex, 1);
    const daysUntilDate = (date.getTime() - firstDayOfMonth.getTime()) / (1000 * 60 * 60 * 24);
    const weekNumber = Math.floor(daysUntilDate / 7) + 1;
    return weekNumber;
  }

export const calculateTotalDurationInRange = (
    shifts: EmployeeShift[],
    type: 'month' | 'week' | 'day',
    monthIndex: number,
    year: number,
    weekNumber?: number,
    dayNumber?: number 
  ): number => {
    let totalDuration = 0;
    for (const shift of shifts) {
      const shiftStartDate = new Date(shift.start);
      
      // Check if the shift falls within the specified date range
      if (
        (type === 'month' && shiftStartDate.getMonth() === monthIndex && shiftStartDate.getFullYear() === year) ||
        (type === 'week' && getWeekNumber(shiftStartDate, monthIndex, year) === weekNumber) ||
        (type === 'day' && shiftStartDate.getDate() === dayNumber && shiftStartDate.getMonth() === monthIndex && shiftStartDate.getFullYear() === year) // Include day index check
      ) {
        const shiftDuration = +shift.working_hours;
        
        totalDuration += shiftDuration;
      }
    }
    return totalDuration;
  }


  export function numberToHoursMinutes(number: number) {
    // Get the integer part (hours)
    const hours = Math.floor(number);
  
    // Get the decimal part (minutes)
    const decimalPart = number - hours;
    const minutes = Math.round(decimalPart * 60);
  
    // Format the hours and minutes as "hh:mm"
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    if(formattedMinutes === '00') {
        return + formattedHours;
    }

    return `${+ formattedHours}:${formattedMinutes}`;
  }