import React from "react";
import {
  Modal,
  Divider,
  Row,
  Col,
  Form,
  Select,
  Input,
  InputNumber,
  Button,
  Checkbox,
} from "antd";

interface TeamModalI {
  isModalOpen: boolean;
  handleOk: any;
  handleCancel: any;
  SubmitTeamWorkingDays: any;
  shiftNames: any;
  teamNames: any;
  recentTeam: any;
  isUpdate: boolean;
  form: any;
  wID: any;
}
const TeamWorkingDaysModal: React.FC<TeamModalI> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  shiftNames,
  teamNames,
  recentTeam,
  isUpdate,
  form,
  wID,
  SubmitTeamWorkingDays,
}) => {
  return (
    <Modal
      title={isUpdate ? "Update Team Working Days" : "Add Team Working Days"}
      className="teams_modal"
      open={isModalOpen}
      mask={false}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={SubmitTeamWorkingDays}
        form={form}
      >
        <Form.Item
          style={{ padding: ".5rem" }}
          label="Shift Name"
          name="shiftName_id"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Shift Name is required",
            },
          ]}
        >
          <Select
            placeholder="Select Shift"
            options={shiftNames.map((item: any, index: number) => ({
              value: item?.id,
              label: item?.shiftName,
            }))}
          />
        </Form.Item>

        <Form.Item
          style={{ display: "none" }}
          label="Team id"
          name="team_id"
          labelCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          style={{ padding: ".5rem" }}
          label="Team name"
          name="teamName"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Team Name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <h3 className="section_title">Working pattern</h3>
        <hr className="divider" />
        <Row>
          <Col span={10}>
            <Form.Item
              style={{ padding: ".5rem", width: "100%" }}
              label="Work days"
              name="workdays"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Workday is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              style={{ padding: ".5rem", width: "100%" }}
              label="Rest days"
              name="restdays"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Restday is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              style={{ padding: ".5rem" }}
              label="Rotating Team"
              name="rotating_team"
              valuePropName="checked"
              labelCol={{ span: 24 }}
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

        <h3 className="section_title">Constraints</h3>
        <hr className="divider" />
        <Row>
          <Col span={10}>
            <Form.Item
              style={{ display: "none" }}
              label=""
              name="constraint_id"
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ padding: ".5rem" }}
              label="Consecutive days check"
              name="consecutive_check_days"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Consecutive days is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item
              style={{ padding: ".5rem" }}
              label="Max work days"
              name="max_work_days_per_days"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Max work days is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          
          <Col span={10}>
            <Form.Item
              style={{ padding: ".5rem" }}
              label="Min rest hours"
              name="min_rest_hours_per_days"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Min rest hours is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item
              style={{ padding: ".5rem" }}
              label="Max work hours"
              name="max_work_hours_per_days"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Max work hours is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              style={{ padding: ".5rem" }}
              label="max_work_hours_per_day"
              name="max_work_hours_per_day"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "max_work_hours_per_day is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              style={{ padding: ".5rem" }}
              label="Min rest hours between shifts"
              name="min_rest_hours_between_shifts"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Min rest hours is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ padding: ".5rem" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button style={{ marginRight: "20px" }} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#20304f" }}
            >
              {isUpdate ? "Update Team Working Days" : "Add Team Working Days"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TeamWorkingDaysModal;
