
import { Modal, Form, Input, Button, Select, DatePicker } from 'antd'
import React from 'react'

interface Edit {
    isOpen: boolean,
    handleClose: any;
    submitEditData: (result: any, onReady: () => void) => void,
    shiftNames: any;
    teamNames: any;
    form: any;
}

const EditShiftModal: React.FC<Edit> = ({
    isOpen,
    handleClose,
    submitEditData,
    teamNames,
    shiftNames,
    form,
}) => {
    const handleEdit = (result: any) => {
        submitEditData(result, handleClose)
    }
    return (
        <Modal
            title="Edit Shift"
            className="teams_modal"
            open={isOpen}
            mask={false}
            onOk={handleClose}
            onCancel={handleClose}
        >
            <Form
                initialValues={{
                    remember: false,
                }}
                onFinish={handleEdit}
                form={form}
            >
                <Form.Item
                    style={{ display: "none" }}
                    name="id"
 
                >
                <Input readOnly disabled={true}/>
                </Form.Item>
                <Form.Item
                    style={{ padding: ".5rem" }}
                    label="Start Date"
                    name="start_date"
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Worker Name is required",
                        },
                    ]}
                >
                    <DatePicker showTime placeholder='Start Date' format="YYYY-MM-DD HH:mm"
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                <Form.Item
                    style={{ padding: ".5rem" }}
                    label="Last Date"
                    name="end_date"
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Last Date is required",
                        },
                    ]}
                >
                    <DatePicker showTime placeholder='End Date' format="YYYY-MM-DD HH:mm"
                        style={{ width: "100%" }}

                    />
                </Form.Item>
                
                <Form.Item
                    style={{ padding: ".5rem" }}
                    label="Shift ID"
                    name="shiftName_id"
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Shift Name is required",
                        },
                    ]}
                >
                    <Select
                        aria-readonly
                        // disabled={true}
                        placeholder="Select Shift"
                        options={shiftNames?.length > 0 && shiftNames?.map((item: any, index: number) => ({
                            value: item?.id,
                            label: item?.shiftName,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    style={{ padding: ".5rem" }}
                    label="Team ID"
                    name="team_id"

                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Team Name is required",
                        },
                    ]}
                >
                    <Select
                        aria-readonly
                        // disabled={true}

                        placeholder="Select Team"
                        options={teamNames.length > 0 && teamNames?.map((item: any, index: number) => ({
                            value: item?.id,
                            label: item?.team,
                        }))}
                    />
                </Form.Item>

                <Form.Item style={{ padding: ".5rem" }}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button style={{ marginRight: "20px" }} onClick={handleClose} >
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ backgroundColor: "#20304f" }}>
                            Update Shift
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal >
    )
}

export default EditShiftModal