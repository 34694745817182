import Papa from 'papaparse';
import moment from 'moment';

const getTimeOffType = (record: any) => {
  if (record.status === '1') {
    return ''; // Empty for status 1
  } else if (record.leave_start_date && record.leave_end_date) {
    return 'A'; // Status 0 with leave dates
  } else {
    return 'P'; // Status 0 without leave dates
  }
};

// Function to download the CSV file
const downloadCSV = (csv: any) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = 'employee_data.csv';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const shiftsToCSV = (list: any) => {
  const allCSVData = [];
  
  for (const worker in list) {
    if (list.hasOwnProperty(worker)) {
      // const startDate = moment(record.start).utc().format('YYYY-MM-DD HH:mm'),
      const workerData = list[worker];
      const workerCSVData = workerData.map((record: any) => [
        record.external_id,
        record.status === '0' ? 'TO' : 'S',

        record.status === '0' 
          ? `${moment(record.start).utc().format('YYYY-MM-DD')} 00:00` 
          : moment(record.start).utc().format('YYYY-MM-DD HH:mm'),
        
        record.status === '0' 
          ? `${moment(record.end).utc().format('YYYY-MM-DD')} 23:59` 
          : moment(record.end).utc().format('YYYY-MM-DD HH:mm'),
        record.status === '0' ? '' : parseInt(record.break_duration_min || 0),
        getTimeOffType(record),
      ]);
      allCSVData.push(...workerCSVData);
    }
  }
  // Add headers to the CSV
  const csvWithHeaders = [
    ['User external ID', 'Type', 'Start date', 'End date', 'Break in minutes', 'Time-off type'],
    ...allCSVData,
  ];

  const csv = Papa.unparse(csvWithHeaders);
  downloadCSV(csv)
}
