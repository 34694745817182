import React, { useState, useId, useContext } from "react";
import { Checkbox, Row, Col, Select, Button, Input, Card } from "antd";

import {
  CheckOutlined,
  EditOutlined,
  CloudUploadOutlined,
  CloseOutlined,
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { useGetShift, useGetTeam } from "../utils/hooks";
import TeamWorkingDaysModal from "../components/Modals/TeamDetails/TeamModal";
import OptionsModal from "../components/Modals/TeamDetails/OptionsModal";
import DeleteModal from "../components/Modals/DeleteModal";
import { TeamsContextI } from "../utils/interfaces";
import { TeamsContext } from "../Context/ShiftSettings/TeamsContext";

const TeamWorkingPattern = () => {
  const {
    mergedData,
    employee_counts,
    setUpdateWorkingDays,
    setEditWorkingDaysId,
    setTeamId,
    form,
    deleteTeam,
    teamId,
    handleTeamModalOk,
    SubmitTeamWorkingDays,
    updateWorkingDays,
    recentTeam,
    workingDaysId,
    workingDaysForm,
    teamModalOpen,
    setTeamModalOpen,
  } = useContext<TeamsContextI>(TeamsContext);

  const [deleteModalopen, setDeleteModalOpen] = useState(false);
  const [shiftNames, setShiftNames] = useState<any[]>([]);
  const [fetching, setFetching] = useState(true);
  const [searchTerm, setSerachterm] = useState("");
  const [teamNames, setTeamNames] = useState<any[]>([]);

  useGetShift(setShiftNames, setFetching);
  useGetTeam(setTeamNames, setFetching);

  const handleShiftChange = (value: any) => {
    setShiftNames(value);
  };

  const teamWorkingDaysModal = () => {
    setTeamModalOpen(true);
  };

  const handleTeamModalCancel = () => {
    setTeamModalOpen(false);
    setEditWorkingDaysId("");
    workingDaysForm.resetFields();
  };

  //methods for handling Delete Modal
  const showDeleteModal = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteodalOk = () => {
    setDeleteModalOpen(false);
  };
  const handleDeleteModalCancel = () => {
    setDeleteModalOpen(false);
  };

  return (
    <>
      <Card
        style={{ 
          margin: "0 20px", 
          border: "none", 
          borderRadius: 0, 
          padding: "15px !important" 
        }}
        className="shift-filters-row"
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <div>
                  <SearchOutlined
                    style={{
                      position: "absolute",
                      top: "10px",
                      zIndex: "1",
                      marginLeft: "12px",
                      fontSize: "17px",
                    }}
                  />
                  <Input
                    className="team-input"
                    placeholder="Search by name or id"
                    onChange={(e: any) => {
                      console.log(e.target.value);
                      setSerachterm(e.target.value);
                    }}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#20304F",
                      color: "white",
                      borderRadius: 5,
                      width: "200px",
                    }}
                    type="default"
                    size="large"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setUpdateWorkingDays(false);
                      teamWorkingDaysModal();
                    }}
                  >
                    Add Team
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Row style={{ padding: "0 20px"}}>
        <Col span={24}>
          <Card className="team-card">
            <table className="teams_table" style={{ borderRadius: 12 }}>
              <thead>
                <tr>
                  <th className="team_member_heading">
                    <Checkbox style={{ marginLeft: "15.2px" }} />
                    <span style={{ marginLeft: "18px" }}>Team name</span>
                  </th>
                  <th>Shift name</th>
                  <th>Work days</th>
                  <th>Rest days</th>
                  <th>rotating team</th>
                  <th>Employees</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {mergedData
                  .filter((team: any) => {
                    return searchTerm === ""
                      ? team
                      : team?.team?.teamName.includes(searchTerm);
                  })
                  .map((item: any, index: number) => {
                    return (
                      <tr className="data_row" key={item.id}>
                        <td>
                          <Checkbox />

                          <span style={{ marginLeft: "18px" }}>
                            {item?.team?.teamName}
                          </span>
                        </td>
                        <td>{item?.team.shiftName.shiftName}</td>
                        <td>
                          <div className="work_days_container">
                            <div className="work_days">
                              {/* {(team?.day == "Workdays" && team?.pattern) ||
                                (team?.days == "Workdays" && team?.patterns)} */}
                              {item?.workdays}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="rest_days_container">
                            <div className="rest_days">
                              {/* {(team?.day == "Restdays" && team?.pattern) ||
                                (team?.days == "Restdays" && team?.patterns)} */}
                              {item?.restdays}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="rotating_team_container">
                            <div className="rotating_team">
                              {item?.rotating_team ? (
                                <CheckOutlined />
                              ) : (
                                <CloseOutlined />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="employees_container">
                            {employee_counts?.map((emp: any, i: any) => {
                              
                              return (
                                <div key={i}>
                                  {emp?.team?.id == item?.team?.id &&
                                    emp?.shiftName?.id ==
                                    item?.shiftName?.id && (
                                      <span className="employees">
                                        {emp?.employees}
                                      </span>
                                    )}
                                </div>
                              );
                            })}
                          </div>
                        </td>
                        <td>
                          <EditOutlined
                            style={{ marginRight: "20px", fontSize: "20px" }}
                            onClick={() => {
                              setUpdateWorkingDays(true);
                              setEditWorkingDaysId(item?.id);
                              setTeamId(item?.team?.id);
                              // showoptionsModal();
                              teamWorkingDaysModal();
                            }}
                          />
                          <DeleteOutlined
                            style={{ fontSize: "20px", color: "red" }}
                            onClick={() => {
                              showDeleteModal();
                              setTeamId(item?.id);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>

      <DeleteModal
        title="Are you sure you want to delete this team?"
        handleDelete={deleteTeam}
        deleteOk={handleDeleteodalOk}
        deleteCancel={handleDeleteModalCancel}
        deleteOpen={deleteModalopen}
        id={teamId}
      />

      <TeamWorkingDaysModal
        isModalOpen={teamModalOpen}
        handleOk={handleTeamModalOk}
        handleCancel={handleTeamModalCancel}
        teamNames={teamNames}
        shiftNames={shiftNames}
        SubmitTeamWorkingDays={SubmitTeamWorkingDays}
        recentTeam={recentTeam}
        isUpdate={updateWorkingDays}
        wID={workingDaysId}
        form={workingDaysForm}
      />

      {/* <OptionsModal
        modalOpen={optionsModal}
        handleOk={handleOkOptionsModal}
        handleCancel={handleCancelOptionsModal}
        openWorkingDaysModal={teamWorkingDaysModal}
        openTeamNameModal={showModal}
        setTeamUpdate={setTeamUpdate}
      /> */}
    </>
  );
};

export default TeamWorkingPattern;
