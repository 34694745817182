import React, { useContext } from "react";
import { EmployeeContextI } from '../../../utils/interfaces'
import { EmployeeContext } from '../../../Context/ShiftSettings/EmployeeContext'
import { IShiftName } from '../../schedule/types'
import EmployeeModal from './EmployeeModal'

interface CreateEmployeeModalI {
  handleModalOk: () => void;
  handleModalCancel: () => void;
  shifts: {
    [key in string]: IShiftName
  },
}

const CreateEmployeeModal: React.FC<CreateEmployeeModalI> = props => {
  const {
    addEmployeeModal,
    submitEmployeeData,
    add_form
  } = useContext<EmployeeContextI>(EmployeeContext);

  const {
    shifts,
    handleModalOk,
    handleModalCancel
  } = props;

  if(!addEmployeeModal) return null;
  return (
    <EmployeeModal 
      title='Add New Employee'
      submitButtonTitle='Add Employee'
      handleModalok={handleModalOk}
      handleModalCancel={handleModalCancel}
      hadleFinish={submitEmployeeData}
      form={add_form}
      isOpen={addEmployeeModal}
      shifts={shifts}
    />
  )
};

export default CreateEmployeeModal;
