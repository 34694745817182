import { useMemo } from 'react'
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import clsx from 'clsx';
import { Row, Col, Input } from "antd";
import HamburgerMenu from "../components/HamburgerMenu";
import DropdownMenu from "../components/DropdownMenu";


import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { routesList } from '../utils/routesList'

const linksList = [
  {
    url: routesList.home,
    label: 'Forecast',
    icon: <TrendingUpIcon className="link_icon"/>
  },
  {
    url: routesList.shiftSettings,
    label: 'Shift Settings',
    icon: <SettingsIcon className="link_icon"/>
  },
  {
    url: routesList.schedule,
    label: 'Schedule',
    icon: <CalendarMonthOutlinedIcon className="link_icon"/>
  },
]

const TopBarNavigation = () => {
  const { pathname } = useLocation();
  
  const title = useMemo(() => {
    const result = linksList.find(item => item.url === pathname)
    return result?.label
  }, [pathname])

  const renderNavigation = () => {
    return (
      <div className='top_bar_navigation'>
        {linksList.map(({url, icon, label}) => {
          const isActive = pathname === url;
          return (
            <Link 
              to={url} 
              key={url}
              className={clsx('link_item',{
                active: isActive,
              })}>
                {icon}
                <span>{label}</span>
            </Link>
          )
        })}
      </div>
    )
  }

  return (
    <Row className="top_bar" align="middle">
      <Col span={8} >
        <h1 className="schedule_heading">{title}</h1>
      </Col>
      <Col span={8}>
        <div className="navigation_wrapper">
          {renderNavigation()}
        </div>
      </Col>
      <Col span={4} offset={4}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "17px",
            marginRight: "6rem",
          }}
        >
          <HamburgerMenu />
          <DropdownMenu />
        </div>
      </Col>
    </Row>
  )
}


export default TopBarNavigation;