import { FC } from 'react'
import { DashboardProvider } from "./Dashboard/DashboardContext";
import { combineComponents } from "../utils/combineComponents";
import { EmployeeProvider } from "./ShiftSettings/EmployeeContext";
import { TeamsProvider } from "./ShiftSettings/TeamsContext";
import { routesList } from '../utils/routesList'

export const getPageProvider = (route: string) => {
    const providers = {
        [routesList.home]: DashboardProvider,
        [routesList.schedule]: EmployeeProvider,
        [routesList.shiftSettings]: combineComponents(TeamsProvider as FC, EmployeeProvider as FC)
    }
    return providers[route]
}

// export const AppContextProvider: any = combineComponents(...providers);
