const BaseUrl = process.env.REACT_APP_API_URL;

export const scheduleUrl = BaseUrl + "scheduling/shiftsview"
export const scheduleShiftname = BaseUrl + "scheduling/shiftsview"
export const workerUrl = BaseUrl + "scheduling/teamcomposition"
export const shifNameUrl = BaseUrl + "scheduling/shift-name"
export const secondShifNameUrl = BaseUrl + "scheduling/secondary-shift"

export const teamcompositionUrl = BaseUrl + "scheduling/teamcomposition"
export const teamworkingpatternUrl = BaseUrl + "scheduling/teamworkingpattern"
export const teamConstraintsViewUrl = BaseUrl + "scheduling/team-constraints"

export const shiftworkingdaysUrl = BaseUrl + "scheduling/shiftworkingdays"
export const scheduled = BaseUrl + 'scheduling/schedule'
export const teamUrl = BaseUrl + "scheduling/team"
export const ShiftdetailsUrl = BaseUrl + "scheduling/shift-details"
export const schedulerunUrl = BaseUrl + "scheduling/schedulerun"
export const dailyworkersUrl = BaseUrl + "scheduling/daily-workers"
export const plannedleavesURL = BaseUrl + "scheduling/planned-leaves"
export const recurringLeavesURl = BaseUrl + "scheduling/recurrence-leaves"
export const combinedURL = BaseUrl + "scheduling/combined"
export const productTypesSettingsUrl = BaseUrl + 'scheduling/product-types-settings'
export const scheduleWorkersRequest = BaseUrl + 'scheduling/scheduleWorkersRequestView'

export const orderforecastURL = BaseUrl + "ordersforecast/forecast"
export const actualForeCastURL = BaseUrl + "ordersforecast/cleaned-actuals"
export const forecasetModelRun = BaseUrl + 'ordersforecast/modelrun';