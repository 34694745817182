import { CopyOutlined, PlusOutlined, DeleteFilled } from "@ant-design/icons";
import {
  Modal,
  Tabs,
  Form,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  Button,
  TabsProps,
  ColorPicker,
} from "antd";

import React, { useState } from "react";
import ModalDayItem from "./ModalDayItem";
import ModalTableRowItem from "./ModalTableRowItem";

const dayOrder = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
interface WorkingDaysModalI {
  workingdaysModal: any;
  handleOK_workingDays: any;
  handleCancel_workingDays: any;
  form: any;
  SubmitWorkingDays: any;
  // SubmitProductTypes: any;
  shifts: any;
  handleFieldClick: any;
  shiftWorkingDays: any;
  shiftId: any;
  handleUpdatedDayClick: any;
  recentShift: any;
  isUpdate: boolean;
  updatedShiftid: any;
}

const WorkingDaysModal: React.FC<WorkingDaysModalI> = props => {
  const {
    workingdaysModal,
    handleOK_workingDays,
    handleCancel_workingDays,
    form,
    SubmitWorkingDays,
    // SubmitProductTypes,
    handleFieldClick,
    recentShift,
    isUpdate,
    updatedShiftid,
  } = props;

  const [activeTab, setActiveTab] = useState("1"); // Track active tab
  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };

  const handleCopyClick = () => {
    const formValues = form.getFieldValue();
    const firstDayKey = dayOrder.find(
      (dayName: string) => formValues[dayName]
    ) as string;

    const firstDayValue = formValues[firstDayKey];

    const updatedDayValues: any = {};
    dayOrder.forEach((day: string) => {
      if (formValues[day] && formValues[day]?.shift === "yes") {
        updatedDayValues[day] = firstDayValue;
      }
    });

    form.setFieldsValue({
      ...formValues,
      ...updatedDayValues,
    });
  };

  const addDay = (day: string) => {
    const newDay = {
      start_time: "",
      end_time: "",
      break_duration_min: 0,
      shift: "yes",
    };
    form.setFieldsValue({ [day]: newDay });
    handleFieldClick(day);
  };

  const removeDay = (dayKey: string) => {
    const { [dayKey]: removedDay, ...rest } = form.getFieldsValue();
    form.setFieldsValue({ ...rest });
    handleFieldClick(dayKey);
  };

  const renderShiftFields = () => (
    <>
      <Form.Item
        style={{ padding: ".5rem" }}
        label="Shift Name"
        name="shiftName"
        labelCol={{ span: 24 }}
      >
        {isUpdate ? (
          <Input />
        ) : (
          <Select
            defaultValue={isUpdate ? updatedShiftid : recentShift?.id}
            value={isUpdate ? updatedShiftid : recentShift?.id}
            placeholder={isUpdate ? updatedShiftid : recentShift?.shiftName}
          >
            <Select.Option value={recentShift?.id}>
              {recentShift?.shiftName}
            </Select.Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item
        style={{ padding: ".5rem" }}
        label="Shift color"
        name="color"
        labelCol={{ span: 24 }}
        rules={[
            {
                required: true,
                message: "Color is required",
            },
        ]}
      >
        {/* defaultValue={!isUpdate && recentShift?.color }  */}
        <ColorPicker size="large" disabledAlpha showText format='hex' />
    </Form.Item>
    </>
  );

  const renderWorkingDaysForm = () => {
    return (
      <>
        {renderShiftFields()}
        <div className="working_days">
          {[
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
            "sunday",
          ].map((day, index) => {
            return (
              <ModalDayItem
                key={index}
                index={index}
                day={day}
                addDay={addDay}
                removeDay={removeDay}
                form={form}
              />
            );
          })}
        </div>
        <table style={{ width: "75%" }}>
          <thead>
            <tr>
              <th>Day</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Break min</th>
              <th>Total Hours</th>
            </tr>
          </thead>
          <tbody style={{ padding: "10px" }}>
            <tr>
              <td colSpan={5}>
                <div
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    marginLeft: "80%",
                    marginTop: "-15px",
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCopyClick()}
                >
                  <CopyOutlined style={{ color: "black" }} />
                  Copy text to All
                </div>
              </td>
            </tr>

            {dayOrder.map((day: any, index) => (
              <ModalTableRowItem key={index} day={day} form={form} />
            ))}
          </tbody>
        </table>
        <Form.Item
          style={{
            padding: ".5rem",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#20304f", marginRight: "10px" }}
          >
            {isUpdate ? "Update Working Days" : "Add Working Days"}
          </Button>

          {isUpdate && (
            <Button
              type="primary"
              style={{
                backgroundColor: "white",
                color: "black",
                border: "2px solid #20304f",
              }}
              onClick={handleCancel_workingDays}
            >
              Cancel
            </Button>
          )}
        </Form.Item>
      </>
    );
  };

  const renderProductTypesForm = () => {
    return (
      <>
        {renderShiftFields()}
        <Form.List name="products">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} style={{ marginBottom: "16px" }}>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        {...restField}
                        labelCol={{ span: 24 }}
                        style={{ padding: ".5rem", marginBottom: 0 }}
                        name={[name, "product_name"]}
                        label="Product Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter product name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8} offset={2}>
                      <Form.Item
                        {...restField}
                        style={{ padding: ".5rem", marginBottom: 0 }}
                        labelCol={{ span: 24 }}
                        name={[name, "seconds_to_finish"]}
                        label="Seconds to Complete"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a valid number",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2} offset={2}>
                      <Form.Item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          marginTop: 20,
                        }}
                      >
                        <Button
                          size={"large"}
                          icon={<DeleteFilled />}
                          onClick={() => remove(name)}
                          type="link"
                          danger
                        ></Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    {...restField}
                    style={{ display: "none" }}
                    name={[name, "organization"]}
                    label="Organization"
                  >
                    <Input />
                  </Form.Item>
                </div>
              ))}
              <Row>
                <Col span={2} offset={22}>
                  <Form.Item>
                    <Button
                      style={{ color: "black" }}
                      size={"large"}
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                      type="link"
                    ></Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
        <Form.Item
          style={{
            padding: ".5rem",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#20304f", marginRight: "10px" }}
          >
            Save shift settings
          </Button>

          {isUpdate && (
            <Button
              type="primary"
              style={{
                backgroundColor: "white",
                color: "black",
                border: "2px solid #20304f",
              }}
              onClick={handleCancel_workingDays}
            >
              Cancel
            </Button>
          )}
        </Form.Item>
      </>
    );
  };

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Shift Working days",
      children: renderWorkingDaysForm(),
    },
    {
      key: "2",
      label: "Product Types",
      children: renderProductTypesForm(),
    },
  ];

  return (
    <Modal
      closable={isUpdate ? true : false}
      keyboard={isUpdate ? true : false}
      maskClosable={isUpdate ? true : false}
      title={isUpdate ? "Update Shift Working Days" : "Add Shift Working Days"}
      className="shift_modal"
      open={workingdaysModal}
      mask={false}
      onOk={handleOK_workingDays}
      destroyOnClose={true}
      onCancel={handleCancel_workingDays}
    >
      <Form form={form} onFinish={SubmitWorkingDays}>
        <Tabs
          items={tabItems}
          className="shift_tabs"
          activeKey={activeTab}
          onChange={handleTabChange}
        />
      </Form>
    </Modal>
  );
};

export default WorkingDaysModal;
