import { useContext } from "react";

import { Select, DatePicker } from "antd";
import dayjs from "dayjs";
import { DashboardContextI, ShiftOptionI } from "../../utils/interfaces";
import CreateForecastModal from "./CreateForecastModal";

import { DashboardContext } from '../../Context/Dashboard/DashboardContext'

const Dashboardheader = () => {
  const {
    selectedShift,
    setproductValue,
    setshift,
    shiftdropdown,
    productType,
    productValue,
    handleDateChange,
    datesFilter,
  } = useContext<DashboardContextI>(DashboardContext);

  const handleProductTypeChange = (value: any) => {
    setproductValue(value);
  };

  const handleShiftChange = (value: {
    value: number;
  }) => setshift(value)

  const productTypeOptions = [
    {
      value: "",
      label: "Product type",
    },
    ...productType,
  ];

  const shiftsOptions = shiftdropdown?.map((item: any, index: number) => ({
    value: item?.id,
    label: item?.shiftName,
  }))

  return (
    <>
      <div className="dashboard-header">
        <div style={{ display: "flex" }}>
          <div className="filters">
            <Select
              labelInValue
              className="shifts"
              placeholder="Shift"
              onChange={handleShiftChange}
              value={selectedShift as ShiftOptionI}
              options={shiftsOptions}

            />
            <Select
              value={productValue}
              placeholder="Select an option"
              className="productType"
              onChange={handleProductTypeChange}
              options={productTypeOptions}
            />
            <DatePicker
              placeholder="MM/DD/YYYY"
              value={dayjs(datesFilter.fromDate)}
              className="productType"
              allowClear={false}
              onChange={(_d, date) => handleDateChange(date, "fromDate")}
            />
            <DatePicker
              placeholder="MM/DD/YYYY"
              className="productType"
              allowClear={false}
              value={dayjs(datesFilter.toDate)}
              onChange={(_d, date) => handleDateChange(date, "toDate")}
            />
          </div>
        </div>
        <CreateForecastModal />
      </div>
    </>
  );
};

export default Dashboardheader;
