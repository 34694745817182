import React, { useState } from 'react'
import { Popover } from 'antd';
import TeamMemberColumn from '../schedule/TeamMemberColumn';
import { ILeave } from '../../components/LeaveTracker/types'

interface LeaveCells {
    content: any;
    leavesList: ILeave[];
    setLeaveId: any;
    getFieldsValue: any;
    test: any;
}
interface Abc {
  leave: any;
  daysDifference: any;
}

const CellContent = ({ leave, content, setLeaveId, getFieldsValue }: { 
  leave: ILeave;
  content: any;
  setLeaveId: any;
  getFieldsValue: any;
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const daysDifference = getDaysDifference(leave?.leave_start_date, leave?.leave_end_date);

  const handleMouseEnter = () => {
    setShowInfo(true);
  }
  const handleMouseLeave = () => {
      setShowInfo(false);
  }

  return (
    <div>
    <Popover
      content={content}
      trigger={['click']}
      placement='leftBottom'
      open={isPopoverVisible}
      className="leaves_popover-inner"
    >
      <div
        className={
          'leaves' + leave?.reason == 'sick'
            ? 'sick_leave'
            : leave?.reason == 'planned leave'
            ? 'planned_leave'
            : 'annual_leave'
        }
        onClick={() => {
          setLeaveId(leave?.id);
          getFieldsValue();
          setPopoverVisible(!isPopoverVisible);
        }}
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          display: 'inline-flex',
          marginTop: '-10px',
          marginLeft: '-17px',
          position: 'absolute',
          cursor: 'pointer',
          width: `${daysDifference * 2.8}%`,
        }}
      >
        {leave?.reason == 'sick'
          ? 'L'
          : leave?.reason == 'planned leave'
          ? 'P'
          : 'A'}
      </div>
    </Popover>
    {showInfo && !isPopoverVisible && (
      <A leave={leave} daysDifference={daysDifference} />
    )}
  </div>
  )
}

const A: React.FC<Abc> = ({ leave, daysDifference }) => (
    <div className='leave_info'>
        <div className='leave_type'><p>One Time Leave</p></div>
        <div className='details'><p>From:</p><p>{leave?.leave_start_date}</p></div>
        <div className="details"><p>Day:</p><p>{leave?.leave_end_date}</p></div>
        <div className="details"><p>Duration:</p><p>{daysDifference} days</p></div>
    </div>
)

const getDaysDifference = (start: string, end: string): number => {
  const startDate: any = new Date(start);
  const endDate: any = new Date(end);

  const timeDifference = (endDate - startDate);
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
}

const PlannedLeaveCells: React.FC<LeaveCells> = ({ content, leavesList, test, setLeaveId, getFieldsValue }) => {
    let workerName = '';
    let id = '';
    if(leavesList.length) {
      workerName = leavesList[0].worker?.worker;
      id = `${leavesList[0].id}`;
    }
    
    const totalDaysCount = leavesList.reduce((sum, leave) => {
      const daysDifference = + getDaysDifference(leave.leave_start_date, leave.leave_end_date);
      return sum + daysDifference;
    }, 0);

  const renderRow = () => {
    return (
      test?.map((day: any) => {
        const currentLeave = leavesList.find(leaveItem => day === leaveItem?.leave_start_date);
        return (
          <td key={Math.random() * 100 + 30}>
            {currentLeave && <CellContent 
              leave={currentLeave} 
              content={content}
              setLeaveId={setLeaveId}
              getFieldsValue={getFieldsValue}
            />}
          </td>
        );
      })
    )
  }
  return (
    <>
      <td
        style={{
        borderBottom: '1px solid #dee2e6',
        padding: '3px 10px',
        textAlign: 'left',
        backgroundColor: 'white !important',
        }}
        className="calendar-columns teams-member-col"
      >
        <TeamMemberColumn
        name={workerName}
        id={id}
        total={totalDaysCount}
        />
      </td>
      {renderRow()}
    </>
  )
}

export default PlannedLeaveCells