import {  Form, InputNumber } from 'antd';
import moment from 'moment';

const getTotalHours = (startDate: any, endDate: any,breakDuration: any) => {
  if(!startDate || !endDate) return 0;
  const startTime: any = startDate.format('YYYY-MM-DD HH:mm') 
  const endTime: any = endDate.format('YYYY-MM-DD HH:mm') 

  const breakMinutes = breakDuration || 0;
  
  const durationInMilliseconds = moment(endTime).diff(moment(startTime));
  const breakMilliseconds = breakMinutes * 60 * 1000;
  const totalDurationMilliseconds = durationInMilliseconds - breakMilliseconds;

  return totalDurationMilliseconds / (1000 * 60 * 60)
}

const formatHours = (totalHours: number) => {
const hours = Math.floor(totalHours);
const minutes = Math.abs(Math.round((totalHours - hours) * 60));
return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

const TotalHours = ({
  form,
  day
}: any) => {
  const dayValue = Form.useWatch(day, form);
  const totalHours = getTotalHours(dayValue?.start_time, dayValue?.end_time, dayValue?.break_duration_min)
  const formattedHours = formatHours(totalHours);

  return (
      <InputNumber
          value={formattedHours }
          className="hours"
          readOnly
      />
  )
}

export default TotalHours