import { useState, createContext, useEffect } from "react";
import { Form, notification } from 'antd';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

import { useGetShift } from '../../utils/hooks'
import { plannedleavesURL, recurringLeavesURl, workerUrl } from '../../utils/network';
import { generateDates } from "../../utils/dates";

// import { leaveData } from '../../mock_data/leave';
// import { leaveDataSeptember } from '../../mock_data/leave2';
// import { fetchData } from '../../utils/mock';

import { 
    LeaveTrackerContextI, 
} from "../../utils/interfaces";


export const LeaveTrackerContext: any = createContext<LeaveTrackerContextI | undefined>(undefined);

export const LeaveTrackerProvider = ({ children, initialDate }: { 
    children: React.ReactNode, initialDate?: Date 
}) => {
  //setting states
  const [shift, setshift] = useState();
  const [selectedTeam, setSelectedTeam] = useState('');
  const [shiftdropdown, setshiftdropdown] = useState<any[]>([]);
  const [workers, setWorkers] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [teams, setTeams] = useState([]);

  const [month, setMonth] = useState(initialDate ? initialDate.getMonth() : new Date().getMonth());
  // UNCLEAR why need this state
  const [empName, setEmpName] = useState([]);

  useGetShift(setshiftdropdown, setFetching);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'Jun',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const [currentDate, setCurrentDate] = useState(initialDate || new Date());

  const currentMonth = (currentDate.getMonth());
  const currentYear = (currentDate.getFullYear());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recurringModal, setRecurringModal] = useState(false);
  const [plannedLeaves, setPlannedLeaves]: any = useState([]);
  const [recurrenceLeaves, setRecurrenceLeaves]: any = useState([]);

  const leavesOptions = ['annual vacation', 'planned leave', 'sick'];
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [leaveId, setLeaveId] = useState();
  const [recurrLeaveId, setrecurrLeaveId] = useState();
  const [editModaOpen, setEditModalOpen] = useState(false);
  const [updateRecurr, setUpdateRecurr] = useState(false);
  // const [sameValues, setSameValues]:any = useState([]);

  const years = Array.from({ length: currentYear + 2000 }, (_, index) => 2023 + index);
  const [selectedValuesFrom, setSelectedValuesFrom] = useState({ select1: '', select2: '', select3: '' });
  const [selectedValuesTo, setSelectedValuesTo] = useState({ select1: '', select2: '', select3: '' });

  const [selectedValuesFromRecurr, setSelectedValuesFromRecurr] = useState({ select1: '', select2: '', select3: '' });
  const [selectedValuesToRecurr, setSelectedValuesToRecurr] = useState({ select1: '', select2: '', select3: '' });


  const [form_edit] = Form.useForm();
  const [add_recurr] = Form.useForm();

  let betweenDates: any = [];
  const handleSelectChangeFrom = (selectNumber: any, value: any) => {
    setSelectedValuesFrom((prevValues) => ({
      ...prevValues,
      [selectNumber]: value
    }));
  };
  const handleSelectChangeTo = (selectNumber: any, value: any) => {
    setSelectedValuesTo((prevValues) => ({
      ...prevValues,
      [selectNumber]: value
    }));
  };

  //
  const handleSelectChangeFromRecurr = (selectNumber: any, value: any) => {
    setSelectedValuesFromRecurr((prevValues) => ({
      ...prevValues,
      [selectNumber]: value
    }));
  };
  const handleSelectChangeToRecurr = (selectNumber: any, value: any) => {
    setSelectedValuesToRecurr((prevValues) => ({
      ...prevValues,
      [selectNumber]: value
    }));
  };



  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };
  const daysInMonth = getDaysInMonth(currentYear, currentMonth);


  const handleshiftchange = (event: any) => {

    setshift(event);
  };

  //Modal methods

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //edit One time modal methods
  const showEditModal = () => {
    setEditModalOpen(true);
  };

  const handleEditModalOk = () => {
    setEditModalOpen(false);
  };

  const handleEditModalCancel = () => {
    setEditModalOpen(false);
  };


  //open a recurring modal with toggle switch from  one time leave modal
  const handleSwitchChange = (checked: any) => {

    setRecurringModal(checked);
    setIsModalOpen(!checked);

  };
  //open a one time modal with toggle switch from  one time leave modal
  const handleRecurrSwitchChange = (checked: any) => {

    setRecurringModal(!checked);
    setIsModalOpen(checked);
  }

  const handleModalClose = () => {
    setRecurringModal(false);
  };


  const plannedLeavesDates = plannedLeaves.map((a: any, index: any) => {
    return {
      startdate: a?.leave_start_date,
      enddate: a?.leave_end_date
    }
  });

  const recurrentDates = recurrenceLeaves.map((a: any, index: any) => a?.leave_dates);

  for (let i in plannedLeavesDates) {
    // start_date
    const currentDate = moment(plannedLeavesDates[i]?.startdate).toDate();
    const endDate = moment(plannedLeavesDates[i]?.enddate).toDate();
    while (currentDate <= endDate) {
      betweenDates.push(moment((currentDate)).format("YYYY-MM-DD"));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }
  betweenDates.push([].concat(...recurrentDates));
  const allLeavesDates = [].concat(...betweenDates);
  const itemCount: any = {};
  const duplicates: any = {};

  for (let i = 0; i < allLeavesDates.length; i++) {
    const item = allLeavesDates[i];

    if (itemCount[item]) {
      itemCount[item] += 1;
    }
    else {
      itemCount[item] = 1;
    }
  }
  for (const item in itemCount) {
    if (itemCount[item] > 1) {
      duplicates[item] = itemCount[item];
    }
  }

  const dates = generateDates();

  const getLeaves = async () => {
    try {
    const startDate = moment(`${currentYear}-${monthNames[currentMonth]}-01`, "YYYY-MMMM-DD");
    const endDate = startDate.clone().endOf('month');
    
    const response = await axios.get(plannedleavesURL, {
        params: {
            leave_start_date: startDate.format("YYYY-MM-DD"),
            leave_end_date: endDate.format("YYYY-MM-DD")
        }
    });

    // const mockDataLeave = {
    //   8: leaveDataSeptember,
    //   9: leaveData,
    // }

    // @ts-ignore
    // const response: any = await fetchData( mockDataLeave[currentMonth] || {results: []} )

      const leavesData = response.data?.results;
      setPlannedLeaves(leavesData);

      setEmpName(leavesData.map((result: any) => result.worker?.worker));
    }

    catch (e) {
      console.log("error");
    }

  }

  const getRecurringLeaves = async () => {
    try {
      const response = await axios.get(recurringLeavesURl);
      const recurringLeaves = response.data.results;
      setRecurrenceLeaves(recurringLeaves);
    }
    catch (e) {
      console.log("error", e);
    }
  }

  const getTeamNames = async () => {
    try {
      const response = await axios.get(workerUrl);
      const workers = response.data.results;
      setWorkers(workers);

    }
    catch (e) {
      notification.error({
        message: "Error fetching team workers details"
      })
    }
  }

  const [add_leave] = Form.useForm();

  //add a one time leave
  const addOneTimeLeave = async (e: any) => {
    const data = { ...e };

    data.leave_start_date = data.leave_start_date.format("YYYY-MM-DD");
    data.leave_end_date = data.leave_end_date.format("YYYY-MM-DD");
    data.status = 'a'

    try {
      const response = await axios.post(plannedleavesURL, data);
      const newLeaves = response.data;
      if (newLeaves) {
        notification.success({
          message: "Leave Added Successfully"
        });
        handleOk();
        add_leave.resetFields();
      };
      getLeaves();

      return response;
    }
    catch (e) {
      notification.error({
        message: "Can't Add Leave"
      })
    }
  }

  //add and update a recurring leave
  const addRecurringLeave = async (e: any) => {
    const data = { ...e };

    const startDate = selectedValuesFromRecurr.select1 +
      "-" + selectedValuesFromRecurr.select2 + "-" + selectedValuesFromRecurr.select3;

    const endDate = selectedValuesToRecurr.select1 +
      "-" + selectedValuesToRecurr.select2 + "-" + selectedValuesToRecurr.select3;


    data.leave_start_date = moment(startDate).format("YYYY-MM-DD");
    data.leave_end_date = moment(endDate).format("YYYY-MM-DD");

    if (!updateRecurr) {
      try {
        const response = await axios.post(recurringLeavesURl
          , data);
        const newLeaves = response.data;
        if (newLeaves) {
          notification.success({
            message: "Recurring Added Successfully"
          });
          handleModalClose();
          getRecurringLeaves();
          add_recurr.resetFields();
        };


        return response;
      }
      catch (e) {
        notification.error({
          message: "Can't Add Leave"
        })
      }
    }
    else {
      try {
        const response = await axios.put(`${recurringLeavesURl}/${recurrLeaveId}`
          , data);
        const newLeaves = response.data;
        if (newLeaves) {
          notification.success({
            message: "Recurring Leave Updated Successfully"
          });
          handleModalClose();
          getRecurringLeaves();
          add_recurr.resetFields();
        };


        return response;
      }
      catch (e) {
        notification.error({
          message: "Can't Add Leave"
        })
      }
    }
  }

  //delete one time leave
  const deleteLeave = async () => {
    try {
      const response = await axios.delete(`${plannedleavesURL}/${leaveId}`)
      if (response) {
        notification.success({
          message: "Leave Deleted Successfully"
        })
      }

      getLeaves();
      return response;

    }
    catch (e) {
      notification.error({
        message: "Error while deleting leave"
      })
    }
  }

  //delete recurring leave
  const deleteRecurringLeave = async () => {
    try {
      const deleteResponse = await axios.delete(`${recurringLeavesURl}/${recurrLeaveId}`);
      if (deleteResponse) {
        notification.success({
          message: "Recurring Leave deleted succesfully"
        })
        getRecurringLeaves();
      }
    }
    catch (e) {
      notification.error({
        message: "Leave deletion failed"
      })
    }
  }

  const updateLeaveData = plannedLeaves.filter((leave: any) => leave?.id == leaveId);

  const worker_id = updateLeaveData[0]?.worker?.id;
  const reason = updateLeaveData[0]?.reason;
  const leave_start_date = moment(updateLeaveData[0]?.leave_start_date);
  const leave_end_date = moment(updateLeaveData[0]?.leave_end_date);

  const updateRecurrLeaveData = recurrenceLeaves?.filter((leave: any) => leave?.id == recurrLeaveId);
  const recurr_worker_id = updateRecurrLeaveData[0]?.worker?.id;
  const recurrence_type = updateRecurrLeaveData[0]?.recurrence_type;
  const interval = updateRecurrLeaveData[0]?.interval;
  const recurr_start_date = updateLeaveData[0]?.leave_start_date;


  //edit one time leave
  const editLeave = async (e: any) => {
    const data = { ...e };
    const startDate = selectedValuesFrom.select1 +
      "-" + selectedValuesFrom.select2 + "-" + selectedValuesFrom.select3;

    const endDate = selectedValuesTo.select1 +
      "-" + selectedValuesTo.select2 + "-" + selectedValuesTo.select3;


    data.leave_start_date = moment(startDate).format("YYYY-MM-DD");
    data.leave_end_date = moment(endDate).format("YYYY-MM-DD");

    try {
      const response = await axios.put(`${plannedleavesURL}/${leaveId}`, data);
      const updateData = response.data;
      if (updateData) {
        notification.success({
          message: "Leave Updated Successfully",
        })

        handleEditModalOk();
        getLeaves();
        form_edit.resetFields();
      }

    }
    catch (e) {
      notification.error({
        message: "error while updating leave"
      })
    }
  }

  //prefill values for one time leave  
  const getFieldsValue = () => {
    form_edit.setFieldsValue({
      worker_id: worker_id,
      reason: reason,
      leave_start_date: leave_start_date,
      leave_end_date: leave_end_date

    });
  }

  //prefill values for recurring leave

  const getRecurringFieldsValue = () => {
    add_recurr.setFieldsValue({
      worker_id: recurr_worker_id,
      interval: interval,
      recurrence_type: recurrence_type,
      leave_start_date: recurr_start_date
    })
  }

    const nextMonth = () => {
        setCurrentDate((prevDate: any) => {
            const nextDate = new Date(prevDate);
            nextDate.setMonth(nextDate.getMonth() + 1);
            return nextDate;
        });
    };

    const prevMonth = () => {
        setCurrentDate((prevDate: any) => {
            const prevDateCopy = new Date(prevDate);
            prevDateCopy.setMonth(prevDateCopy.getMonth() - 1);
            return prevDateCopy;
        });
    };

    useEffect(() => {
        getLeaves();
        getTeamNames();
        getRecurringLeaves();
    }, [monthNames[currentMonth], currentYear]);
    let test: any = [];

    const renderHeaders = () => {
        const headers: any = [];
        const allLeavesDates = [].concat(...betweenDates);
        for (let day = 1; day <= daysInMonth; day++) {
    
          const date = new Date(currentYear, currentMonth, day);
          let value: any = "";
          for (let elements in itemCount) {
            if (moment(date).format("YYYY-MM-DD") == elements) {
              value = itemCount[elements]
            }
          }
          const dayOfWeek = date.getDay();
          test.push(moment(date).format("YYYY-MM-DD"));
          
          headers.push(
    
            <th key={day} className='dates' >
              <div className='leaves_bar' style={{ height: `${plannedLeaves.length + recurrenceLeaves.length}rem` }}>
                <div style={{
                  backgroundColor: "#506eae",
                  color: "white",
                  display: "flex",
                  alignSelf: "flex-end",
                  textAlign: "center",
                  width: "inherit",
                  height: `${value}px`,
                  justifyContent: "center",
                  fontSize: ".7rem"
                }}>
                  <p style={{ marginTop: value < 5 ? '-19px' : '' }}>{value}</p>
                </div>
              </div>
              <div className="day_names"> <p>{day} </p><p>{daysOfWeek[dayOfWeek]}</p></div>
            </th>
          );
        }
        return headers;
      };


    return (
        <LeaveTrackerContext.Provider value={{
            test,
            renderHeaders,
            plannedLeaves,
            recurrenceLeaves,
            teams,
            selectedTeam,
            currentDate,
            shiftdropdown,
            daysInMonth,
            currentMonth,
            itemCount,
            betweenDates,
            currentYear,
            deleteBtn,
            handleshiftchange,
            leavesOptions,
            setSelectedTeam,
            setMonth,
            years,
            setLeaveId,
            setrecurrLeaveId,
            workers,
            isModalOpen,
            handleOk,
            handleCancel,
            addOneTimeLeave,
            handleSwitchChange,
            deleteLeave,
            deleteRecurringLeave,
            prevMonth,
            nextMonth,
            add_leave,
            dates,
            selectedValuesFrom,
            selectedValuesTo,
            handleSelectChangeFrom,
            handleSelectChangeTo,
            showModal,
            showEditModal,
            setRecurringModal,
            getRecurringFieldsValue,
            setUpdateRecurr,
            getFieldsValue,
            editModaOpen,
            handleEditModalOk,
            handleEditModalCancel,
            editLeave,
            form_edit,
            updateRecurr,
            recurringModal,
            handleModalClose,
            addRecurringLeave,
            add_recurr,
            handleRecurrSwitchChange,
            handleSelectChangeFromRecurr,
            selectedValuesFromRecurr,
            handleSelectChangeToRecurr,
            selectedValuesToRecurr,
            daysOfWeek,
        }}>
            {children}
        </LeaveTrackerContext.Provider>
    )

}