import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';

interface IProps {
  onChange: (value: string) => void;
  defaultValue: string;
}
export const LEAVE_VIEW = 'LEAVE_VIEW';
export const SCHEDULE_VIEW = 'SCHEDULE_VIEW';

const togglerOptions = [
  {
    value: SCHEDULE_VIEW,
    label: 'Shifts'
  },
  {
    value: LEAVE_VIEW,
    label: 'Time-off'
  }
]

const RadioGroup = ({ 
  onChange,
  defaultValue
}: IProps) => {
  const handleChange = ({target: { value }}: RadioChangeEvent) => {
    onChange(value)
  };

  return (
    <Radio.Group 
      onChange={handleChange} 
      value={defaultValue}
      className='radio_group'
      >
      {togglerOptions.map((item) => (
        <Radio 
          key={item.value} 
          value={item.value}
        >
          {item.label}
          </Radio>
      ))}
    </Radio.Group>
  )
}

export default RadioGroup;