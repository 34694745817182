import { useContext, useMemo} from 'react';
import { Button } from 'antd';
import { CloudOutlined } from '@ant-design/icons';

import { LeaveTrackerContext } from '../../Context/LeaveTracker/LeaveTrackerContext'
import { LeaveTrackerContextI } from '../../utils/interfaces'
import ScheduleGenerationModal, { ScheduleData } from '../../components/schedule/ScheduleGenerationModal'

interface IProps {
  handleGenerate?: (data: ScheduleData) => void;
  defaultScheduleValue?: ScheduleData;
  shiftdropdown?: number[]
}

const LeaveActionButtons = ({
  handleGenerate,
  defaultScheduleValue,
  shiftdropdown,
}: IProps) => {
  const {
    showModal,
  } = useContext<LeaveTrackerContextI>(LeaveTrackerContext);

  const allowGenerateButton = handleGenerate && defaultScheduleValue &&  shiftdropdown;
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        style=
        {{ fontWeight: "bold", marginRight: "15px", borderRadius: 0, color: "#20304F" }}
        type="default"
        icon={<CloudOutlined />}
        size="large"
      >
        Import CSV
      </Button>
      <Button
        style={{
          fontWeight: "bold",
          backgroundColor: "#20304F",
          color: "white",
          borderRadius: 0
        }}
        type="default"
        size="large"
        onClick={showModal}
      >
        Add Leave
      </Button>
      {allowGenerateButton && (
         <div style={{marginLeft: 10}}>
          <ScheduleGenerationModal 
              shiftdropdown={shiftdropdown}
              generateSchedule={handleGenerate}
              defaultValue={defaultScheduleValue}
            />
         </div>
      )}
    </div>
  )
}

export default LeaveActionButtons;