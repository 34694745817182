import React from "react";
import { useLocation } from "react-router-dom";
import { ACCESS_TOKEN } from "../config";
import { Button } from "antd";

const Blank = () => {
  const location = useLocation();

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      {location.state && (
        <div style={{ color: "red" }}>{location.state.message}</div>
      )}

      <Button
        type="primary"
        onClick={() => {
          localStorage.setItem(
            ACCESS_TOKEN,
            JSON.stringify(
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsid29ya3RpbWVwb3J0YWwiXSwidXNlcl9uYW1lIjoiYW5hc3Rhc2lhLmJydXlha292YUBmYWNlaXQuY29tLnVhIiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl0sImV4cCI6MTcwMjM0MzgzOCwiYXV0aG9yaXRpZXMiOlsiUk9MRV9QRVJTT04iLCJST0xFX1BMQU5ORVIiXSwianRpIjoiOWM3ZDc5ODgtZmI3Yi00ZjMwLThjYTItM2I0NmFhNDJlZWZiIiwiY2xpZW50X2lkIjoid29ya3RpbWVwb3J0YWwifQ.LguFClwGKlH-1xWnB2kbmzH53XnFH2jRlbg4gzKPR3Y"
            )
          );
          window.location.href = "/";
        }}
      >
        test login
      </Button>
    </div>
  );
};

export default Blank;
