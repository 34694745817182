import React, { useState } from "react";
import { Popover } from "antd";
import TeamMemberColumn from '../schedule/TeamMemberColumn';

interface RecurringLeave {
    emp: any;
    test: any;
    recurringContent: any;
    setrecurrLeaveId: any;
}
interface Abc {
    emp: any;
    type: any;
}
const A: React.FC<Abc> = ({ emp, type }) => (
    <div className="leave_info">
        <div className="leave_type">
            <p>Recurring Leave</p>
        </div>

        <div className="details">
            <p>From:</p>
            <p>{emp?.leave_start_date}</p>
        </div>
        <div className="details">
            <p>Day:</p>
            <p>{emp?.leave_end_date}</p>
        </div>
        <div className="details">
            <p>Duration:</p>
            <p>{type}</p>
        </div>
    </div>
);
const RecurrenceLeaveCell: React.FC<RecurringLeave> = ({
    emp,
    test,
    recurringContent,
    setrecurrLeaveId,
}) => {
    let sameValues = test?.filter((value: any) =>
        emp?.leave_dates?.includes(value)
    );

    const [showInfo, setShowInfo] = useState(false);

    const [isPopoverVisible, setPopoverVisible] = useState(false);

    const handleMouseEnter = () => {
        setShowInfo(true);
    };
    const handleMouseLeave = () => {
        setShowInfo(false);
    };

    const handlePopoverVisibleChange = () => {
        setPopoverVisible(true);
    };

    return (
        <>
            <td style={{
                    borderBottom: "1px solid #dee2e6",
                    padding: "5px 10px",
                    textAlign: "left",
                    backgroundColor: "white !important",
                }}
            className="calendar-columns teams-member-col"
            >
                <TeamMemberColumn name={emp?.worker?.worker} id={emp?.worker?.id}/>
            </td>

            {test?.map((testingArr: any) => {
                return (
                    <td key={Math.random() * 10 + 12.5 * 2} className="auto-wrap">
                        {test
                            ?.filter((s: any) => sameValues.includes(s))
                            ?.map((t: any, index: any) => {
                                return (
                                    t == testingArr && (
                                        <div>
                                            <Popover
                                                content={recurringContent}
                                                trigger={["click"]}
                                                placement="bottom"
                                                open={isPopoverVisible}
                                                onOpenChange={handlePopoverVisibleChange}
                                            >
                                                <div
                                                    className="not-available"
                                                    style={{
                                                        marginTop: "-10px",
                                                        position: "absolute",
                                                    }}
                                                    onClick={() => {
                                                        setrecurrLeaveId(emp?.id);
                                                        //getFieldsValue();
                                                        setPopoverVisible(!isPopoverVisible);
                                                    }}
                                                    onMouseOver={handleMouseEnter}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    N/A
                                                </div>
                                            </Popover>
                                            {(showInfo && !isPopoverVisible) &&
                                                < A emp={emp} type={emp?.recurrence_type} />}
                                        </div>
                                    )
                                );
                            })}
                    </td>
                );
            })}
        </>
    );
};

export default RecurrenceLeaveCell;
