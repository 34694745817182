import React from 'react'
import { Modal, Form, Select, Input,TimePicker, Button } from "antd"
interface ShiftWorkingDaysI {
    shiftDaysEditModal: boolean;
    handleShifDaysEditModalOk: any;
    shiftdays_form: any;
    updateShiftWorkingDay: any;
    shifts: any;
    handleShiftDaysEditModalCancel: any;
}
const UpdateShiftDaysModal: React.FC<ShiftWorkingDaysI> = ({
    shiftDaysEditModal,
    handleShifDaysEditModalOk,
    handleShiftDaysEditModalCancel,
    shiftdays_form,
    updateShiftWorkingDay,
    shifts,
}) => {
    return (
        <Modal
            title="Update Shift Working Day"
            open={shiftDaysEditModal}
            className="shift_modal"
            mask={false}
            onOk={handleShifDaysEditModalOk}
            onCancel={handleShiftDaysEditModalCancel}
        >
            <Form form={shiftdays_form} onFinish={updateShiftWorkingDay}>
                <Form.Item
                    style={{ padding: ".5rem" }}
                    label="Shift Name"
                    name="shiftName_id"
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Shift is required",
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Shift"
                        options={
                            shifts?.map((item: any, index: number) => ({
                                value: item?.id,
                                label: item?.shiftName,
                            }))
                        }
                    />
                </Form.Item>
                <Form.Item
                    style={{ padding: ".5rem" }}
                    label="Day"
                    name="day"
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Day Name is required",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    style={{ padding: ".5rem" }}
                    label="Shift"
                    name="shift"
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Day Name is required",
                        },
                    ]}
                >
                    <Input readOnly />
                </Form.Item>
                <div>
                    <Form.Item
                        style={{ padding: ".5rem" }}
                        label="Start time"
                        name="start_time"
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "Day Name is required",
                            },
                        ]}
                    >
                        <TimePicker
                            placeholder="Start Time"
                            format="HH:mm"
                        />
                        {/* <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: "100%" }} /> */}
                    </Form.Item>
                    <Form.Item
                        style={{ padding: ".5rem" }}
                        label="End Time"
                        name="end_time"
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "Day Name is required",
                            },
                        ]}
                    >
                        <TimePicker
                            placeholder="End Time"
                            format="HH:mm"
                        />
                        {/* <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: "100%" }} /> */}

                    </Form.Item>
                </div>
                <Form.Item style={{
                    padding: ".5rem", display: "flex", justifyContent: "end"
                }}>
                    <Button type="primary" htmlType="submit" style={{ backgroundColor: "#20304f", marginRight: "10px" }
                    } >
                        Update
                    </Button>
                    <Button type="primary"
                        style={{ backgroundColor: "white", color: "black", border: "2px solid #20304f" }}
                        onClick={handleShiftDaysEditModalCancel}>
                        Cancel
                    </Button>
                </Form.Item>
              
            </Form>
        </Modal>
    )
}

export default UpdateShiftDaysModal