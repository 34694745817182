import { Form, TimePicker, InputNumber } from 'antd';
import TotalHours from './TotalHours';

interface Props {
  day: string;
  form: any
}

const ModalTableRowItem = ({
  day,
  form
}: Props) => {
  const dayValue = Form.useWatch(day, form);
  const isDaySelected = dayValue?.shift === 'yes'

// console.log(dayValue,`dayValue for ${day}`)
  if(!isDaySelected) return null;

  return (
    <tr key={`row-${day}`}>
      <td>
        <div style={{ marginTop: '-20px' }}>{day}</div>
      </td>

      <td>
        <Form.Item name={[day, 'start_time']}>
          <TimePicker
            placeholder="Start Time"
            format="HH:mm"
          />
        </Form.Item>
      </td>
      <td>
        <Form.Item
          name={[day, 'end_time']}
          className="timepicker"
        >
          <TimePicker
            placeholder="End Time"
            format="HH:mm"
          />
        </Form.Item>
      </td>
      <td>
        <Form.Item name={[day, 'break_duration_min']}>
          <InputNumber
            style={{ border: 'none !important' }}
          />
        </Form.Item>
      </td>
      <td>
        <Form.Item>
          <TotalHours form={form} day={day}/>
        </Form.Item>
      </td>
    </tr>
  )
}

export default ModalTableRowItem