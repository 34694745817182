import React from "react"
import axios, { AxiosResponse } from "axios"
import { notification, Menu } from "antd"
import { ShiftdetailsUrl, dailyworkersUrl, shifNameUrl, teamUrl } from "./network"
// 
// import { shiftNameData } from '../mock_data/shitName'

export const getShift = async (setShift: (data: any[]) => void, setFetching: (val: boolean) => void) => {
  const response: AxiosResponse = await axios.get(shifNameUrl).catch(
    (e) => {
      notification.error({
        message: "Operation Error",
        description: e.response?.data.error
      })
    }
  ) as AxiosResponse<any>

  // const response = {
  //   data: shiftNameData
  // }
  if (response) {
    const data = (response.data.results as any[]).map((item) => ({
      ...item,
      key: item.id,
      shiftName: item?.shiftName
    }))

    setShift(data)
    setFetching(false)
  }
}

export const getTeam = async (setTeam: (data: any[]) => void, setFetching: (val: boolean) => void) => {
  const response: AxiosResponse = await axios.get(teamUrl).catch(
    (e) => {
      notification.error({
        message: "Operation Error",
        description: e.response?.data.error
      })
    }
  ) as AxiosResponse<any>
  if (response) {
    const data = (response.data.results as any[]).map((item) => ({
      ...item,
      key: item.id,
      team: item.teamName
    }))
    setTeam(data)
    setFetching(false)
  }
}


export const getdailySchedule = async (setdailySchedule: (data: any[]) => void, setFetching: (val: boolean) => void) => {
  const response: AxiosResponse = await axios.get(dailyworkersUrl).catch(
    (e) => {
      notification.error({
        message: "Operation Error",
        description: e.response?.data.error
      })
    }
  ) as AxiosResponse<any>
  if (response) {
    const data = (response.data as any[]).map((item) => ({
      ...item,
      key: item.id,
      name: item.startdate__date,
      uv: item.status_sum
    }))
    setdailySchedule(data)
    setFetching(false)
  }
}



function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}


export default useDebounce;