import axios from "axios";
import { ACCESS_TOKEN, ANGULAR_APP_URL } from "../../config";

axios.interceptors.request.use(async config => {
  return {
    ...config,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem(ACCESS_TOKEN))}`,
    },
  };
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    console.log("err", err);

    const { response } = err;

    if (response.status === 403) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = ANGULAR_APP_URL;
    }

    throw err.response.data;
  }
);
