import React from 'react'
import { Modal, Form, Input, Button, ColorPicker, Space } from "antd"

interface AddSHiftI {
    shiftModal: boolean;
    handleOk_shiftName: any;
    handleCancel_shiftName: any;
    AddShiftName: any;
    shiftForm: any;

}
const AddNewShiftModal: React.FC<AddSHiftI> = ({
    shiftModal,
    handleOk_shiftName,
    handleCancel_shiftName,
    AddShiftName,
    shiftForm,
}) => {
    const defaultColor = shiftForm.getFieldsValue('color')

    return (
        <Modal
            title={"Add New Shift"}
            open={shiftModal}
            className="shift_modal"
            mask={false}
            onOk={handleOk_shiftName}
            onCancel={handleCancel_shiftName}
        >
            <Form onFinish={AddShiftName} form={shiftForm}>
                <Form.Item
                    style={{ padding: ".5rem" }}
                    label="Shift Name"
                    name="shiftName"
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Shift is required",
                        },
                    ]}
                >
                    <Input placeholder="Enter ShiftName" />
                </Form.Item>
                <Form.Item
                    style={{ padding: ".5rem" }}
                    label="Shift color"
                    name="color"
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Color is required",
                        },
                    ]}
                >
                    <ColorPicker defaultValue={defaultColor} size="large" disabledAlpha showText format='hex' />
                </Form.Item>
                <Form.Item
                    style={{ padding: ".5rem", display: "flex", justifyContent: "end" }}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ backgroundColor: "#20304f", marginRight: "1rem" }}
                    >
                        Add New Shift

                        {/* {shiftNameUpdate ? "Update Shift" : "Add New Shift"} */}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddNewShiftModal