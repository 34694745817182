import React, { useState } from 'react'
import { Modal, Form, Button, DatePicker, Select} from 'antd'

export interface ScheduleData {
    start_date: any;
    end_date: any;
    shiftName_id: number;
}
interface Props {
    generateSchedule: (data: ScheduleData) => void;
    shiftdropdown: any;
    defaultValue: {
        start_date: any;
        end_date: any;
        shiftName_id: number
    }
}

const ScheduleGenerationModal: React.FC<Props> = (props) => {
    const { 
        generateSchedule,
        shiftdropdown,
        defaultValue
    } = props
    const [form] = Form.useForm();

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        form.setFieldsValue(defaultValue);
        setIsOpen(true);
    };

    const handleCancel = () =>{
        setIsOpen(false);
        form.resetFields()
    };

    const handleFinish = (data: any) => {
        generateSchedule(data)
        handleCancel()
    }

    return (
        <>
            <Button
                style={{
                    fontWeight: "bold",
                    backgroundColor: "#20304F",
                    color: "white",
                    borderRadius: 0,
                }}
                type="default"
                size="large"
                onClick={handleOpen}
            >
            Generate Schedule
            </Button>
            <Modal
                forceRender
                title={'Create Schedule'}
                className="forecast_modal"
                open={isOpen}
                width={400}
                mask={false}
                // onOk={() => {}}
                onCancel={handleCancel}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: true }}
            >
                <Form
                    onFinish={handleFinish}
                    form={form}
                    className='schedule_form'
                >
                    <Form.Item
                        style={{ padding: ".2rem",  }}
                        label="Shift ID"
                        name="shiftName_id"
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "Shift ID is required",
                            },
                        ]}
                    >
                        <Select 
                            placeholder="Select Shift"
                            options={
                                shiftdropdown?.map((item: any, index: number) => ({
                                    value: item?.id,
                                    label: item?.shiftName
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ padding: ".2rem" }}
                        label="Start Date"
                        name="start_date"
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "Start Date is required",
                            },
                        ]}
                    >
                        <DatePicker 
                            placeholder='Start Date' 
                            className='input-field'
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ padding: ".2rem" }}
                        label="End Date"
                        name="end_date"
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: "End Date is required",
                            },
                        ]}
                    >
                        <DatePicker 
                            placeholder='Start Date' 
                            className='input-field'
                        />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ backgroundColor: "#20304f" }}
                            >
                                Generate schedule
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ScheduleGenerationModal;
